<form [formGroup]="validarForn">

  <mat-form-field>
      <mat-label>Informe seu CNPJ</mat-label>
      <input id="cnpj"
             matInput
             [formControlName]="'cnpj'">
      <mat-error *ngIf="validarForn.get('cnpj').hasError('invalidCnpj')">
        CNPJ está incorreto
      </mat-error>
  </mat-form-field>
  <button class="submit-button treo-mat-button-large"
          mat-flat-button
          [color]="'primary'"
          [disabled]="validarForn.disabled"
          (click)="validarCNPJ()">
      <span *ngIf="!validarForn.disabled">
          Verificar CNPJ
      </span>
      <mat-progress-spinner *ngIf="validarForn.disabled"
                            [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
  </button>

</form>
