import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FaqCategory, GuideCategory, Guide, PostCategory, Post, MessageSuporte } from '@shared/types/help-center.type';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {
  // Private
  private _faqs: BehaviorSubject<FaqCategory[] | null>;
  private _guides: BehaviorSubject<GuideCategory[] | null>;
  private _guide: BehaviorSubject<Guide | null>;
  private _postCategory: BehaviorSubject<PostCategory[] | null>;
  private _post: BehaviorSubject<Post | null>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
  ) {
    // Set the private defaults
    this._faqs = new BehaviorSubject(null);
    this._guides = new BehaviorSubject(null);
    this._guide = new BehaviorSubject(null);
    this._postCategory = new BehaviorSubject(null);
    this._post = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for FAQs
   */
  get faqs$(): Observable<FaqCategory[]> {
    return this._faqs.asObservable();
  }

  /**
   * Getter for guides
   */
  get guides$(): Observable<GuideCategory[]> {
    return this._guides.asObservable();
  }

  /**
   * Getter for guide
   */
  get guide$(): Observable<GuideCategory> {
    return this._guide.asObservable();
  }
  /**
   * Getter for post
   */
  get post$(): Observable<Post> {
    return this._post.asObservable();
  }
  /**
   * Getter for post
   */
  get postCategory$(): Observable<PostCategory[]> {
    return this._postCategory.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all FAQs
   */
  getAllFaqs(): Observable<FaqCategory[]> {
    return this._httpClient.get<FaqCategory[]>('api/pages/help-center/faqs').pipe(
      tap((response: any) => {
        this._faqs.next(response);
      })
    );
  }

  /**
   * Get FAQs by category using category slug
   *
   * @param slug
   */
  getFaqsByCategory(slug): Observable<FaqCategory[]> {
    return this._httpClient.get<FaqCategory[]>('api/pages/help-center/faqs', {
      params: { slug }
    }).pipe(
      tap((response: any) => {
        this._faqs.next(response);
      })
    );
  }

  /**
   * Get all guides limited per category by the given number
   *
   * @param limit
   */
  getAllGuides(limit = '4'): Observable<GuideCategory[]> {
    return this._httpClient.get<GuideCategory[]>('api/pages/help-center/guides', {
      params: { limit }
    }).pipe(
      tap((response: any) => {
        this._guides.next(response);
      })
    );
  }

  /**
   * Get guides by category using category slug
   *
   * @param slug
   */
  getGuidesByCategory(slug): Observable<GuideCategory[]> {
    return this._httpClient.get<GuideCategory[]>('api/pages/help-center/guides', {
      params: { slug }
    }).pipe(
      tap((response: any) => {
        this._guides.next(response);
      })
    );
  }

  /**
   * Get guide by category and guide slug
   *
   * @param categorySlug
   * @param guideSlug
   */
  getGuide(categorySlug, guideSlug): Observable<GuideCategory> {
    return this._httpClient.get<GuideCategory>('api/pages/help-center/guide', {
      params: {
        categorySlug,
        guideSlug
      }
    }).pipe(
      tap((response: any) => {
        this._guide.next(response);
      })
    );
  }


  getPostCategories(): Observable<PostCategory[]> {
    return this._httpClient.get<PostCategory[]>(`${environment.apiEndpoint}post/category/all/active`).pipe(
      tap((response: any) => {
        this._postCategory.next(response);
      })
    );
  }
  getPostByCategory(slug): Observable<PostCategory[]> {
    return this._httpClient.get<PostCategory[]>(`${environment.apiEndpoint}post/category/${slug}`).pipe(
      tap((response: any) => {
        this._postCategory.next(response);
      })
    );
  }

  getOnePost(slug): Observable<Post> {
    return this._httpClient.get<Post>(`${environment.apiEndpoint}post/${slug}`).pipe(
      tap((response: any) => {

        this._post.next(response);
      })
    );
  }
  getUnreadPost(idUsuario): Observable<Post> {
    return this._httpClient.get<Post>(`${environment.apiEndpoint}post/unreaded/${idUsuario}`);
  }

  enviarMensagem(body: MessageSuporte): Observable<any> {
    return this._httpClient.post<any>(`${environment.apiEndpoint}suporte/mensagem`, body);
  }
}
