import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriaFornecedorService {

  private baseUrl = environment.apiEndpoint+'/categoria-fornecedor';

  constructor(private http: HttpClient) { }

  createCategoriaFornecedor(categoriaFornecedor: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}`, categoriaFornecedor);
  }

  getCategoriaFornecedorList(): Observable<any> {
    return this.http.get(`${this.baseUrl}`);
  }
  getCategoriaFornecedorListProspect(): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/prospect/categoria-fornecedor`);
  }

  getCategoriaFornecedor(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  updateCategoriaFornecedor(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}`, value);
  }

  deleteCategoriaFornecedor(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
  searchCategoriaFornecedor(keyword: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/search?term=${keyword}`);
  }
  searchCategoriaFornecedorProspect(keyword: string): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/prospect/search?term=${keyword}`);
  }
}
