<div class="flex flex-wrap">
  <p-autoComplete appendTo="body" [(ngModel)]="vendedorSelected" [showEmptyMessage]="true" [showClear]="true"
    [field]="'email'" [delay]="500" [dataKey]="'id'" [suggestions]="filteredVendedores"
    (completeMethod)="filterVendedor($event)" placeholder="Buscar vendedor" field="email" [minLength]="1">
    <ng-template let-vendedor pTemplate="item">
      <div class="vendedor-item">
        <div>{{vendedor.name || " N/I "}} | {{ vendedor.email }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
  <button pButton pRipple class="p-button-raised p-button-primary" (click)="vincular()" class="px-6 ml-1"
    [disabled]="!vendedorValido()"><p-progressSpinner *ngIf="loadingVinculo" [fill]="'#ffffff'" strokeWidth="8" animationDuration=".5s" [style]="{width: '10px', height: '10px'}"></p-progressSpinner>Vincular</button>
</div>