<form [formGroup]="enderecoFormGroup" autocomplete="disabled" class="mt-1" > 
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-1">
    <span class="mat-label">Deseja informar um novo endereço?</span>
    <p-inputSwitch [(ngModel)]="informarNovoEndereco" [ngModelOptions]="{standalone: true}" ></p-inputSwitch>
  </div>
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-1" *ngIf="informarNovoEndereco">
    <span class="mat-label">Buscar Novo CEP *</span>
    <p-autoComplete showClear="true" formControlName="cep" [minLength]="7" [maxlength]="9" appendTo="body"
      field="cep" [suggestions]="filteredCEP" (completeMethod)="filterCEP($event)"
      (onSelect)="selectedEndereco($event)" [showEmptyMessage]="false" [emptyMessage]="'CEP nāo encontrado'">
      <ng-template let-enderecoT pTemplate="item">
        <div class=""> {{ enderecoT.logradouro }}, {{ enderecoT.bairro }}, {{ enderecoT.localidade }}, {{
          enderecoT.uf }} </div>
      </ng-template>
      <ng-template let-enderecoT pTemplate="selectedItem">
        <div class="">{{ enderecoT.cep }}</div>
      </ng-template>
    </p-autoComplete>
    <small class="invalid-feedback"
      *ngIf="enderecoFormGroup && enderecoFormGroup.controls.cep.touched && enderecoFormGroup.controls.cep.hasError('required')">O CEP é obrigatório.</small>
  </div>
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-4">
    <mat-form-field *ngIf="!informarNovoEndereco">
      <mat-label>CEP</mat-label>
      <input matInput formControlName="cep" readonly>
      <mat-error *ngIf="enderecoFormGroup && enderecoFormGroup.controls.cep.hasError('required')">O campo é obrigatório.</mat-error>
    </mat-form-field>
    <mat-form-field >
      <mat-label>Logradouro</mat-label>
      <input matInput formControlName="logradouro" [readonly]="!informarNovoEndereco" >
      <mat-error *ngIf="enderecoFormGroup && enderecoFormGroup.controls.logradouro.hasError('required')">O campo é obrigatório.</mat-error>
    </mat-form-field>
  </div>
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-2">
    <div class="flex-col w-full">
      <mat-form-field>
        <mat-label>Complemento(Opcional)</mat-label>
        <input matInput formControlName="complemento" [readonly]="!informarNovoEndereco">
        <mat-error *ngIf="enderecoFormGroup && enderecoFormGroup.controls.complemento.hasError('required')">O campo é obrigatório.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div
  class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2">
  <mat-form-field>
    <mat-label>Numero(Opcional)</mat-label>
    <input matInput formControlName="numero" [readonly]="!informarNovoEndereco">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Bairro</mat-label>
    <input matInput formControlName="bairro" [readonly]="!informarNovoEndereco" >
    <mat-error *ngIf="enderecoFormGroup && enderecoFormGroup.controls.bairro.hasError('required')">O campo é obrigatório.</mat-error>
  </mat-form-field>
</div>
<div
  class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2">
  <mat-form-field>
    <mat-label>Cidade</mat-label>
    <input matInput formControlName="municipio" readonly>
    <mat-error *ngIf="enderecoFormGroup && enderecoFormGroup.controls.municipio.hasError('required')">O campo é obrigatório.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Estado</mat-label>
    <input matInput formControlName="uf" readonly >
    <mat-error *ngIf="enderecoFormGroup && enderecoFormGroup.controls.uf.hasError('required')">O campo é obrigatório.</mat-error>
  </mat-form-field>
</div>

<div
  class="flex items-center justify-end  -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
  <button class="submit-button treo-mat-button-large" mat-flat-button [color]="'primary'"
    [disabled]="enderecoFormGroup.disabled" (click)="salvarEndereco()">
    <span *ngIf="!enderecoFormGroup.disabled"> Salvar e Prosseguir </span>
    <mat-spinner *ngIf="enderecoFormGroup.disabled" [diameter]="24"
      [mode]="'indeterminate'"></mat-spinner>
  </button>
</div>
</form>