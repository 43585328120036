import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from 'app/shared/models/cliente.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConviteService {
  readonly SERVER_URL = environment.apiEndpoint;
  constructor(
    private _httpClient: HttpClient
  ) { 

  }

  getClienteData(key: String): Observable<any>{
    return this._httpClient.get<any>(`${this.SERVER_URL}cliente/key/${key}`);
  }
  getClienteDataProspect(key: String): Observable<any>{
    return this._httpClient.get<any>(`${this.SERVER_URL}prospect/cliente/key/${key}`);
  }
  salvarCliente(key: String, body: any): Observable<any>{
      // Do your action here...
      return this._httpClient.post<any>(`${this.SERVER_URL}usuario/registrar/${key}`,body);
  }

  create(key: String,user: any): Observable<any>{
      return this._httpClient.post<any>(`${this.SERVER_URL}usuario/create/${key}`,user);
  }

  createVendedorComFornecedor(user: any): Observable<any>{
      return this._httpClient.post<any>(`${this.SERVER_URL}usuario/create/fornecedor`,user);
  }
  cadastrarProspectVendedorVinculaFornecedor(vendedor: any): Observable<any>{
      return this._httpClient.post<any>(`${this.SERVER_URL}usuario/create/prospect/vendedor`,vendedor);
  }
  cadastrarProspectVendedorVinculaFornecedorProspect(vendedor: any): Observable<any>{
      return this._httpClient.post<any>(`${this.SERVER_URL}prospect/usuario/create/prospect/vendedor`,vendedor);
  }
  adicionarCliente(key: String): Observable<any>
  {
      return this._httpClient.post<any>(`${this.SERVER_URL}usuario/cliente`,{key: key});
  }
}
