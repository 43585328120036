import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[capitalizeFirstLetter]'
})
export class CapitalizeFirstLetterDirective {
  @Input('capitalizeFirstLetter') text: string;

  constructor() {}

  transform(): string {
    if (!this.text) {
      return '';
    }

    return this.text.charAt(0).toUpperCase() + this.text.slice(1).toLowerCase().replaceAll("-"," ");
  }
}