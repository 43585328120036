import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import Utils from '@shared/helpers/utils';
import { Cliente } from '@shared/models/cliente.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'form-buscar-vendedor-eh-vincular-no-fornecedor',
  templateUrl: './form-buscar-vendedor-eh-vincular-no-fornecedor.component.html',
  styleUrls: ['./form-buscar-vendedor-eh-vincular-no-fornecedor.component.scss']
})
export class FormBuscarVendedorEhVincularNoFornecedorComponent implements OnInit,AfterViewInit {
  validacaoUsuarioFormGroup: FormGroup;
  loading: boolean = false;

  @Input('cnpj') cnpj: string;
  
  @Output('vendedorAtualizado') emitter = new EventEmitter<Usuario>();
  @Output('needToRegister') needToRegister = new EventEmitter<boolean>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() messageERROR = new EventEmitter<string>();


  constructor(
    private _formBuilder: FormBuilder,
    private _fornecedorService: FornecedorService,
    private _messageService: MessageService,
  ) { 
    this.validacaoUsuarioFormGroup = this._formBuilder.group({
      email: [null, Validators.required],
    });
  }

  ngAfterViewInit() {
    this.formReady.emit(this.validacaoUsuarioFormGroup);
  }

  ngOnInit(): void {
    
  }

  buscarUsuarioEhVincularCNPJaoFornecedor(): void {
    this.messageERROR.emit(null);
    this.validacaoUsuarioFormGroup.markAllAsTouched();
    if (this.validacaoUsuarioFormGroup.invalid) {
      const errorMessages = Utils.getErrorMessages(this.validacaoUsuarioFormGroup);
      if (errorMessages) {
        console.error(errorMessages);
        this.messageERROR.emit(errorMessages);
        return;
      }
      return;
    }
    this.validacaoUsuarioFormGroup.disable();
    const cnpj = Utils.manterSomenteNumeros(this.cnpj);
    const { email } = this.validacaoUsuarioFormGroup.getRawValue();
    this._fornecedorService.consultarEmailEhVincularProspect(cnpj, email).subscribe({
      next: (res: any) => {
        this.validacaoUsuarioFormGroup.enable();
        if (res.hasUser == false && res.vinculo == false) {
          this.needToRegister.emit(true);
          this._messageService.add({ severity: 'warn', summary: 'Atenção', detail: res.message });
        } else {
          this.needToRegister.emit(false);
          this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: "Cnpj consultado com sucesso." });
        }
        this.emitter.emit({...res,email: email} as Usuario);
      },
      error: (err) => {
        this.validacaoUsuarioFormGroup.enable();
        console.error(err)
        this.messageERROR.emit(`Houve um erro,${err.error.message || err.error.error || err.error}`);
        // this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: err.error.message });
      }
    })
  }
}
