import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { UserService } from '@shared/services/user.service';
import { environment } from 'environments/environment';
import { CondicaoPagamentoEntrega, Cotacao, CotacaoItem } from '@shared/models/cotacao.interface';
import { AnexoUAU, ArquivoDoAnexoUAU } from '@shared/models/anexoUAU.interface';
@Injectable({
  providedIn: 'root'
})
export class CotacoesService {
  private _cotacoes: BehaviorSubject<any[]>;
  private _cotacao: BehaviorSubject<Cotacao | null>;
  private _cotacaoItem: BehaviorSubject<CotacaoItem[] | null>;
  private _fornecedor: any;
  readonly SERVER_URL = environment.apiEndpoint;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {
    // Set the private defaults
    this._cotacoes = new BehaviorSubject([]);
    this._cotacao = new BehaviorSubject(null);
    this._cotacaoItem = new BehaviorSubject(null);


    // this._brands = new BehaviorSubject(null);
  }

  // get CotacaoData(): Observable<Cotacao[]> {
  //     // return the subject here
  //     // subscribers will will notified when the data is refreshed
  //     return this._cotacoesData$.asObservable();
  // }
  setCotacoes(value: any) {
    // this._cotacoes.next(value);
    const current = this._cotacoes.value;
    const newest = [...current, ...value];
    this._cotacoes.next(newest);
  }
  get cotacoes$(): Observable<Cotacao[]> {
    return this._cotacoes.asObservable();
  }

  /**
   * Getter for _cotacao
   */
  get cotacao$(): Observable<Cotacao> {
    return this._cotacao.asObservable();
  }
  clearCotacoes() {

    this._cotacoes.next([]);

  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get brands
   */
  // getCotacoes(): Observable<Cotacao[]>
  // {
  //     const key = sessionStorage.getItem("clientes"); 

  //     // this._httpClient.post(this.SERVER_URL+'cotacao/BuscarCotacaoAbertaFornecedor',{cnpj: cnpj},{headers})
  //     // .subscribe((
  //     //     res:any)=>{return res.data})
  //     if(key == null || key == undefined || cnpj == null || cnpj == undefined){
  //         throwError(new Error('Nāo foi possivel carregar as informaçōes!'));
  //     }
  //     return this._httpClient.post<Cotacao[]>(`${this.SERVER_URL}cotacao/BuscarCotacaoAbertaFornecedor/${key}`,{cnpj: cnpj},{headers}).pipe(
  //         tap((cotacoes) => {           
  //             this.cotacoes = cotacoes;     
  //             this._cotacoes.next(cotacoes);
  //         })
  //     );
  // }
  getCadaCotacoes(cliente_key: String): Observable<Cotacao[]> {
    return this._httpClient.post<Cotacao[]>(`${this.SERVER_URL}cotacao/BuscarCotacaoAbertaFornecedor/${cliente_key}`, {}).pipe(
      tap((cotacoes) => {
        this.setCotacoes(cotacoes);
      })
    );
  }
  buscarCotacoesFiltradasPorSPE(cliente_id: string, cnpj: string): Observable<any> {
    const headers = new HttpHeaders({
      "cliente-id": cliente_id,
      "cnpj-selected": cnpj
    });
    return this._httpClient.get<Cotacao[]>(`${this.SERVER_URL}cotacao/BuscarCotacoes`, { headers: headers }).pipe(
      tap((cotacoes) => {
        if (cotacoes && cotacoes.length > 0) {
          this.setCotacoes(cotacoes);
        }
        return cotacoes;
      })
    );
  }
  /**
   * Get product by id
   */
  getCotacaoItems(empresa: Number, numeroCotacao: Number, fornecedor: Number, origem: Number, cliente_key: String): Observable<Cotacao> {
    return this._httpClient.post<Cotacao>(`${this.SERVER_URL}cotacao/BuscarItensCotacaoFornecedor/${cliente_key}`, {
      empresa: empresa,
      cotacao: numeroCotacao,
      fornecedor: fornecedor,
      origem: origem,
    }).pipe(
      tap((cotacao) => {
        this._cotacao.next(cotacao);
      })
    );
  }
  AtualizarItemCotacao(cotacaoItems: CotacaoItem[], cotacao: Cotacao, cliente_key: string): Observable<Cotacao> {
    return this._httpClient.post<any>(`${this.SERVER_URL}cotacao/AtualizarItemCotacao/${cliente_key}`, { items: cotacaoItems, cotacao: cotacao });
  }
  AtualizarItemPorItemCotacao(cotacaoItems: CotacaoItem, empresa: Number, origem: Number, cliente_key: String): Observable<Cotacao> {
    return this._httpClient.post<any>(`${this.SERVER_URL}cotacao/AtualizarItemPorItemCotacao/${cliente_key}`, { item: cotacaoItems, empresa: empresa, origem: origem });

  }
  AtualizarCondicaoPagamentoEntrega(condicaoPagamentoentrega: CondicaoPagamentoEntrega, cliente_key: String, clienteId: String, origem: number): Observable<Cotacao> {
    return this._httpClient.post<any>(`${this.SERVER_URL}cotacao/AtualizarCondicaoPagamentoEntrega/${cliente_key}`, { ...condicaoPagamentoentrega, cliente: clienteId, origem: origem });
  }
  // cotacao local
  ListarCotacoes(cliente_key: String): Observable<Cotacao> {
    return this._httpClient.get<any>(`${this.SERVER_URL}cotacao/list/${cliente_key}`);
  }
  SalvarCotacao(cotacao: Cotacao, cliente_key: String): Observable<Cotacao> {
    return this._httpClient.post<any>(`${this.SERVER_URL}cotacao/salvarCotacao/${cliente_key}`, { cotacao: cotacao });
  }
  SalvarItemsCotacao(cotacao: Cotacao, cotacaoItems: CotacaoItem[], cliente_key: String): Observable<Cotacao> {
    return this._httpClient.post<any>(`${this.SERVER_URL}cotacao/salvarItensCotacao/${cliente_key}`, { itens: cotacaoItems, cotacao: cotacao });
  }
  ShowCotacao(cotacao_id: number): Observable<Cotacao> {
    return this._httpClient.get<any>(`${this.SERVER_URL}cotacao/showCotacao/${cotacao_id}`);
  }

  ExecutarConsultaGeralDashboard(id: number, clienteKey: String, cnpj: String, parameter: String): Observable<any> {
    return this._httpClient.post<Cotacao[]>(`${this.SERVER_URL}rotinasgerais/ExecutarConsultaGeralDashboard/${clienteKey}`, {
      "Id": id,
      "Personalizado": "1",
      "Parameters": [
        parameter, cnpj
      ]
    }).pipe(catchError(error => {
      return error;
    }), mergeMap(something => {
      if (something) {
        return of(something)
      } else {
        return EMPTY;
      }
    })
    )
  }
  
  EnviarEmailDeDesconto(clienteKey: String, body: any): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}email/descontoCotacao/${clienteKey}`, body);
  }

  AdicionarFornecedorCotacao(clienteId: string,fornecedorId: string,cotacaoId: string): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}cotacao/AdicionarFornecedorCotacao`,{clienteId,fornecedorId,cotacaoId});
  }

  EnviarConviteCadastroViaEmail(clienteId: string,fornecedorId:string, emailAlvo: string): Observable<any> {
    return this._httpClient.get<any>(environment.workflow.endoint);
  }

  AnexarArquivosBase64(clienteKey: string, body: AnexoUAU): Observable<any> {
    return this._httpClient.post(`${this.SERVER_URL}Anexo/AnexarArquivosBase64Request`, body);
  }
}
