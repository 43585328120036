import { AbstractControl, ValidatorFn } from '@angular/forms';
import { parseISO, isBefore } from 'date-fns';

export function dataInicioMenorQueDataFinalValidator(dataInicioKey: string, dataFinalKey: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const dataInicioControl = control.get(dataInicioKey);
    const dataFinalControl = control.get(dataFinalKey);

    // Check if both fields have values
    const haveBothFieldsValues = dataInicioControl?.value !== null && dataFinalControl?.value !== null;

    if (!haveBothFieldsValues) {
      // If both fields do not have values, disable the validation
      dataInicioControl?.setErrors(null);
      dataFinalControl?.setErrors(null);
      return null;
    }

    const dataInicio = parseISO(dataInicioControl.value);
    const dataFinal = parseISO(dataFinalControl.value);

    if (isBefore(dataInicio, dataFinal)) {
      dataInicioControl.setErrors(null); // Set control as valid
      dataFinalControl.setErrors(null); // Set control as valid
      return null; // Return null if validation succeeds
    } else {
      dataInicioControl.setErrors({ dataInicioMenorQueDataFinal: true }); // Set control as invalid
      dataFinalControl.setErrors({ dataInicioMenorQueDataFinal: true }); // Set control as invalid
      return { dataInicioMenorQueDataFinal: true }; // Return an object with the property 'dataInicioMenorQueDataFinal' as true if validation fails
    }
  }
}

