<form [formGroup]="cadastroContaVendedorForm" class="mt-1" >
  <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
    <mat-form-field class="treo-mat-dense flex-auto">
      <mat-label class=" font-semibold mb-1"> Nome Completo de Vendedor</mat-label>
      <input matInput type="text" formControlName="name" required />
      <mat-error *ngIf="cadastroContaVendedorForm.get('name').hasError('required')"> Nome completo é
        obrigatório. </mat-error>
    </mat-form-field>
  </div>
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-2">
    <span class="font-semibold">Telefone do Vendedor *</span>
    <input class="p-inputtext p-component p-element p-inputmask" mask="(00) 0000-0000||(00) 0 0000-0000" required formControlName="telefone" />
    <small class="invalid-feedback"
      *ngIf="cadastroContaVendedorForm && cadastroContaVendedorForm.controls.telefone.touched && cadastroContaVendedorForm.controls.telefone.hasError('required')">O
      Campo é obrigatório.</small>
  </div>
  <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
    <mat-form-field class="treo-mat-dense flex-auto">
      <mat-label class="font-semibold mb-1"> Senha de Acesso</mat-label>
      <input id="c_password" matInput type="password" formControlName="c_password" #c_passwordField
        required />
      <button mat-icon-button type="button"
        (click)="c_passwordField.type === 'password' ? (c_passwordField.type = 'text') : (c_passwordField.type = 'password')"
        matSuffix>
        <mat-icon *ngIf="c_passwordField.type === 'password'" [svgIcon]="'visibility'"></mat-icon>
        <mat-icon *ngIf="c_passwordField.type === 'text'" [svgIcon]="'visibility_off'"></mat-icon>
      </button>
      <mat-error *ngIf="cadastroContaVendedorForm.get('c_password').hasError('required')"> A senha é
        obrigatória. </mat-error>
      <mat-error *ngIf="cadastroContaVendedorForm.get('c_password').hasError('mustMatch')"> As senhas nāo sāo
        iguais. </mat-error>
      <mat-error *ngIf="cadastroContaVendedorForm.get('c_password').hasError('minlength')">
        A senha deve conter no mínimo 8 caracteres, entre letras e números.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
    <mat-form-field class="treo-mat-dense flex-auto">
      <mat-label class="font-semibold mb-1"> Confirmar Senha de Acesso</mat-label>
      <input id="password-confirm" matInput type="password" formControlName="passwordConfirm"
        #passwordConfirmField required />
      <button mat-icon-button type="button" (click)="
          passwordConfirmField.type === 'password'
            ? (passwordConfirmField.type = 'text')
            : (passwordConfirmField.type = 'password')
        " matSuffix>
        <mat-icon *ngIf="passwordConfirmField.type === 'password'" [svgIcon]="'visibility'"> </mat-icon>
        <mat-icon *ngIf="passwordConfirmField.type === 'text'" [svgIcon]="'visibility_off'"> </mat-icon>
      </button>
      <mat-error *ngIf="cadastroContaVendedorForm.get('passwordConfirm').hasError('required')"> A senha é
        obrigatória. </mat-error>
      <mat-error *ngIf="cadastroContaVendedorForm.get('passwordConfirm').hasError('mustMatch')"> As senhas nāo
        sāo iguais. </mat-error>
      <mat-error *ngIf="cadastroContaVendedorForm.get('passwordConfirm').hasError('minlength')">
        A senha deve conter no mínimo 8 caracteres, entre letras e números.
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <!-- <button mat-button matStepperPrevious>Voltar</button> -->
    <button class="submit-button treo-mat-button-large" mat-flat-button [color]="'primary'"
      [disabled]="cadastroContaVendedorForm.disabled" (click)="criarUsuarioEhVincularCNPJ()">
      <span *ngIf="!cadastroContaVendedorForm.disabled"> Cadastrar e Prosseguir </span>
      <mat-spinner *ngIf="cadastroContaVendedorForm.disabled" [diameter]="24"
        [mode]="'indeterminate'"></mat-spinner>
    </button>
  </div>
</form>