import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyFirstLetterUpperCase'
})
export class OnlyFirstLetterUpperCasePipe implements PipeTransform {

  transform(value: string): String {
    return value.charAt(0).toUpperCase()+(value.slice(1)).toLowerCase();
  }

}