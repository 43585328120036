import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from '@shared/models/cliente.interface';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { DocumentoService } from '@shared/services/documento.service';
import { DocumentoTiposService } from '@shared/services/documentoTipos.service';
import { FileService } from '@shared/services/file.service';
import { UserService } from '@shared/services/user.service';
import { FileSizePipe } from 'app/shared/pipes/fileSize.pipe';
import { format, parseISO } from 'date-fns';
import { FileInputComponent } from 'ngx-material-file-input';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-editar-documento-modal',
  templateUrl: './editar-documento-modal.component.html',
  styleUrls: ['./editar-documento-modal.component.scss']
})
export class EditarDocumentoModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @ViewChild("file") file: FileInputComponent;

  documentoTipos: any[] = [];
  fileName: string;
  private destroyed$ = new Subject();
  user: Usuario;
  selectedFile;
  uploadedFiles = [];
  loading: boolean = false;
  inRoute: boolean = false;
  role: string;
  message: TreoMessage  = {show: false};
  mimeType;
  urlFile;

  @Input() documento: Documento;
  @Input() openDialog: boolean;
  @Input() fornecedor: Fornecedor;
  @Input() somenteVisualizar: boolean;
  @Input('clienteId')clienteId?: string;
  
  constructor(
    private _formBuilder: FormBuilder,
    private _documentoService: DocumentoService,
    private _documentoTipoService: DocumentoTiposService,
    private _userService: UserService,
    private _fileSizePipe: FileSizePipe,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _config: DynamicDialogConfig,
    private _dinamicDialogRef: DynamicDialogRef,
    private _fileService: FileService,
    ) {
        this.role = this._activatedRoute.snapshot.data.role;
        if(this._config.data && (this._config.data.create == true || this._config.data.documento)) {
          this.documento = this._config.data.documento;
          this.mimeType = this.documento.file.mimetype;
          this.urlFile = this.getUrlFile(this.documento.file.id)
        }else{
          this.inRoute = true; // senao tem dado do dialoag é rota
        this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
          this.user = data;
        })
    }
    this.buidForm()
    
  }

  ngOnDestroy(): void {
    this.destroyed$.unsubscribe();
  }
  ngOnInit(): void {

    this.loadDocumentoTipos()
  }
  onShow(){
    this.buidForm()
  }
  getUrlFile(url) {
    return this._fileService.urlViewFile(url);
  }
  buidForm(){
    this.form = this._formBuilder.group({
      numero: [this.documento?.numero , Validators.required],
      dataEmissao: [this.documento && this.documento.dataEmissao ? format(parseISO(this.documento?.dataEmissao.toString().split("T")[0]), 'yyyy-MM-dd') : null , Validators.required],
      dataValidade: [this.documento && this.documento.dataValidade ? format(parseISO(this.documento?.dataValidade.toString().split("T")[0]), 'yyyy-MM-dd') : null, Validators.required],
      nome: [this.documento?.nome , Validators.required], 
      tipo: [(this.documento.tipo?.id || this.documento.tipo?._id) , Validators.required],
      atualizadoPor: [ (this.user?.id || this.user?._id), Validators.required],
      observacao: [this.documento?.observacao ],
      // codigoDeVerificacao: [this.documento?.codigoDeVerificacao],
      // inscricaoMunicipal: [this.documento?.inscricaoMunicipal],
      cliente: [this?.clienteId ?? null],
    });
    // if(this.documento && this.documento.dataEmissao) this.form.controls.dataEmissao.setValue();
    // if(this.documento && this.documento.dataValidade) this.form.controls.dataValidade.setValue(format(parseISO(this.documento.dataValidade.toString().split("T")[0]), 'yyyy-MM-dd'));
  }
  viewFile() {
    const url = this._fileService.urlViewFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  upload() {
    try {
      this.form.markAllAsTouched();
      if (!this.form.valid) {
        return;
      }
      this.message = {
        show: false,
        content: null
      };
      this.loading = true;
      const formValues = this.form.getRawValue();
      this._documentoService.updateOne(this.documento.id, formValues).pipe(takeUntil(this.destroyed$)).subscribe(
        (response: any) => {
          this.documento = response.data;
          this.message.type = 'success';
          this.message.content = `Documento criado com sucesso`;
          this.message.show = true;
          this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: `Documento criado com sucesso` })
          
          this.loading = false;
          this._dinamicDialogRef.close()
        },(error: any) => {
          this.message.type = 'error';
          this.message.content = `Houve um erro: ${error?.message}`;
          this.message.show = true;
          this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true })
          console.error(error);
          this.loading = false;
        }
      );
    } catch (error) {
      this.loading = false;
      this.message.type = 'error';
      this.message.content = `Houve um erro: ${error?.message}`;
      this.message.show = true;
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true })
    }
  }

  loadDocumentoTipos() {
    try {
      this._documentoTipoService.getSomenteAtivos().pipe(takeUntil(this.destroyed$)).subscribe((data) => {
        this.documentoTipos = data;
      })
    } catch (error) {
      this.message.type = 'error';
      this.message.content = `Houve um erro: ${error?.message}`;
      this.message.show = true;
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true })
    }
  }
  removeFile(file: string) {
    // Lógica para remover o arquivo
    
    this.documento.file = null;
    this.form.controls.file.setValue(null);
  }
  getSizeFormated(size: number) {
    return this._fileSizePipe.transform(size)
  }
  handleFileChange(event) {
    console.info(event)
  }
  getFileName(file){
    if(file && file.mimetype && file.mimetype != ""){
      return `${file.filename}.${file.mimetype.split("/")[1]}`
    }else{
      return `${ file.id }`
    }
  }
  erroImage(event){
    
  }
}
