import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Fornecedor from '@shared/models/fornecedor.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { CotacoesService } from '@shared/services/cotacoes.service';
import { HelpCenterService } from '@shared/services/help-center.service';
import { UserService } from '@shared/services/user.service';
import {
  ApexAxisChartSeries,
  ApexChart, ApexDataLabels, ApexFill, ApexPlotOptions, ApexStroke, ApexYAxis, ChartComponent
} from "ng-apexcharts";
import { Subject, timeout } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UTILS_DASHBOARD } from './utils_dashboard';



export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: any; //ApexXAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  colors: string[];
  labels?: string[]; // Adicione esta linha
};
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  user: Usuario;
  private destroyed$ = new Subject();
  public chartOptionsItensVendidos: Partial<ChartOptions>;
  dataChartOptionsItensVendidos: Array<any> = [];
  public chartOptionsItensAbertos: Partial<ChartOptions>;
  dataChartOptionsItensAbertos: Array<any> = [];
  public chartOptionsItensFechados: Partial<ChartOptions>;
  dataChartOptionsItensFechados: Array<any> = [];
  ftimes: number = 0;
  localItensVendidos: any;
  localItensAbertos: any;
  localItensFechados: any
  empresaSelected: Fornecedor;
  isLoading: Boolean = false;
  lastPostUnread: any = null;

  @ViewChild("chart_itens_vendidos") chart_itens_vendidos: ChartComponent;
  @ViewChild("chart_itens_abertos") chart_itens_abertos: ChartComponent;
  @ViewChild("chart_itens_fechados") chart_itens_fechados: ChartComponent;

  constructor(
    private cotacoesService: CotacoesService,
    private _userService: UserService,
    private _helpCenterSevice: HelpCenterService,
  ) {
    this.chartOptionsItensVendidos = {
      series: [],
      chart: {
        height: 350,
        type: "bar"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: []
      },
      fill: {},
      stroke: {},
      colors: []
    };
    this.chartOptionsItensAbertos = {
      series: [],
      chart: {
        height: 350,
        type: "bar"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: []
      },
      fill: {},
      stroke: {},
      colors: []
    };
    this.chartOptionsItensFechados =    {
      series: [],
      chart: {
        height: 350,
        type: "bar"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: []
      },
      fill: {},
      stroke: {},
      colors: []
    };
    this.dataChartOptionsItensVendidos = [];
    this.dataChartOptionsItensAbertos = [];
    this.dataChartOptionsItensFechados = [];
    this._userService.getUserData().subscribe((data) => {
      this.user = data as Usuario;
      this._helpCenterSevice.getUnreadPost(this.user.id).subscribe((res: any) => {
        this.lastPostUnread = res.data[0];
      })
      this.empresaSelected = this.user!.fornecedores[0];1
      this.newSelection({ value: this.empresaSelected });
    })
    this.localItensVendidos = localStorage.getItem("dataChartOptionsItensVendidos");
    if (this.localItensVendidos) {
      this.chartOptionsItensVendidos = this.chartBuilder("Quantidade", JSON.parse(this.localItensVendidos));
    }
    this.localItensAbertos = localStorage.getItem("dataChartOptionsItensAbertos");
    if (this.localItensAbertos) {
      this.chartOptionsItensAbertos = this.chartBuilder("Quantidade", JSON.parse(this.localItensAbertos));
    }
    this.localItensFechados = localStorage.getItem("dataChartOptionsItensFechados");
    if (this.localItensFechados) {
      this.chartOptionsItensFechados = this.chartBuilder("Quantidade", JSON.parse(this.localItensFechados));
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
  newSelection(event) {
    this.isLoading = false;
    this.dataChartOptionsItensVendidos = [];
    this.dataChartOptionsItensAbertos = [];
    this.dataChartOptionsItensFechados = [];
    localStorage.removeItem("dataChartOptionsItensVendidos");
    localStorage.removeItem("dataChartOptionsItensAbertos");
    localStorage.removeItem("dataChartOptionsItensFechados");
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
    this.destroyed$ = new Subject();
    this.loadData(event);
  }
  loadData(event) {
    const cnpj = event.value.rawCNPJ; 
    this.isLoading = true;
    let totalLoading = this.user.clientes.length;
    this.user.clientes.forEach(async (cliente) => {
      await this.cotacoesService
        .ExecutarConsultaGeralDashboard(+cliente.rotina_geral_dashboard, cliente.key, cnpj, "CNPJ")
        .pipe(takeUntil(this.destroyed$))
        .pipe(timeout(30 * 1000))
        .subscribe({

          next: (res: Array<any>) => {

            // 

            const xlabel = cliente.desc_emp.split(" ", 1)[0];
            this.dataChartOptionsItensVendidos.unshift({
              x: xlabel,
              y: res.filter((item) => item.Status == "Ordem de Compra").length
            })
            let abertos = res.filter((item) => item.Status == "Cotação Aberta");
            abertos = UTILS_DASHBOARD.toSetSize(abertos, "Num_Cot");
            this.dataChartOptionsItensAbertos.unshift({
              x: xlabel,
              y: abertos
            });
            let fechados = res.filter((item) => item.Status == "Ordem de Compra");
            fechados = UTILS_DASHBOARD.toSetSize(fechados, "Num_Cot");
            this.dataChartOptionsItensFechados.unshift({
              x: xlabel,
              y: fechados
            });

            // localStorage.setItem("dataChartOptionsItensVendidos",JSON.stringify(Object.assign({},this.dataChartOptionsItensVendidos,localStorage.getItem("dataChartOptionsItensVendidos"))));
            // localStorage.setItem("dataChartOptionsItensAbertos",JSON.stringify(Object.assign({},this.dataChartOptionsItensAbertos,localStorage.getItem("dataChartOptionsItensAbertos"))));
            // localStorage.setItem("dataChartOptionsItensFechados",JSON.stringify(Object.assign({},this.dataChartOptionsItensAbertos,localStorage.getItem("dataChartOptionsItensAbertos"))));
            localStorage.setItem("dataChartOptionsItensVendidos", JSON.stringify(this.dataChartOptionsItensVendidos));
            localStorage.setItem("dataChartOptionsItensAbertos", JSON.stringify(this.dataChartOptionsItensAbertos));
            localStorage.setItem("dataChartOptionsItensFechados", JSON.stringify(this.dataChartOptionsItensFechados));

            // this.ftimes++;
            // if(this.ftimes == this.user.clientes.length) {
            // this.chart_itens_vendidos.series = [{
            //   data: this.dataChartOptionsItensVendidos,
            // }]
            // this.chart_itens_abertos.series = [{
            //   data: this.dataChartOptionsItensAbertos,
            // }]
            // this.chart_itens_fechados.series = [{
            //   data: this.dataChartOptionsItensFechados,
            // }]
            this.chartOptionsItensVendidos = this.chartBuilder("Quantidade", this.dataChartOptionsItensVendidos);
            this.chartOptionsItensAbertos = this.chartBuilder("Quantidade", this.dataChartOptionsItensAbertos);
            this.chartOptionsItensFechados = this.chartBuilder("Quantidade", this.dataChartOptionsItensFechados);
            // }
            totalLoading--;
            if (totalLoading == 0) this.isLoading = false;



          },
          error: (err) => {

            totalLoading--;

            this.isLoading = false;
          },
        });
    });
  }
  chartBuilder(title, seriesData: Array<any>): Partial<ChartOptions> {
    return {
      series: [
        {
          name: "Quantidade",
          data: seriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      yaxis: {
        title: {
          text: title
        },
        max: function (max) {
          return max + seriesData.length
        }
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        //   categories: categories,
      },
      colors: [
        '#036637',
        '#e84f27',
        '#8990a0',
        '#89ba30',
        '#244293',
        '#f2d31d',
        '#132241',
        '#f4a01c',
      ],
      // fill: {
      //   colors: ['#036637','#244293','#f2d31d','#132241'],
      //   opacity: 0.9,
      //   type: 'solid',
      // }
    }
  };
}
