<form [formGroup]="dadosFornecedorFormGroup" autocomplete="disabled" class="mt-1">
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-2">
    <mat-form-field>
      <mat-label class="font-semibold">Apresentaçāo ao Cliente(Opcional)</mat-label>
      <textarea matInput formControlName="apresentacaoAoCliente" 
      maxlength="2000" placeholder="Ex.:Somos uma empresa dedicada a fornecer os melhores produtos e serviços aos nossos clientes." rows="5"></textarea>
      <mat-error
        *ngIf="dadosFornecedorFormGroup && dadosFornecedorFormGroup.controls.apresentacaoAoCliente.hasError('required')">O
        Campo é obrigatório.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="font-semibold">E-mail da Empresa</mat-label>
      <input matInput formControlName="email" type="email" >
      <mat-error *ngIf="dadosFornecedorFormGroup && dadosFornecedorFormGroup.controls.email.hasError('required')">O
        Campo é obrigatório.</mat-error>
      <mat-error *ngIf="dadosFornecedorFormGroup && dadosFornecedorFormGroup.controls.email.hasError('email')">O
        E-mail é inválido.</mat-error>
    </mat-form-field>

    <ng-template #campoTemplate let-campo let-rotulo="rotulo" let-mascara="mascara" let-erro="erro" let-placeholder="placeholder">
      <div class="treo-mat-dense w-full grid grid-cols-1 gap-2">
        <span class="font-semibold">{{ rotulo }}</span>
        <input type="text" class="p-inputtext p-component p-element p-inputmask" [mask]="mascara" [formControlName]="campo" placeholder="{{placeholder}}">
        <message-input-required [formGroup]="dadosFornecedorFormGroup" [field]="campo"></message-input-required>
        <small class="invalid-feedback" *ngIf="dadosFornecedorFormGroup.controls[campo].errors?.['minlength']">O Campo é inválido.</small>
      </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="campoTemplate; context: { $implicit: 'website', rotulo: 'Website da Empresa(Opcional)', placeholder: 'www.seusite.com.br' }"></ng-container>
    <ng-container *ngTemplateOutlet="campoTemplate; context: { $implicit: 'telefoneComercial', rotulo: 'Telefone Comercial da Empresa *', mascara: '(00) 0000-0000||(00) 0 0000-0000', placeholder: '(00) 0 0000-0000' }"></ng-container>
    <ng-container *ngTemplateOutlet="campoTemplate; context: { $implicit: 'telefoneAdministrativo', rotulo: 'Telefone Administrativo da Empresa(Opcional)', mascara: '(00) 0000-0000||(00) 0 0000-0000', placeholder: '(00) 0000-0000' }"></ng-container>
    <!-- Fim do novo código -->
    <hr>
    <div class="flex-col">
      <div formArrayName="dadosBancarios">
        <div [formGroupName]="0">
          <div class="flex-col">
            <span class="font-semibold">Dados Bancários - Banco {{ campoOpcional() }}</span>
            <p-dropdown [options]="bancos" formControlName="banco" [filter]="true" appendTo="body" [required]="true"
              filterBy="label" [showClear]="true" placeholder="Selecione um Banco">
            </p-dropdown>
            <small class="invalid-feedback" *ngIf="campoTemErro('banco', 'required')" >O Campo é obrigatório.</small>
            <small class="invalid-feedback" *ngIf="campoTemErro('banco', 'minlength')" >O Campo é inválido.</small>
          </div>
          <div class="flex-col">
            <span class="font-semibold" for="tipo">Dados Bancários - Tipo da Conta {{ campoOpcional() }}</span>
            <p-dropdown id="tipo" formControlName="tipo" [options]="tiposConta" [appendTo]="'body'" [showClear]="false" 
            [placeholder]="'Selecione um tipo'" [optionLabel]="'nome'" [optionValue]="'valor'"></p-dropdown>
            <small class="invalid-feedback"  *ngIf="campoTemErro('tipo', 'required')" >O Campo é obrigatório.</small>
          </div>
          <div class="flex-col">
            <span class="font-semibold" for="conta">Dados Bancários - Conta com Dígito {{ campoOpcional() }}</span>
            <input id="conta" type="text" pInputText formControlName="conta" [dropSpecialCharacters]="false" mask="999999999999999-0" placeholder="9999999-9"  style="width: 100%;" (blur)="onContaBlur()" >
            <small class="invalid-feedback" *ngIf="campoTemErro('conta', 'required')" >O Campo é obrigatório.</small>
            <small class="invalid-feedback" *ngIf="campoTemErro('conta', 'minlength')" >O Campo é inválido.</small>
          </div>
          <div class="flex-col">
            <span class="font-semibold" for="agencia">Dados Bancários - Código da Agência {{ campoOpcional() }}</span>
            <input id="agencia" type="text" pInputText formControlName="agencia" mask="9999999999" placeholder="9999" style="width: 100%;" >
            <small class="invalid-feedback" *ngIf="campoTemErro('agencia', 'required')" >O Campo é obrigatório.</small>
            <small class="invalid-feedback" *ngIf="campoTemErro('agencia', 'minlength')" >O Campo é inválido.</small>
          </div>
          <div class="flex-col">
            <span class="font-semibold" for="nomeAgencia">Dados Bancários - Nome da Agência(Opcional)</span>
            <input id="nomeAgencia" type="text" pInputText formControlName="nomeAgencia" style="width: 100%;" maxlength="50" >
            <small class="invalid-feedback" *ngIf="campoTemErro('nomeAgencia', 'required')" >O Campo é obrigatório.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="submit-button treo-mat-button-large" mat-flat-button [color]="'primary'"
    [disabled]="dadosFornecedorFormGroup.disabled" (click)="salvarDadosDoFornecedor()">
    <span *ngIf="!dadosFornecedorFormGroup.disabled"> Salvar e Prosseguir </span>
    <mat-spinner *ngIf="dadosFornecedorFormGroup.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-spinner>
  </button>
</form>