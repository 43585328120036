<p-confirmPopup></p-confirmPopup>
<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="left">
      <h1>Requisiçāo: {{ requisicaoDocumento.tipo.nome }} | Status: {{ getLabelDoStatus() }} </h1>
    </div>
    <div class="right">
    </div>
  </div>
  <mat-stepper [linear]="true" #stepper style="width: 100%;height: 100%;">
    <mat-step state="requisicao" label="Requisiçāo" [completed]="requisicaoDocumento.respostaFornecedor != null">
      <div class="flex flex-col mt-2 px-8 pt-2 rounded overflow-hidden show-info">
        <div class="flex flex-col gap-4">
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Status: </strong>
            <span [class]="'badge status-' + requisicaoDocumento.status " >{{ status || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Fornecedor: </strong>
            <span>{{ requisicaoDocumento.fornecedorRequerido?.nome || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Cliente: </strong>
            <span>{{ requisicaoDocumento.cliente.desc_emp || "-"}}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="tipo">Tipo: </strong>
            <span>{{ requisicaoDocumento.tipo.nome || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Motivo: </strong>
            <span>{{ requisicaoDocumento.motivo || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="dataInicio">Data de Início: </strong>
            <span>{{ requisicaoDocumento.dataInicio| date: "dd/MM/yyyy":'UTC' }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="dataFinal">Prazo Final: </strong>
            <span>{{ (requisicaoDocumento.dataFinal == null ? "-" : requisicaoDocumento.dataFinal | date:
              "dd/MM/yyyy":'UTC') }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="observacao">Observaçāo: </strong>
            <span>{{ requisicaoDocumento.observacao || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="observacao">Solicitada por: </strong>
            <span>{{ requisicaoDocumento?.solicitadoPor?.email || 'Automático' }}</span>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-end border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <button matStepperNext mat-raised-button color="primary" class="px-6 ml-3"
          [disabled]="requisicaoDocumento.respostaFornecedor == null">Próxima</button>
      </div>
    </mat-step>
    <mat-step state="resposta" optional label="Resposta do Fornecedor"
      [completed]="requisicaoDocumento.documento != null">
      <div class="flex flex-col mt-2 px-8 pt-2 rounded overflow-hidden show-info">
        <div class="flex flex-col gap-4">
          <span class="treo-mat-dense flex-auto">
            <strong>Status: </strong>
            <span [class]="'badge status-' + requisicaoDocumento.status " >{{ status || "-" }}</span>
          </span>
          <span class="treo-mat-dense flex-auto" *ngIf="requisicaoDocumento.compradorAprovador">
            <strong>Respondido pelo Comprador: </strong>
            <span>{{ getAprovadaPor(requisicaoDocumento)  }}</span>
          </span>
          <div class="flex flex-col gap-4" *ngIf="requisicaoDocumento.documento">
            <span class="treo-mat-dense flex-auto">
              <strong>Tipo de Documento: </strong>
              <span>{{ requisicaoDocumento.tipo.nome || '-' }}</span>
            </span>
            <span class="treo-mat-dense flex-auto">
              <strong>Nome: </strong>
              <span>{{ requisicaoDocumento.documento?.nome || '-' }}</span>
            </span>
            <span class="treo-mat-dense flex-auto">
              <strong>Número: </strong>
              <span>{{ requisicaoDocumento.documento?.numero || '-' }}</span>
            </span>
            <span class="treo-mat-dense flex-auto">
              <strong>Data de Emissāo: </strong>
              <span>{{ requisicaoDocumento.documento.dataEmissao | date: "dd/MM/yyyy":'UTC' || '-' }}</span>
            </span>
            <span class="treo-mat-dense flex-auto">
              <strong>Data Validade: </strong>
              <span>{{ requisicaoDocumento.documento.dataValidade | date: "dd/MM/yyyy":'UTC'|| '-' }}</span>
            </span>
            <span class="treo-mat-dense flex-auto">
              <strong>Observaçāo do Documento: </strong>
              <span>{{ requisicaoDocumento.documento.observacao  || '-' }}</span>
            </span>
          </div>
          <span class="treo-mat-dense flex-auto" *ngIf="requisicaoDocumento.observacao">
            <strong>Observaçāo: </strong>
            <span>{{ requisicaoDocumento.observacao || '-' }}</span>
          </span>
          <span class="treo-mat-dense flex-auto">
            <strong>Respondida Por: </strong>
            <span>{{ getRespondidoPor(requisicaoDocumento)  }}</span>
          </span>
          <div class="treo-mat-dense flex-auto">
            <strong>Resposta do Fornecedor: </strong>
            <span>{{ requisicaoDocumento.respostaFornecedor || '-' }}</span>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-between border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <button mat-raised-button matStepperPrevious color="info" class="px-6 ml-3 justify-start">Voltar</button>
        <button matStepperNext mat-raised-button color="primary" class="px-6 ml-3 justify-end"
          [disabled]="requisicaoDocumento.documento == null">Próxima</button>
      </div>
    </mat-step>
    <mat-step state="documento" *ngIf="requisicaoDocumento.status != 'recusada'" [editable]="!ocorreuErro()"
      [label]="requisicaoDocumento.documento == null ? 'Documento pendente de envio' : 'Documento enviado'">

      <p-toolbar>
        <div class="p-toolbar-group-left">
        </div>
        <div class="p-toolbar-group-right">
          <p-button label="Visualizar" pTooltip="Visualizar em Aba" icon="pi pi-external-link " styleClass="p-button-secondary ml-2" (click)="viewFile()" ></p-button>
          <p-button label="Baixar" pTooltip="Baixar Arquivo" icon="pi pi-download " styleClass="p-button-success ml-2" (click)="downloadFile()" ></p-button>
        </div>
      </p-toolbar>
      <div class="mt-3 show-info">
        <ng-container *ngIf="mimeType">
          <ng-container *ngIf="mimeType.startsWith('image/')">
            <p-image [src]="urlFile" alt="Image" width="250" [preview]="true" (onImageError)="erroImage($event)"></p-image>
          </ng-container>
          <ng-container *ngIf="mimeType === 'application/pdf'">
            <ngx-extended-pdf-viewer [src]="urlFile" [height]="'600px'" [width]="'100%'" [useBrowserLocale]="true"
              [showToolbar]="false" [originalSize]="false" [minZoomLevel]="0.5" [maxZoomLevel]="3"
              [zoom]="'page-width'"></ngx-extended-pdf-viewer>
          </ng-container>
          <ng-container *ngIf="!mimeType.startsWith('image/') && mimeType !== 'application/pdf'">
            <div class="flex flex-col items-center justify-center space-y-2">
              <p>O Documento com extensāo "<strong>{{ (mimeType.split("/")[1]) }}</strong>" não pode ser visualizado aqui, será necessário realizar o download do arquivo.</p>
              <!-- <button pButton pRipple type="button" class="p-button-raised p-button-secondary" (click)="downloadFile()">Fazer o Download do Arquivo</button> -->
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!mimeType">
          <h6 class="text-center">Documento indisponível para visualização. </h6>
          <h6 class="text-center">Faça uma Solicitaçāo de Alteraçāo e Novo Envio para o Fornecedor.</h6>
        </ng-container>
      </div>
      <div
        class="flex items-center justify-between border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <button mat-raised-button matStepperPrevious color="info" class="px-6 ml-3 justify-start">Voltar</button>
        <button matStepperNext mat-raised-button color="primary" class="px-6 ml-3 justify-end"
          *ngIf="formAprovacao != null">Próxima</button>
      </div>
    </mat-step>
    <mat-step state="aprovacao" [editable]="precisaDeAprovacao()" [label]="getStatusPossiveisdeAprovacao() | capitalizeFirstLetter">
      <div *ngIf="precisaDeAprovacao();else jaPossuiAprovacao;">
          <form [formGroup]="formAprovacao" autocomplete="disabled"
          class="flex flex-col p-8 pb-0 overflow-hidden">
          <div class="flex flex-col gap-4">
            <!-- Fornecedor -->
            <div class="treo-mat-dense flex flex-col gap-2">
              <label for="status">Decisāo</label>
              <p-selectButton [options]="options" [disabled]="!hasAprovacao" formControlName="status" optionLabel="label" optionValue="value"
                (onChange)="onStatusChange($event)"></p-selectButton>
              <small id="status-error" class="p-error block"
                *ngIf="formAprovacao.get('status').errors?.required && formAprovacao.get('status').touched">Campo
                Obrigatório.</small>
            </div>
            <!-- justificativa Aprovacao Comprador -->
            <div class="treo-mat-dense flex flex-col gap-2"
              *ngIf="formAprovacao.controls.status.value !== options[0].value">
              <label for="justificativaAprovacaoComprador">Justificativa</label>
              <textarea class="border-2 rounded" inputId="textarea" rows="5" cols="30" pInputTextarea [disabled]="!hasAprovacao" [readonly]="!hasAprovacao"
                [autoResize]="autoResize" formControlName="justificativaAprovacaoComprador"
                [ngClass]="[ formAprovacao.get('justificativaAprovacaoComprador').invalid && formAprovacao.get('justificativaAprovacaoComprador').touched ? 'mat-input-invalid' : '']"></textarea>
              <small id="justificativaAprovacaoComprador-error" class="p-error block"
                *ngIf="formAprovacao.get('justificativaAprovacaoComprador').errors?.required && formAprovacao.get('justificativaAprovacaoComprador').touched">
                Campo Obrigatório.
              </small>
              <small id="justificativaAprovacaoComprador-error" class="p-error block"
                *ngIf="formAprovacao.get('justificativaAprovacaoComprador').errors?.minLength && formAprovacao.get('justificativaAprovacaoComprador').touched">
                Campo deve conter no minimo 10 caracteres.
              </small>
            </div>
            <treo-message style="width:100%;" [dismissed]="!message.show" [appearance]="message.appearance"
              [showIcon]="message.showIcon" [type]="message.type">
              {{ message.content }}
            </treo-message>
          </div>
        </form>
        <div
          class="flex items-center justify-between border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
          <button mat-raised-button matStepperPrevious color="info" class="px-6 ml-3 justify-start">Voltar</button>
          <button mat-raised-button (click)="aprovarRequisicaoRequest($event)" *ngIf="hasAprovacao" class="px-6 ml-3 justify-end" [disabled]="!formAprovacao.valid" color="primary"> Enviar </button>
        </div>
      </div>
      <ng-template #jaPossuiAprovacao>
        <div class="flex flex-col mt-2 px-8 pt-2 rounded overflow-hidden show-info">
          <div class="flex flex-col gap-4">
            <div class="treo-mat-dense flex-auto">
              <strong for="motivo">Status Da Requisiçāo: </strong>
                <span [class]="'badge status-' + requisicaoDocumento.status " >{{ status ? (status | onlyFirstLetterUpperCase) : "N/A" }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-4" >
            <span class="treo-mat-dense flex-auto">
              <strong>Resposta do Fornecedor: </strong> 
                <span>{{ requisicaoDocumento.respostaFornecedor || 'N/A' }}</span>
            </span>
          </div>
          <div class="flex flex-col gap-4" >
            <span class="treo-mat-dense flex-auto">
              <strong>Justificativa de Aprovação do Comprador: </strong>
              <span>{{ requisicaoDocumento.justificativaAprovacaoComprador || 'N/A'  }}</span>
            </span>
          </div>
        </div>
      </ng-template>

     
    </mat-step>
    <!-- Icon overrides. -->
    <ng-template matStepperIcon="requisicao">
      <mat-icon>description</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="resposta">
      <mat-icon>question_answer</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="documento">
      <mat-icon>attach_file</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="aprovacao">
      <mat-icon>thumbs_up_down</mat-icon>
    </ng-template>
  </mat-stepper>
</div>