import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeWithSuffix'
})
export class TimeWithSuffixPipe implements PipeTransform {
  transform(value: number, language: string = 'en'): string {
    let result: string;
    const isPlural = (value !== 1);

    switch (language) {
      case 'pt':
        if (value < 2) {
          result = `${value} minuto`;
        } else if (value < 60) {
          result = `${value} minutos`;
        } else if (value === 60) {
          result = `1 hora`;
        } else {
          const hours = Math.floor(value / 60);
          const minutes = value % 60;
          result = `${hours} hora${isPlural ? 's' : ''} e ${minutes} minuto${isPlural ? 's' : ''}`;
        }
        break;

      case 'es':
        if (value < 2) {
          result = `${value} minuto`;
        } else if (value < 60) {
          result = `${value} minutos`;
        } else if (value === 60) {
          result = `1 hora`;
        } else {
          const hours = Math.floor(value / 60);
          const minutes = value % 60;
          result = `${hours} hora${isPlural ? 's' : ''} y ${minutes} minuto${isPlural ? 's' : ''}`;
        }
        break;

      default:
        if (value < 2) {
          result = `${value} minute`;
        } else if (value < 60) {
          result = `${value} minutes`;
        } else if (value === 60) {
          result = `1 hour`;
        } else {
          const hours = Math.floor(value / 60);
          const minutes = value % 60;
          result = `${hours} hour${isPlural ? 's' : ''} and ${minutes} minute${isPlural ? 's' : ''}`;
        }
        break;
    }

    return result;
  }
}
