import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  Router,
  RouterEvent
} from "@angular/router";
import { environment } from '@environments';
import { TreoSplashScreenService } from '@treo/services/splash-screen';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showProgressBar: boolean = false;
  environment = environment;
  constructor(
    private _router: Router,
    private _splashScreenService: TreoSplashScreenService,
    private config: PrimeNGConfig,
  ) {
    this.config.setTranslation({
      accept: 'Aceitar',
      reject: 'Cancelar',
      cancel: 'Cancelar',
      clear: 'Limpar',
      startsWith: 'Começa com',
      contains: 'Contém',
      notContains: 'Nāo contém',
      endsWith: 'Termina com',
      equals: 'Igual',
      notEquals: 'Diferente',
      noFilter: 'Sem filtro',
      lt: 'Menor que',
      lte: 'Menor ou igual',
      gt: 'Maior que',
      gte: 'Maior ou igual',
      is: 'É',
      isNot: 'Nāo é',
      before: 'Antes',
      after: 'Depois',
      dateIs: 'Data igual',
      dateIsNot: 'Data diferente',
      dateBefore: 'Data anterior',
      dateAfter: 'Data posterior',
      apply: 'Aplicar',
      matchAll: 'Corresponder a todos',
      matchAny: 'Corresponder a qualquer',
      addRule: 'Adicionar Regra',
      removeRule: 'Remover Regra',
      choose: 'Escolher',
      upload: 'Envio',
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      dateFormat: 'mm/dd/yy',
      firstDayOfWeek: 0,
      today: 'Hoje',
      weekHeader: 'Semana',
      weak: 'Fraco',
      medium: 'Médio',
      strong: 'Forte',
      passwordPrompt: 'Insira a senha',
      emptyMessage: 'Nenhum resultado encontrado.',
      emptyFilterMessage: 'Nenhum resultado correspondente encontrado.'
    });
  }

  ngOnInit() {
    this.routerEvents();
  }
  routerEvents() {
    this._router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.showProgressBar = true;
          break;
        }
        case event instanceof ResolveStart: {
          this.showProgressBar = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.showProgressBar = false;
          this._splashScreenService.hide();
          break;
        }
        case event instanceof NavigationError: {
          this.showProgressBar = false;
          this._splashScreenService.hide();
          break;
        }
        case event instanceof NavigationCancel: {
          this.showProgressBar = false;
          this._splashScreenService.hide();
          break;
        }
        case event instanceof ResolveEnd: {
          this.showProgressBar = false;
          this._splashScreenService.hide();
          break;
        }
      }
    });
  }
}
