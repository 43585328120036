import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) { }

  changeRole(id: string, role: string): Observable<any> {
    const url = `${environment.apiEndpoint}role/change`;
    const payload = { id, role };
    return this.httpClient.post<any>(url, payload);
  }
}
