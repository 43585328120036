<form [formGroup]="validacaoUsuarioFormGroup" class="mt-1" >
  <mat-form-field class="treo-mat-dense flex-auto">
    <mat-label class="font-semibold mb-1"> E-mail</mat-label>
    <input matInput type="email" formControlName="email" autocomplete="disabled" required />
    <mat-error *ngIf="validacaoUsuarioFormGroup.get('email').hasError('required')"> O email é obrigatório.
    </mat-error>
    <mat-error *ngIf="validacaoUsuarioFormGroup.get('email').hasError('email')"> O email é invalido.
    </mat-error>
  </mat-form-field>
  <div>
    <!-- <button mat-button matStepperPrevious>Voltar</button> -->
    <button class="submit-button treo-mat-button-large" mat-flat-button [color]="'primary'"
      [disabled]="validacaoUsuarioFormGroup.disabled" (click)="buscarUsuarioEhVincularCNPJaoFornecedor()">
      <span *ngIf="!validacaoUsuarioFormGroup.disabled"> Validar E-mail e Prosseguir </span>
      <mat-spinner *ngIf="validacaoUsuarioFormGroup.disabled" [diameter]="24"
        [mode]="'indeterminate'"></mat-spinner>
    </button>
  </div>
</form>