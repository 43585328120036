import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from 'app/shared/models/fornecedor.interface';
import { FileSizePipe } from 'app/shared/pipes/fileSize.pipe';
import { DocumentoService } from '@shared/services/documento.service';
import { DocumentoTiposService } from '@shared/services/documentoTipos.service';
import { UserService } from '@shared/services/user.service';
import { FileInputComponent } from 'ngx-material-file-input';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { Usuario } from '@shared/models/usuario.interface';
import { Dialog } from 'primeng/dialog';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileService } from '@shared/services/file.service';
import ModeloDocumento from '@shared/models/modeloDocumento.interface';
import { Cliente } from '@shared/models/cliente.interface';

@Component({
  selector: 'app-criar-documento-modal',
  templateUrl: './criar-documento-modal.component.html',
  styleUrls: ['./criar-documento-modal.component.scss'],
  providers: [FileSizePipe,DynamicDialogRef]
})
export class CriarDocumentoModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @Input('openDialog') openDialog: boolean = false;
  @Input('fornecedor') fornecedor?: Fornecedor = null;
  @Input('fornecedorId') fornecedorId?: string = null ;
  @Input('documento') documento: Documento = {};
  @Input('headerTitle') headerTitle: string = 'Novo Documento';
  @Input('tipoDocumento') tipoDocumento?: string = null;
  @Input('enviadorPor') enviadorPor?: string = null;
  @Input('documentoObrigatorio') documentoObrigatorio?: boolean = null;
  @Input('documentoObrigatorioCampo') documentoObrigatorioCampo?: string = null;
  @Input('autoCloseOnFinish') autoCloseOnFinish: boolean = true;
  @Input('modeloDocumento') modeloDocumento: ModeloDocumento[];
  @Input('acimaModeloDocumentoMensagemAlert') acimaModeloDocumentoMensagemAlert: TemplateRef<any>;
  @Input('abaixoModeloDocumentoMensagemAlert') abaixoModeloDocumentoMensagemAlert: TemplateRef<any>;
  @Input('clienteId')clienteId?: string;

  @Output() newDocumentoEvent = new EventEmitter<any>();
  @Output() closeDialog = new EventEmitter<boolean>();

  @ViewChild("dialog") dialog: Dialog;
  @ViewChild("file") file: FileInputComponent;

  documentoTipos: any[] = [];
  fileName: string;
  private destroyed$ = new Subject();
  user: Usuario;
  selectedFile;
  uploadedFiles = [];
  valorSelecionadoDropdown = null;
  message:TreoMessage = {show: false};

  constructor(
    private _formBuilder: FormBuilder,
    private _documentoService: DocumentoService,
    private _documentoTipoService: DocumentoTiposService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _fileSizePipe: FileSizePipe,
    private _messageService: MessageService,
    private _dinamicDialogRef: DynamicDialogRef,
    private _fileService: FileService,
    ) {
    this.loadDocumentoTipos()
    this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
      this.user = data;
    })
  }
  ngOnDestroy(): void {
    this.destroyed$.unsubscribe()
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      numero: [null],
      dataEmissao: [null],
      dataValidade:  [null],
      nome:  [null],
      tipo:  [null],
      file: [null],
      enviadoPor: [null],
      observacao: [null],
      fornecedor: [null],
      documentoObrigatorio: [null],
      documentoObrigatorioCampo: [null],
    });
  }
  get formControl() {
    return this.form.controls;
  }

  onShowDialog(event: any) {
    this.atualizarForm();
  }

  atualizarForm(){
    this.form = this._formBuilder.group({
      numero: [(this.documento?.numero || '')],
      dataEmissao: [(this.documento?.dataEmissao || ''), Validators.required],
      dataValidade: [(this.documento?.dataValidade || null)],
      nome: ['', Validators.required],
      tipo: [this.tipoDocumento || this.documento?.tipo?.id, Validators.required],
      file: [null, Validators.required],
      enviadoPor: [(this.user?.id || this.enviadorPor || this.documento?.enviadoPor?.id )],
      observacao: [(this.documento?.observacao || '')],
      fornecedor: [(this.fornecedorId || this.fornecedor?.id || this.documento?.fornecedor?.id)],
      documentoObrigatorio: [this.documentoObrigatorio],
      documentoObrigatorioCampo: [this.documentoObrigatorioCampo],
    });

    // Set the current date as the default value for dataEmissao
    const currentDate = new Date().toISOString().split('T')[0];
    this.form.controls.dataEmissao.patchValue(currentDate);
    
    if (this.documento && this.documento.file) {
      this.form.controls.file.patchValue((this.documento.file.id), { disabled: true, readonly: true })
      this.file.disabled = true;
    }
    if (this.documento && this.documento.file) this.uploadedFiles = [this.documento.file];
  }
  viewFile() {
    const url = this._fileService.urlViewFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  upload() {
    this.message = {
      show: false,
      content: null
    };
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.form.disable()
    const formValues = this.form.getRawValue();
    
    
    const formData = new FormData();
    formData.append('numero', formValues.numero);
    formData.append('dataEmissao', formValues.dataEmissao);
    formData.append('dataValidade', formValues.dataValidade);
    formData.append('nome', formValues.nome);
    formData.append('tipo', formValues.tipo);
    formData.append('file', formValues.file.files[0]);
    formData.append('enviadoPor', formValues.enviadoPor);
    formData.append('observacao', formValues.observacao);
    formData.append('fornecedor', formValues.fornecedor);
    formData.append('codigoDeVerificacao', formValues.codigoDeVerificacao);
    formData.append('documentoObrigatorio', formValues.documentoObrigatorio);
    formData.append('documentoObrigatorioCampo', formValues.documentoObrigatorioCampo);
    formData.append('cliente', this.clienteId);
    this._documentoService.uploadNew(formValues.fornecedor,formData).pipe(takeUntil(this.destroyed$)).subscribe(
      (response: any) => {
        const documento = response.data;
        
        this.newDocumentoEvent.emit(documento);
        this.message = {
          show: true,
          type: 'success',
          content: 'Documento salvo com sucesso'
        };
        this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: `Documento salvo com sucesso` })
        this.hideDialog()
        this._dinamicDialogRef.close();
        this.form.enable()
      },
      (error: any) => {
        this.message = {
          show: true,
          type: 'error',
          content: `Houve um erro: ${error?.message}`
        };
        this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true })
        console.error(error);
        this.form.enable()
      }
    );
  }
  showImage(image) {
    return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + image)
  }
  loadDocumentoTipos() {
    this._documentoTipoService.getSomenteAtivosProspect().pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.documentoTipos = data;
    })
  }
  onBeforeUpload(event: any) {
    
    event.formData.append('origem', 'pre-cadastro-documento-fornecedor');
    event.formData.append('origemId', (this.fornecedor?.id || this.fornecedorId));
    event.formData.append('createdBy', this.user.id);
  }
  hideDialog() {
    this.openDialog = false;
    this.closeDialog.emit(this.openDialog)
  }
  getSizeFormated(size: number) {
    return this._fileSizePipe.transform(size)
  }
   atualizarValorCampo(event: any) {
    this.valorSelecionadoDropdown = this.documentoTipos.filter((e)=> e.id == event.value)
    if(this.valorSelecionadoDropdown && this.valorSelecionadoDropdown.length > 0){
      this.valorSelecionadoDropdown = this.valorSelecionadoDropdown[0]
      // Atualize o valor do campo de entrada com o valor selecionado do dropdown
      this.form.controls.nome.patchValue(`${this.valorSelecionadoDropdown.nome}`)
    }
  
  }
  copyToClipboard(valor: string) {
    // Seleciona e copia o texto do campo de entrada para a área de transferência
    navigator.clipboard.writeText(valor)
      .then(() => {
        // Feedback para o usuário de que o texto foi copiado
        alert('Valor copiado para a área de transferência!');
      })
      .catch(err => {
        console.error('Erro ao copiar para a área de transferência: ', err);
      });
  }
}
