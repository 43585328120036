import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '@shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

  readonly SERVER_URL = environment.apiEndpoint;

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _cookieService: CookieService,
  ) {}

  private _loadMessages(): Observable<any> {
    return this._httpClient.get('api/common/messages');
  }

  private _loadNavigation(): Observable<any> {
    return this._httpClient.get('api/common/navigation');
  }

  private _loadNotifications(): Observable<any> {
    return this._httpClient.get('api/common/notifications');
  }

  private _loadShortcuts(): Observable<any> {
    return this._httpClient.get('api/common/shortcuts');
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this._userService.fetchUserData(),
      this._loadNavigation(),
    ]).pipe(
      map((data) => {
        
        return {
          user: data[0],
          navigation: {
            compact: data[1].compact,
            default: data[1].default,
            futuristic: data[1].futuristic,
            horizontal: data[1].horizontal
          },
        };
      })
    );
  }
}