import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from './material/material.module';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

import { AutocompleteoffDirective } from './directives/autocompleteoff.directive';
import { CapitalizeFirstLetterDirective } from './directives/capitalize-first-letter.directive';
import { InvalidInputClassDirective } from './directives/invalidInputClass.directive';
import { PermissaoAcessoDirective } from './directives/permissao-contexto.directive';
import { SePermitidoDirective } from './directives/se-permitido-directive.directive';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { CustomDateFormatPipe } from './pipes/custom-date-format.pipe';
import { FormatarNumeroPipe } from './pipes/formatar-numero.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { OnlyFirstLetterUpperCasePipe } from './pipes/only-first-letter-upper-case.pipe';
import { ReplaceThousandSeparatorPipe } from './pipes/replace-thousand-separator.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TimeWithSuffixPipe } from './pipes/time-with-suffix.pipe';
import { WordsPipe } from './pipes/words.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { StatusCotacaoPipe } from './pipes/status-cotacao.pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    MaterialModule,
    TooltipModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MaterialModule,
    TruncatePipe,
    FileSizePipe,
    WordsPipe,
    CustomDateFormatPipe,
    OnlyFirstLetterUpperCasePipe,
    TimeWithSuffixPipe,
    SafeHtmlPipe,
    TruncatePipe,
    NumberFormatPipe,
    ReplaceThousandSeparatorPipe,
    FormatarNumeroPipe,
    AutocompleteoffDirective,
    InvalidInputClassDirective,
    PermissaoAcessoDirective,
    SePermitidoDirective,
    CapitalizeFirstLetterDirective,
    CapitalizeFirstLetterPipe,
    StatusCotacaoPipe,
  ],
  declarations: [
    TruncatePipe,
    FileSizePipe,
    WordsPipe,
    CustomDateFormatPipe,
    OnlyFirstLetterUpperCasePipe,
    TimeWithSuffixPipe,
    SafeHtmlPipe,
    TruncatePipe,
    ReplaceThousandSeparatorPipe,
    NumberFormatPipe,
    FormatarNumeroPipe,
    AutocompleteoffDirective,
    InvalidInputClassDirective,
    PermissaoAcessoDirective,
    SePermitidoDirective,
    CapitalizeFirstLetterDirective,
    CapitalizeFirstLetterPipe,
    StatusCotacaoPipe,
  ],
  providers: [
  ],
})
export class SharedModule {
}
