import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackofficeDashboardComponent } from './backoffice-dashboard.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from 'app/core/core.module';
import { MaterialModule } from '@shared/material/material.module';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [
    BackofficeDashboardComponent
  ],
  exports: [
    BackofficeDashboardComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CoreModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    SharedModule,
    MaterialModule,
    NgxMaskModule,
  ]
})
export class BackofficeDashboardModule { }
