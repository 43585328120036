import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { FileSizePipe } from '@shared/pipes/fileSize.pipe';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { SharedModule } from '@shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TreoMessageModule } from '@treo/components/message';
import { MaterialModule } from 'app/shared/material/material.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { CriarDocumentoModalComponent } from './criar-documento-modal/criar-documento-modal.component';
import { CriarDocumentoComponent } from './criar-documento/criar-documento.component';
import { EditarDocumentoModalComponent } from './editar-documento-modal/editar-documento-modal.component';
import { ExibirDocumentoComponent } from './exibir-documento/exibir-documento.component';
import { GerenciarDocumentoFornecedorComponent } from './gerenciar-documento-fornecedor/gerenciar-documento-fornecedor.component';
import { ListarDocumentosComponent } from './listar-documentos/listar-documentos.component';
import { ListarMeusDocumentosComponent } from './listar-meus-documentos/listar-meus-documentos.component';
import {ImageModule} from 'primeng/image';

@NgModule({
  declarations: [
    CriarDocumentoComponent,
    CriarDocumentoModalComponent,
    EditarDocumentoModalComponent,
    GerenciarDocumentoFornecedorComponent,
    ListarDocumentosComponent,
    ListarMeusDocumentosComponent,
    ExibirDocumentoComponent,
  ],
  exports: [
    CriarDocumentoComponent,
    CriarDocumentoModalComponent,
    EditarDocumentoModalComponent,
    GerenciarDocumentoFornecedorComponent,
    ListarMeusDocumentosComponent,
    ListarDocumentosComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    TreoMessageModule,
    SatPopoverModule,
    NgxDatatableModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    NgxMaskModule.forRoot(),
    CalendarModule,
    TabViewModule,
    FileUploadModule,
    ConfirmDialogModule,
    DialogModule,
    ToastModule,
    ToolbarModule,
    DropdownModule,
    SkeletonModule,
    MaterialFileInputModule,
    AutoCompleteModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    InputMaskModule,
    TooltipModule,
    NgxExtendedPdfViewerModule,
    ImageModule,
  ],
  providers: [
    TruncatePipe,
    FileSizePipe,
    ConfirmationService,
    DialogService,
    DynamicDialogRef,
  ],
})
export class DocumentoModule { }
