import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BancosBrasileirosService } from '../../../shared/services/bancos-brasileiros.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'banco-selector',
  templateUrl: './banco-selector.component.html',
  styleUrls: ['./banco-selector.component.scss']
})
export class BancoSelectorComponent implements OnInit {
  bancos: any[];
  bancoSelecionado: any;
  @Input() bancoValue: string;
  @Output() bancoAlterado = new EventEmitter<any>();

  constructor(
    private _bancosBrasileirosService: BancosBrasileirosService,
    private _messageService: MessageService,
  ) { 
    this.bancos = this._bancosBrasileirosService.getBancos();
  }

  ngOnInit(): void {
    this.bancoValue = this.bancos.find(banco => this.normalizeValue(banco.value) === this.normalizeValue(this.bancoValue))?.value;
    this.bancoSelecionado = this.bancos.find(banco => banco.value === this.bancoValue);
  }
  normalizeValue(value: any): string {
    return value.toString().padStart(3, '0');
  }
  onChange(event){
    this.bancoSelecionado = this.bancos.find(banco => banco.value === event.value);
    if(event.value){
      this.bancoAlterado.emit(event.value);
    }
  }

}
