<div class="content-layout" [ngClass]="'fullwidth-standard-content-scroll'">
  <!-- Header -->
  <div class="header">
    <div class="left">
      <h1>Backoffice Dashboard</h1>
    </div>
    <div class="right">
      <!-- Action buttons -->
      <div class="flex items-center">
        <button class="xs:hidden" (click)="atualizarDados()" mat-stroked-button>
          <mat-icon class="icon-refresh-20" [ngClass]="{ rotating: atualizando }" [svgIcon]="'mat_outline:refresh'"> </mat-icon>
          <span class="ml-2">Atualizar</span>
        </button>
      </div>
    </div>
  </div>

  <!-- Main -->
  <div class="main">
    <div class="flex flex-col flex-auto w-full">
      <div class="flex flex-wrap w-full">
        <div class="flex flex-auto">
          <div class="w-full md:w-1/4 min-w-25 h-50 p-4 bg-transparent">
            <div class="flex flex-col flex-auto p-6 pb-4 bg-card shadow-md rounded overflow-hidden">
              <div class="flex items-center justify-between">
                <div class="flex flex-col">
                  <div class="font-bold text-md text-secondary uppercase tracking-wider">Clientes</div>
                  <div class="text-sm text-hint font-medium">Quantidade de Cliente</div>
                </div>
                <!-- <div class="-mr-2">
                        <button class="h-8 min-h-8 px-2"
                                mat-button
                                [matMenuTriggerFor]="purchasesMenu">
                            <span class="font-medium text-sm text-hint">30 days</span>
                        </button>
                        <mat-menu #purchasesMenu="matMenu">
                            <button mat-menu-item>30 days</button>
                            <button mat-menu-item>3 months</button>
                            <button mat-menu-item>9 months</button>
                        </mat-menu>
                    </div> -->
              </div>
              <div class="flex items-center h-20 mt-auto">
                <div class="mt-auto mb-2 font-semibold text-5xl tracking-tighter leading-none">
                  {{ data?.clientes[0].total | formatarNumero }}
                </div>
                <div class="flex flex-col flex-auto h-full ml-6">
                  <!-- <apx-chart class="flex-auto w-full h-full"
                                   [chart]="purchasesOptions.chart"
                                   [colors]="purchasesOptions.colors"
                                   [series]="purchasesOptions.series"
                                   [stroke]="purchasesOptions.stroke"
                                   [tooltip]="purchasesOptions.tooltip"
                                   [xaxis]="purchasesOptions.xaxis"
                                   [yaxis]="purchasesOptions.yaxis"></apx-chart> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-auto">
          <div class="w-full md:w-1/4 min-w-25 h-50 p-4 bg-transparent">
            <div class="flex flex-col flex-auto p-6 pb-4 bg-card shadow-md rounded overflow-hidden">
              <div class="flex items-center justify-between">
                <div class="flex flex-col">
                  <div class="font-bold text-md text-secondary uppercase tracking-wider">Vendedores</div>
                  <div class="text-sm text-hint font-medium">Quantidade de Vendedores</div>
                </div>
              </div>
              <div class="flex items-center h-20 mt-auto">
                <div class="mt-auto mb-2 font-semibold text-5xl tracking-tighter leading-none">
                  {{ data?.vendedores[0].total | formatarNumero }}
                </div>

                <div class="flex flex-col flex-auto h-full ml-6">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-auto">
          <div class="w-full md:w-1/4 min-w-25 h-50 p-4 bg-transparent">
            <div class="flex flex-col flex-auto p-6 pb-4 bg-card shadow-md rounded overflow-hidden">
              <div class="flex items-center justify-between">
                <div class="flex flex-col">
                  <div class="font-bold text-md text-secondary uppercase tracking-wider">Fornecedores</div>
                  <div class="text-sm text-hint font-medium">Quantidade de Fornecedores</div>
                </div>
                <!-- <div class="-mr-2">
                        <button class="h-8 min-h-8 px-2"
                                mat-button
                                [matMenuTriggerFor]="purchasesMenu">
                            <span class="font-medium text-sm text-hint">30 days</span>
                        </button>
                        <mat-menu #purchasesMenu="matMenu">
                            <button mat-menu-item>30 days</button>
                            <button mat-menu-item>3 months</button>
                            <button mat-menu-item>9 months</button>
                        </mat-menu>
                    </div> -->
              </div>
              <div class="flex items-center h-20 mt-auto">
                <div class="mt-auto mb-2 font-semibold text-5xl tracking-tighter leading-none">
                  {{ data?.fornecedores[0].total | formatarNumero }}
                </div>

                <div class="flex flex-col flex-auto h-full ml-6">
                  <!-- <apx-chart class="flex-auto w-full h-full"
                                   [chart]="purchasesOptions.chart"
                                   [colors]="purchasesOptions.colors"
                                   [series]="purchasesOptions.series"
                                   [stroke]="purchasesOptions.stroke"
                                   [tooltip]="purchasesOptions.tooltip"
                                   [xaxis]="purchasesOptions.xaxis"
                                   [yaxis]="purchasesOptions.yaxis"></apx-chart> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-auto">
          <div class="w-full md:w-1/4 min-w-25 h-50 p-4 bg-transparent">
            <div class="flex flex-col flex-auto p-6 pb-4 bg-card shadow-md rounded overflow-hidden">
              <div class="flex items-center justify-between">
                <div class="flex flex-col">
                  <div class="font-bold text-md text-secondary uppercase tracking-wider">SPEs</div>
                  <div class="text-sm text-hint font-medium">Quantidade de SPEs</div>
                </div>
        
              </div>
              <div class="flex items-center h-20 mt-auto">
                <div class="mt-auto mb-2 font-semibold text-5xl tracking-tighter leading-none">
                  {{ data?.spes[0].total | formatarNumero }}
                </div>

                <div class="flex flex-col flex-auto h-full ml-6">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
