import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[sePermitido]'
})
export class SePermitidoDirective {
  private _condicao: any;
  private _array: any[];

  @Input() set sePermitido(condicao: any) {
    this._condicao = condicao;
    this.verificarCondicao();
  }

  @Input() set emArray(array: any[]) {
    this._array = array;
    this.verificarCondicao();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  private verificarCondicao() {
    
    if (this._array && this._array.includes(this._condicao)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
