import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import Utils from '@shared/helpers/utils';
import Fornecedor from '@shared/models/fornecedor.interface';
import { BancosBrasileirosService } from '@shared/services/bancos-brasileiros.service';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { GeolocationIpService } from '@shared/services/geolocation-ip.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'form-atualizar-dados-do-fornecedor',
  templateUrl: './form-atualizar-dados-do-fornecedor.component.html',
  styleUrls: ['./form-atualizar-dados-do-fornecedor.component.scss']
})
export class FormAtualizarDadosDoFornecedorComponent implements OnInit, AfterViewInit {
  dadosFornecedorFormGroup: FormGroup;
  geolocationaIp: any;
  loading: boolean = false;
  tiposConta = [
    { nome: 'Conta Corrente', valor: 0 },
    { nome: 'Conta Poupança', valor: 1 },
    { nome: 'Conta FGTS', valor: 2 },
    { nome: 'Conta Salário', valor: 3 }
  ];
  bancos: any[];
  @Input('fornecedorId') fornecedorId: string;
  @Input('cnpj') cnpj: string;
  @Input('obrigatorioEnvioDadosBancariosFornecedor') obrigatorioEnvioDadosBancariosFornecedor: boolean = false;

  @Output('fornecedorAtualizado') emitter = new EventEmitter<Fornecedor>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() messageERROR = new EventEmitter<string>();

  constructor(
    private _formBuilder: FormBuilder,
    private _fornecedorService: FornecedorService,
    private _messageService: MessageService,
    private _geolocationIpService: GeolocationIpService,
    private _bancosBrasileirosService: BancosBrasileirosService,
  ) { 
    this.createForm();
    this.bancos = this._bancosBrasileirosService.getBancos();
  }
  ngAfterViewInit() {
    this.createForm();
    this.formReady.emit(this.dadosFornecedorFormGroup);
  }
  ngOnInit(): void {
  }
  createForm() {
    this.dadosFornecedorFormGroup = this._formBuilder.group({
      apresentacaoAoCliente: [''],
      email: [null, [Validators.required, Validators.email]],
      website: [null],
      telefoneComercial: [null, [Validators.required,Validators.minLength(8)]],
      telefoneAdministrativo: [null],
      dadosBancarios: this._formBuilder.array([
        this._formBuilder.group({
          banco: [null, this.obrigatorioEnvioDadosBancariosFornecedor ? [Validators.required, Validators.minLength(2)] : []],
          conta: [null, this.obrigatorioEnvioDadosBancariosFornecedor ? [Validators.required, Validators.minLength(2)] : []],
          agencia: [null, this.obrigatorioEnvioDadosBancariosFornecedor ? [Validators.required, Validators.minLength(2)] : []],
          tipo: [null, this.obrigatorioEnvioDadosBancariosFornecedor ? Validators.required : Validators.nullValidator],
          nomeAgencia: [null],
        })
      ])
    });
  }
  salvarDadosDoFornecedor(): void {
    this.messageERROR.emit(null);
    this.dadosFornecedorFormGroup.markAllAsTouched();
    if (this.dadosFornecedorFormGroup.invalid) {
      const errorMessages = Utils.getErrorMessages(this.dadosFornecedorFormGroup);
      if (errorMessages) {
        console.error(errorMessages);
        this.messageERROR.emit(errorMessages);
        return;
      }
      return;
    }
    this.dadosFornecedorFormGroup.disable();
    this._geolocationIpService.getGeolocationData(window.location.href).subscribe((res: any) => {
      this.geolocationaIp = res;
    });
    
    let fornecedor: Fornecedor = {
      id: this.fornecedorId,
      rawCNPJ: Utils.manterSomenteNumeros(this.cnpj),
      origemCadastro: {
      url: window.location.href,
      device: navigator.userAgent,
      browser: Utils.getBrowserName(),
      geolocation: this.geolocationaIp,
      },
      ...this.dadosFornecedorFormGroup.getRawValue(),
    };
    // Verificar se todos os campos em dadosBancarios são null
    if (fornecedor.dadosBancarios && Object.values(fornecedor.dadosBancarios  [0]).every(value => value === null)) {
      delete fornecedor.dadosBancarios;
    }
    const fornecedorData = Object.keys(fornecedor).reduce((acc, key) => {
      if (fornecedor[key] !== null) {
        acc[key] = fornecedor[key];
      }
      return acc;
    }, {});
    this._fornecedorService.atualizarFornecedorProspect(fornecedorData).subscribe({
      next: (res: any) => {
        this.dadosFornecedorFormGroup.enable();
        this.emitter.emit(res as Fornecedor);
      },
      error: (err) => {
        this.dadosFornecedorFormGroup.enable();
        this.messageERROR.emit(`Houve um erro,${err.error.message || err.error.error || err.error}`);
        this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: err.error.message });
      }
    });
  }
  
  campoTemErro(campo: string, erro: string) {
    if (!this.dadosFornecedorFormGroup) return;
    const formArray = this.dadosFornecedorFormGroup.get('dadosBancarios') as FormArray;
    const controle = formArray.at(0).get(campo);
    return controle && controle.touched && controle.hasError(erro);
  }
  
  campoTemValidator(campo: string, validator: ValidatorFn) {
    const formArray = this.dadosFornecedorFormGroup.get('dadosBancarios') as FormArray;
    const controle = formArray.at(0).get(campo);
    return controle && controle.touched && controle.hasValidator(validator);
  }

  campoOpcional() {
    return this.obrigatorioEnvioDadosBancariosFornecedor ? '' : '(Opcional)';
  }

  // Função para adicionar hífen antes do último dígito, se necessário
  validarEAdicionarHifen(conta: string): string {
    // Adiciona a condição para aplicar somente se a conta tiver mais de 1 dígito
    if (conta.length > 1 && /\d$/.test(conta) && !/-\d$/.test(conta)) {
      conta = conta.slice(0, conta.length - 1) + '-' + conta.slice(conta.length - 1);
    }
    return conta;
  } 

  // Função chamada quando o usuário sai do campo de entrada
  onContaBlur(): void {
    // Acessa o primeiro grupo de dados bancários no FormArray
    const dadosBancariosGroup = this.dadosFornecedorFormGroup.get('dadosBancarios') as FormArray;
  
    // Supondo que você quer validar e adicionar hífen na conta do primeiro conjunto de dados bancários
    const conta = dadosBancariosGroup.at(0).get('conta').value;
  
    // Chama a função de validação e adição de hífen
    const contaAtualizada = this.validarEAdicionarHifen(conta);
  
    // Atualiza o valor da conta no FormGroup
    dadosBancariosGroup.at(0).get('conta').setValue(contaAtualizada);
  }
}