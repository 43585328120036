import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { MaterialModule } from '@shared/material/material.module';
import { SharedModule } from '@shared/shared.module';
import { TreoCardModule } from '@treo/components/card';
import { TreoMessageModule } from '@treo/components/message';
import { DocumentoModule } from 'app/modules/documentos/documento.module';
import { NgxMaskModule } from 'ngx-mask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { StepsModule } from 'primeng/steps';
import { AutocompleteVincularVendedorFornecedorComponent } from './autocomplete-vincular-vendedor-fornecedor/autocomplete-vincular-vendedor-fornecedor.component';
import { FormAdicionarCategoriasDoFornecedorComponent } from './formularios/form-adicionar-categorias-do-fornecedor/form-adicionar-categorias-do-fornecedor.component';
import { FormAtualizarDadosDoFornecedorComponent } from './formularios/form-atualizar-dados-do-fornecedor/form-atualizar-dados-do-fornecedor.component';
import { FormBuscarVendedorEhVincularNoFornecedorComponent } from './formularios/form-buscar-vendedor-eh-vincular-no-fornecedor/form-buscar-vendedor-eh-vincular-no-fornecedor.component';
import { FormConsultarEhPersistirFornecedorNoClienteComponent } from './formularios/form-consultar-eh-persistir-fornecedor-no-cliente/form-consultar-eh-persistir-fornecedor-no-cliente.component';
import { FormCriarAtualizarEnderecoFornecedorComponent } from './formularios/form-criar-atualizar-endereco-fornecedor/form-criar-atualizar-endereco-fornecedor.component';
import { FormCriarVendedorEhVincularNoFornecedorComponent } from './formularios/form-criar-vendedor-eh-vincular-no-fornecedor/form-criar-vendedor-eh-vincular-no-fornecedor.component';
import { FormDocumentacaoFornecedorComponent } from './formularios/form-documentacao-fornecedor/form-documentacao-fornecedor.component';
import { MessageInputRequiredComponent } from './message-input-required/message-input-required.component';
import { ModeloDocumentoAlertComponent } from './modelo-documento-alert/modelo-documento-alert.component';
import { ValidarCnpjComponent } from './validar-cnpj/validar-cnpj.component';
import { BancoSelectorComponent } from './banco-selector/banco-selector.component';


@NgModule({
  declarations: [
    ValidarCnpjComponent,
    MessageInputRequiredComponent,
    ModeloDocumentoAlertComponent,
    AutocompleteVincularVendedorFornecedorComponent,

    FormCriarAtualizarEnderecoFornecedorComponent,
    FormAdicionarCategoriasDoFornecedorComponent,
    FormBuscarVendedorEhVincularNoFornecedorComponent,
    FormCriarVendedorEhVincularNoFornecedorComponent,
    FormAtualizarDadosDoFornecedorComponent,
    FormConsultarEhPersistirFornecedorNoClienteComponent,
    FormDocumentacaoFornecedorComponent,
    BancoSelectorComponent,
  ],
  exports: [
    ValidarCnpjComponent,
    MessageInputRequiredComponent,
    ModeloDocumentoAlertComponent,
    AutocompleteVincularVendedorFornecedorComponent,

    FormCriarAtualizarEnderecoFornecedorComponent,
    FormAdicionarCategoriasDoFornecedorComponent,
    FormBuscarVendedorEhVincularNoFornecedorComponent,
    FormCriarVendedorEhVincularNoFornecedorComponent,
    FormAtualizarDadosDoFornecedorComponent,
    FormConsultarEhPersistirFornecedorNoClienteComponent,
    FormDocumentacaoFornecedorComponent,
    BancoSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    TreoMessageModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    TreoCardModule,
    TreoMessageModule,
    SharedModule,
    StepsModule,
    MatStepperModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    DocumentoModule,
    ButtonModule,
    NgxViacepModule,
    AutoCompleteModule,
    MultiSelectModule,
    InputMaskModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    
  ]
})
export class ComponentesModule { }
