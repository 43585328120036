import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '@shared/models/usuario.interface';
import { RoleService } from '@shared/services/role.service';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

@Component({
  selector: 'auth-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss']
})
export class RoleSelectorComponent implements OnDestroy {
  @Input() user: Usuario;
  @Input() moduleActive: string;
  private destroyed$ = new Subject<void>();

  constructor(
    private _cookieService: CookieService,
    private _router: Router,
    private _roleService: RoleService,
    private _messageService: MessageService
  ) { }


  selectThisRole(event: any): void {
    localStorage.setItem(`modulo`, this.moduleActive);
    this._roleService.changeRole(this.user.id, this.moduleActive)
      .subscribe(() => {
        let redirect = '/signed-in-redirect';
        if (event.value === 'backoffice') {
          redirect = `/${redirect}-backoffice`;
        } else if (event.value === 'comprador') {
          redirect = `/${redirect}-comprador`;
        } else if (event.value === 'vendedor') {
          redirect = `/${redirect}-vendedor`;
        }
        this._router.navigateByUrl(redirect);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}


