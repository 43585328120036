import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   *
   * @param {AuthService} _authService
   * @param {Router} _router
   */
  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the role status
   *
   * @param redirectUrl
   * @private
   */
  private _checkRole(role): Observable<boolean> {

    // Check the authentication status
    return this._authService.checkRolePermitted(role)
      .pipe(tap((permitted) => {
        
        // If the user is not authenticated...
        if (!permitted) {
          // Redirect to the sign-in page
          this._router.navigateByUrl(environment.routes.errors.error403);

          
          // Prevent the access
          return of(false);
        }
        
        // Allow the access
        return of(true);
      }));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const role = route.data?.role;
    const url = state.url;
    
    

    return this._checkRole(role);

  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = childRoute.data?.role;

    return this._checkRole(role);

  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    return this._checkRole(route.data.role || null);
  }
}
