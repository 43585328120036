import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import Utils from '@shared/helpers/utils';
import Fornecedor from '@shared/models/fornecedor.interface';
import { CategoriaFornecedorService } from '@shared/services/categoria-fornecedor.service';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';

@Component({
  selector: 'form-adicionar-categorias-do-fornecedor',
  templateUrl: './form-adicionar-categorias-do-fornecedor.component.html',
  styleUrls: ['./form-adicionar-categorias-do-fornecedor.component.scss']
})
export class FormAdicionarCategoriasDoFornecedorComponent implements OnInit, AfterViewInit {
  categoriasFormGroup: FormGroup;
  geolocationaIp: any;

  loading: boolean = false;
  suggestions: any[];
  categoriaFornecedor: any[];

  @Input('fornecedorId') fornecedorId: string;
  @Input('cnpj') cnpj: string;
  
  @Output('fornecedorAtualizado') emitter = new EventEmitter<Fornecedor>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() messageERROR = new EventEmitter<string>();

  constructor(
    private _formBuilder: FormBuilder,
    private _fornecedorService: FornecedorService,
    private _categoriaFornecedorService: CategoriaFornecedorService,
    private _messageService: MessageService,
  ) { 
    this._categoriaFornecedorService.getCategoriaFornecedorListProspect().subscribe((res: any) => {
      this.categoriaFornecedor = res;
    })
  }

  ngAfterViewInit() {
    this.formReady.emit(this.categoriasFormGroup);
  }

  ngOnInit(): void {
    this.categoriasFormGroup = this._formBuilder.group({
      categoriasFornecedor: [null, [Validators.required]],
    });
  }

  filterCategories(event) {
    this._categoriaFornecedorService.searchCategoriaFornecedorProspect(event.query)
      .pipe(take(1))
      .subscribe((res: any) => {
      this.suggestions = res;
    });
  }
 
  salvarCategoriasDoFornecedor(): void {
    this.messageERROR.emit(null);
    this.categoriasFormGroup.markAllAsTouched()
    if (this.categoriasFormGroup.invalid) {
      const errorMessages = Utils.getErrorMessages(this.categoriasFormGroup);
      if (errorMessages) {
        console.error(errorMessages);
        this.messageERROR.emit(errorMessages);
        return;
      }
      return;
    }
    this.categoriasFormGroup.disable();
    const fornecedor = {
      id: this.fornecedorId, 
      rawCNPJ: Utils.manterSomenteNumeros(this.cnpj),
      categoriasFornecedor: this.categoriasFormGroup.getRawValue().categoriasFornecedor,
    }
    this._fornecedorService.atualizarFornecedorProspect(fornecedor).subscribe({
      next: (res: any) => {
        this.categoriasFormGroup.enable();
        this.emitter.emit(res as Fornecedor);
      },
      error: (err) => {
        this.categoriasFormGroup.enable();
        console.error(err)
        this.messageERROR.emit(`Houve um erro,${err.error.message || err.error.error || err.error}`);
        this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: err.error.message });
      }
    })
  }
}
