import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "@environments";
import Utils from "@shared/helpers/utils";
import { Cliente } from "@shared/models/cliente.interface";
import { Documento } from "@shared/models/documento.interface";
import Fornecedor from "@shared/models/fornecedor.interface";
import PaginateResponse from "@shared/models/paginateResponse.interface";
import { RequisicaoDocumento } from "@shared/models/requisicaoDocumento";
import { TreoMessage } from "@shared/models/treoMessage.interface";
import { Usuario } from "@shared/models/usuario.interface";
import { CapitalizeFirstLetterPipe } from "@shared/pipes/capitalize-first-letter.pipe";
import { RequisicaoDocumentoService } from "@shared/services/requisicaoDocumento.service";
import { UserService } from "@shared/services/user.service";
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { Subject, takeUntil } from "rxjs";
import { CriarEditarRequisitacaoDocumentoComponent } from "../criar-editar-requisicao-documento/criar-editar-requisicao-documento.component";
import { ExibirAprovarRequisicaoComponent } from "../exibir-aprovar-requisicao/exibir-aprovar-requisicao.component";
import { ResponderRequisicaoComponent } from "../responder-requisicao/responder-requisicao.component";

@Component({
  selector: "app-listar-requisicao",
  templateUrl: "./listar-requisicao.component.html",
  styleUrls: ["./listar-requisicao.component.scss"],
})

export class ListarRequisicaoComponent implements OnInit {
  cliente: Cliente = null;
  requisicaoDocumentoDialog: boolean;
  loading: boolean = false;
  private destroyed$ = new Subject();
  totalRecords: number = 0;
  offset: number = 0;
  limit: number = 10;
  totalPages: number;
  page: number = 1;
  pagingCounter: number = 1;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
  somenteVisualizar: boolean = false;
  selectedDocumentosRequisicoes: any;
  loadingRequisicoes: any;
  requisicoes: RequisicaoDocumento[];
  requisicaoDocumento: RequisicaoDocumento;
  limitRequisicoes: any;
  totalRecordsRequisicoes: any;
  usuario: Usuario;
  environment = environment;
  statuses = Utils.getStatusToRequisicaoDocumento();
  contexto: string;
  documentos: Documento[];
  mapaDeAcoes = {
    visualizar: ["backoffice", "comprador", "vendedor"],
    editar: ["backoffice", "comprador"],
    excluir: ["backoffice", "comprador"],
    criar: ["backoffice", "comprador"],
    responder: ["backoffice", "vendedor"],
    aprovar: ["backoffice", "comprador"]
  };
  blockedList: any[] = null;
  message: TreoMessage = {
    show: false,
    appearance: 'outline',
    showIcon: true,
  };

  @ViewChild("dtRequisicoes") dtRequisicoes: Table;
  @Input("fornecedor") fornecedor: Fornecedor;
  @Output() quantidadeDeRequisicoes = new EventEmitter<number>();


  constructor(
    private _confirmationService: ConfirmationService,
    private _userService: UserService,
    private _messageService: MessageService,
    private _requisicaoDocumentosService: RequisicaoDocumentoService,
    private _dialogService: DialogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.queryParams.subscribe((params) => {
      const idsString = params.blockedList as string;
      if (idsString && idsString.includes(",")) {
        this.blockedList = idsString.split(",");
      }
    });
    this.contexto = Utils.getContextoDaRota(this._router);
    
    this._userService
    .getUserData()
    .pipe(takeUntil(this.destroyed$))
    .subscribe((data: Usuario) => {
      this.usuario = data;
      this.cliente = this.usuario.clienteComprador;
    });
  }

  ngOnDestroy(): void {
    this._confirmationService.close();
  }
  ngOnInit() {
    this.loadRequisicoesDocumentos();
  }
  loadRequisicoesDocumentos(event?: LazyLoadEvent) {
    try {
      this.loadingRequisicoes = true;
      let opt = this.contexto;
      if(this.fornecedor) opt+="_fornecedor"
      switch (opt) {
        case "comprador":
          this._requisicaoDocumentosService
            .getRequisicoesFromClienteList(this.cliente.id, event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res: PaginateResponse) => {
                this.requisicoes = res.docs;
                this.totalRecordsRequisicoes = res.totalDocs;
                this.limitRequisicoes = res.limit;
                this.loadingRequisicoes = false;
                this.quantidadeDeRequisicoes.emit(this.totalRecordsRequisicoes.length);
              },
              (error) => {
                this.loadingRequisicoes = false;
                console.error(error);
                this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
              }
            );
          break;
        case "comprador_fornecedor":
          this._requisicaoDocumentosService
            .getDocumentListFromFornecedorDeUmCliente(this.fornecedor.id,this.cliente.id, event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res: PaginateResponse) => {
                this._messageService.add({ severity: "success", summary: "Carregado", detail: `${res.docs.length}` });
                this.requisicoes = res.docs;
                this.totalRecordsRequisicoes = res.totalDocs;
                this.limitRequisicoes = res.limit;
                this.loadingRequisicoes = false;
                this.quantidadeDeRequisicoes.emit(this.totalRecordsRequisicoes.length);
              },
              (error) => {
                this.loadingRequisicoes = false;
                console.error(error);
                this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
              }
            );
          break;
        
        case "vendedor":
          if (this.blockedList && this.blockedList.length > 0) {
            console.log(this.blockedList);
            this._requisicaoDocumentosService
              .getOnlyBySomeIDs(this.blockedList)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(
                (res: PaginateResponse) => {
                  this.requisicoes = res.docs;
                  this.totalRecordsRequisicoes = res.totalDocs;
                  this.limitRequisicoes = res.limit;
                  this.loadingRequisicoes = false;

                  const ids = this.requisicoes.map(e => (e.id || e._id));
                  if(this.requisicoes && this.requisicoes.length > 1){
                    this.message.content = `Existem ${this.requisicoes.length} requisições de documentos do cliente ${this.requisicoes[0].cliente.desc_emp} que não estão concluídas, enquanto estas pendências não forem resolvidas não será possível responder cotações deste cliente.`
                   }else{
                    this.message.content = `Existem uma requisições de documentos do cliente ${this.requisicoes[0].cliente.desc_emp} que não estão concluídas, enquanto estas pendências não forem resolvidas não será possível responder cotações deste cliente.`
                   }
                  this.message.type = 'warning';
                  this.message.show = true;
                  this.quantidadeDeRequisicoes.emit(this.totalRecordsRequisicoes.length);
                },
                (error) => {
                  this.loadingRequisicoes = false;
                  console.error(error);
                  this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
                }
              );
          } else {
            this._requisicaoDocumentosService
              .getDocumentListFromVendedor(this.usuario.id, event)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(
                (res: PaginateResponse) => {
                  this.requisicoes = res.docs;
                  this.totalRecordsRequisicoes = res.totalDocs;
                  this.limitRequisicoes = res.limit;
                  this.loadingRequisicoes = false;
                  this.quantidadeDeRequisicoes.emit(this.totalRecordsRequisicoes.length);
                },
                (error) => {
                  this.loadingRequisicoes = false;
                  console.error(error);
                  this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
                }
              );
          }
          break;

        case "backoffice":
          this._requisicaoDocumentosService
            .getRequisicaoDocumentoAllPaginated(event)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res: PaginateResponse) => {
                this.requisicoes = res.docs;
                this.totalRecordsRequisicoes = res.totalDocs;
                this.limitRequisicoes = res.limit;
                this.loadingRequisicoes = false;
                this.quantidadeDeRequisicoes.emit(this.totalRecordsRequisicoes.length);
              },
              (error) => {
                this.loadingRequisicoes = false;
                console.error(error);
                this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
              }
            );
        default:
          this.loadingRequisicoes = false;
          break;
      }
    } catch (error) {
      this.loadingRequisicoes = false;
      console.error(error);
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }
  requisitarNovo() {
    try {
      if (!this.mapaDeAcoes.criar.includes(this.contexto)) {
        this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` });
        return;
      }
      this.requisicaoDocumento = null;
      this.somenteVisualizar = false;
      this.requisicaoDocumentoDialog = true;
      const ref = this._dialogService.open(CriarEditarRequisitacaoDocumentoComponent, {
        data: {
          create: true
        },
        maximizable: true,
        closable: true,
        closeOnEscape: true,
        width: "60%",
        height: "90vh",
        header: "Requisiçāo Novo Documento"
      });
      ref.onClose.subscribe((documentos: any) => {
        // this.loadRequisicoesDocumentos();
        // this.dtRequisicoes.reset()
      });
    } catch (error) {
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }
  editarRequisicao(requisicaoDocumento: RequisicaoDocumento) {
    try {
      if (!this.mapaDeAcoes.editar.includes(this.contexto)) {
        this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` });
        return;
      }
      this.somenteVisualizar = false;
      this.requisicaoDocumento = Utils.convertIds({ ...requisicaoDocumento });
      this.requisicaoDocumentoDialog = true;
      const ref = this._dialogService.open(CriarEditarRequisitacaoDocumentoComponent, {
        data: {
          requisicaoDocumento: this.requisicaoDocumento,
        },
        maximizable: true,
        closable: true,
        closeOnEscape: true,
        width: "60%",
        height: "90vh",
        header: `Editar Requisiçāo: ${this.requisicaoDocumento.tipo?.nome} #${this.requisicaoDocumento.id || requisicaoDocumento._id}`
      });
      ref.onClose.subscribe((documentos: any) => {
        // this.dtRequisicoes.reset()
        // this.loadRequisicoesDocumentos();

      });
    } catch (error) {
      console.error(error);
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }
  exibirRequisicao(requisicaoDocumento: RequisicaoDocumento) {
    try {
      if (!this.mapaDeAcoes.visualizar.includes(this.contexto)) {
        this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` });
        return;
      }
      this.requisicaoDocumento = null;
      this.requisicaoDocumento = Utils.convertIds({ ...requisicaoDocumento });
      const ref = this._dialogService.open(ExibirAprovarRequisicaoComponent, {
        data: {
          requisicaoDocumento: this.requisicaoDocumento,
          contexto: this.contexto,
          mapaDeAcoes: this.mapaDeAcoes.aprovar,
          hasAprovacao: true,
          usuario: this.usuario
        },
        maximizable: true,
        closable: true,
        closeOnEscape: true,
        width: "80%",
        height: "90vh",
        header: `Exibir Requisiçāo: ${new CapitalizeFirstLetterPipe().transform(requisicaoDocumento.status)} #${this.requisicaoDocumento.id || requisicaoDocumento._id}`
      });
      ref.onClose.subscribe((documentos: any) => {
      });
    } catch (error) {
      console.error(error);
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }
  excluirRequisicao(requisicao: RequisicaoDocumento) {
    try {
      if (!this.mapaDeAcoes.excluir.includes(this.contexto)) {
        this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` });
        return;
      }
      const confirmRef = this._confirmationService.confirm({
        key: "listar-requisicao",
        message: "Você realmente deseja deletar a requisiçāo documento " + requisicao.tipo.nome + "?",
        header: "Confirmar",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this._requisicaoDocumentosService.deleteDocument(requisicao._id || requisicao.id).subscribe(
            (res: any) => {
              this.requisicoes = this.requisicoes.filter((val) => val._id !== requisicao._id);
              this.requisicaoDocumento = {};
              this.dtRequisicoes.clear();
              this._messageService.add({ severity: "success", summary: "Ótimo", detail: "Requisicao de documento deletado" });
            },
            (error: any) => {
              console.error(error);
              this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
            }
          );
        },
        reject: ()=>{
          confirmRef.close()
        }
      });
    } catch (error) {
      console.error(error);
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }
  exibirEhAprovarEhReprovar(requisicaoDocumento: RequisicaoDocumento) {
    try {
      
      if (!this.mapaDeAcoes.visualizar.includes(this.contexto)) {
        this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` });
        return;
      }
      this.requisicaoDocumento = null;
      this.requisicaoDocumento = Utils.convertIds({ ...requisicaoDocumento });
      
      const data = {
        requisicaoDocumento: this.requisicaoDocumento,
        contexto: this.contexto,
        mapaDeAcoes: this.mapaDeAcoes.aprovar,
        hasAprovacao: true,
        usuario: this.usuario
      }
      console.log(data)
      
      const ref = this._dialogService.open(ExibirAprovarRequisicaoComponent, {
        data: data,
        maximizable: true,
        closable: true,
        closeOnEscape: true,
        width: "80%",
        height: "95vh",
        header: `Aprovaçāo da Requisiçāo: ${this.statuses.find((e) => e.value == requisicaoDocumento.status).label} #${this.requisicaoDocumento.id || requisicaoDocumento._id}`
      })
      ref.onClose.subscribe((requisicao: any) => {
        // this.loadRequisicoesDocumentos();
      });
    } catch (error) {
      console.error(error);
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }
  
  responderRequisicao(requisicao) {
    try{
      this.requisicaoDocumento = Utils.convertIds(requisicao);
      const ref = this._dialogService.open(ResponderRequisicaoComponent, {
        data: {
          requisicaoDocumento: this.requisicaoDocumento,
          fornecedor: this.fornecedor,
        },
        maximizable: true,
        closable: true,
        closeOnEscape: true,
        header: `Responder requisiçāo: ${requisicao.tipo.nome} #${requisicao.id ||requisicao._id}`,
        height: "95hv",
        width: "80%"
      });
      ref.onClose.subscribe((requisicao: any) => {
          // this.loadRequisicoesDocumentos();
      });
    } catch (error) {
      console.error(error);
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
    }
  }

  clear(table: Table, storageKey: string) {
    if (this.blockedList) {
      this.blockedList = null;
      this.message.content = null;
      this.message.show = false;
    }
    Utils.clearTableFilters(table, storageKey);
  }
}