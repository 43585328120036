import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class BancosBrasileirosService {

  constructor() { }

  normalizeValue(value: any): string {
    return value.toString().padStart(3, '0');
  }
  
  buscarPorCodigo(codigo: any): string {
    if(!codigo) return 'N/I';
    const normalizedCodigo = this.normalizeValue(codigo);
    const banco = this.getBancos().find(banco => banco.value === normalizedCodigo);
    return banco ? banco.label : `${codigo}`;
  }
  
  buscaPorCodigoComLabel(codigo: any): string {
    if(!codigo) return 'N/I';
    const normalizedCodigo = this.normalizeValue(codigo);
    const banco = this.getBancos().find(banco => banco.value === normalizedCodigo);
    return banco ? `${banco.value} - ${banco.label}` : `${codigo}`;
  }

  getBancos(): any {

    return [
      {
          "label": "BCO DO BRASIL S.A.",
          "value": "001"
      },
      {
          "label": "BCO DA AMAZONIA S.A.",
          "value": "003"
      },
      {
          "label": "BCO DO NORDESTE DO BRASIL S.A.",
          "value": "004"
      },
      {
          "label": "BNDES",
          "value": "007"
      },
      {
          "label": "CREDICOAMO",
          "value": "010"
      },
      {
          "label": "C.SUISSE HEDGING-GRIFFO CV S/A",
          "value": "011"
      },
      {
          "label": "BANCO INBURSA",
          "value": "012"
      },
      {
          "label": "STATE STREET BR S.A. BCO COMERCIAL",
          "value": "014"
      },
      {
          "label": "UBS BRASIL CCTVM S.A.",
          "value": "015"
      },
      {
          "label": "CCM DESP TRÂNS SC E RS",
          "value": "016"
      },
      {
          "label": "BNY MELLON BCO S.A.",
          "value": "017"
      },
      {
          "label": "BCO TRICURY S.A.",
          "value": "018"
      },
      {
          "label": "BCO BANESTES S.A.",
          "value": "021"
      },
      {
          "label": "BCO BANDEPE S.A.",
          "value": "024"
      },
      {
          "label": "BCO ALFA S.A.",
          "value": "025"
      },
      {
          "label": "BANCO ITAÚ CONSIGNADO S.A.",
          "value": "029"
      },
      {
          "label": "BCO SANTANDER (BRASIL) S.A.",
          "value": "033"
      },
      {
          "label": "BCO BBI S.A.",
          "value": "036"
      },
      {
          "label": "BCO DO EST. DO PA S.A.",
          "value": "037"
      },
      {
          "label": "BCO CARGILL S.A.",
          "value": "040"
      },
      {
          "label": "BCO DO ESTADO DO RS S.A.",
          "value": "041"
      },
      {
          "label": "BCO DO EST. DE SE S.A.",
          "value": "047"
      },
      {
          "label": "CONFIDENCE CC S.A.",
          "value": "060"
      },
      {
          "label": "HIPERCARD BM S.A.",
          "value": "062"
      },
      {
          "label": "BANCO BRADESCARD",
          "value": "063"
      },
      {
          "label": "GOLDMAN SACHS DO BRASIL BM S.A",
          "value": "064"
      },
      {
          "label": "BCO ANDBANK S.A.",
          "value": "065"
      },
      {
          "label": "BCO MORGAN STANLEY S.A.",
          "value": "066"
      },
      {
          "label": "BCO CREFISA S.A.",
          "value": "069"
      },
      {
          "label": "BRB - BCO DE BRASILIA S.A.",
          "value": "070"
      },
      {
          "label": "BCO. J.SAFRA S.A.",
          "value": "074"
      },
      {
          "label": "BANCO ABN AMRO CLEARING S.A.",
          "value": "075"
      },
      {
          "label": "BCO KDB BRASIL S.A.",
          "value": "076"
      },
      {
          "label": "BANCO INTER",
          "value": "077"
      },
      {
          "label": "HAITONG BI DO BRASIL S.A.",
          "value": "078"
      },
      {
          "label": "PICPAY BANK - BANCO MÚLTIPLO S.A",
          "value": "079"
      },
      {
          "label": "B&T CC LTDA.",
          "value": "080"
      },
      {
          "label": "BANCOSEGURO S.A.",
          "value": "081"
      },
      {
          "label": "BANCO TOPÁZIO S.A.",
          "value": "082"
      },
      {
          "label": "BCO DA CHINA BRASIL S.A.",
          "value": "083"
      },
      {
          "label": "SISPRIME DO BRASIL - COOP",
          "value": "084"
      },
      {
          "label": "COOPCENTRAL AILOS",
          "value": "085"
      },
      {
          "label": "BANCO RANDON S.A.",
          "value": "088"
      },
      {
          "label": "CREDISAN CC",
          "value": "089"
      },
      {
          "label": "CCCM UNICRED CENTRAL RS",
          "value": "091"
      },
      {
          "label": "BRK S.A. CFI",
          "value": "092"
      },
      {
          "label": "POLOCRED SCMEPP LTDA.",
          "value": "093"
      },
      {
          "label": "BANCO FINAXIS",
          "value": "094"
      },
      {
          "label": "TRAVELEX BANCO DE CÂMBIO S.A.",
          "value": "095"
      },
      {
          "label": "BCO B3 S.A.",
          "value": "096"
      },
      {
          "label": "CREDISIS - CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
          "value": "097"
      },
      {
          "label": "CREDIALIANÇA CCR",
          "value": "098"
      },
      {
          "label": "UNIPRIME COOPCENTRAL LTDA.",
          "value": "099"
      },
      {
          "label": "PLANNER CV S.A.",
          "value": "100"
      },
      {
          "label": "RENASCENCA DTVM LTDA",
          "value": "101"
      },
      {
          "label": "XP INVESTIMENTOS CCTVM S/A",
          "value": "102"
      },
      {
          "label": "CAIXA ECONOMICA FEDERAL",
          "value": "104"
      },
      {
          "label": "LECCA CFI S.A.",
          "value": "105"
      },
      {
          "label": "BCO BOCOM BBM S.A.",
          "value": "107"
      },
      {
          "label": "PORTOCRED S.A. - CFI",
          "value": "108"
      },
      {
          "label": "OLIVEIRA TRUST DTVM S.A.",
          "value": "111"
      },
      {
          "label": "NEON CTVM S.A.",
          "value": "113"
      },
      {
          "label": "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
          "value": "114"
      },
      {
          "label": "ADVANCED CC LTDA",
          "value": "117"
      },
      {
          "label": "BCO WESTERN UNION",
          "value": "119"
      },
      {
          "label": "BCO RODOBENS S.A.",
          "value": "120"
      },
      {
          "label": "BCO AGIBANK S.A.",
          "value": "121"
      },
      {
          "label": "BCO BRADESCO BERJ S.A.",
          "value": "122"
      },
      {
          "label": "BCO WOORI BANK DO BRASIL S.A.",
          "value": "124"
      },
      {
          "label": "BANCO GENIAL",
          "value": "125"
      },
      {
          "label": "BR PARTNERS BI",
          "value": "126"
      },
      {
          "label": "CODEPE CVC S.A.",
          "value": "127"
      },
      {
          "label": "BRAZA BANK S.A. BCO DE CÂMBIO",
          "value": "128"
      },
      {
          "label": "UBS BRASIL BI S.A.",
          "value": "129"
      },
      {
          "label": "CARUANA SCFI",
          "value": "130"
      },
      {
          "label": "TULLETT PREBON BRASIL CVC LTDA",
          "value": "131"
      },
      {
          "label": "ICBC DO BRASIL BM S.A.",
          "value": "132"
      },
      {
          "label": "CRESOL CONFEDERAÇÃO",
          "value": "133"
      },
      {
          "label": "BGC LIQUIDEZ DTVM LTDA",
          "value": "134"
      },
      {
          "label": "CONF NAC COOP CENTRAIS UNICRED",
          "value": "136"
      },
      {
          "label": "GET MONEY CC LTDA",
          "value": "138"
      },
      {
          "label": "INTESA SANPAOLO BRASIL S.A. BM",
          "value": "139"
      },
      {
          "label": "NU INVEST CORRETORA DE VALORES S.A.",
          "value": "140"
      },
      {
          "label": "MASTER BI S.A.",
          "value": "141"
      },
      {
          "label": "BROKER BRASIL CC LTDA.",
          "value": "142"
      },
      {
          "label": "TREVISO CC S.A.",
          "value": "143"
      },
      {
          "label": "EBURY BCO DE CÂMBIO S.A.",
          "value": "144"
      },
      {
          "label": "LEVYCAM CCV LTDA",
          "value": "145"
      },
      {
          "label": "GUITTA CC LTDA",
          "value": "146"
      },
      {
          "label": "FACTA S.A. CFI",
          "value": "149"
      },
      {
          "label": "ICAP DO BRASIL CTVM LTDA.",
          "value": "157"
      },
      {
          "label": "CASA CREDITO S.A. SCM",
          "value": "159"
      },
      {
          "label": "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO",
          "value": "163"
      },
      {
          "label": "BRL TRUST DTVM SA",
          "value": "173"
      },
      {
          "label": "PEFISA S.A. - C.F.I.",
          "value": "174"
      },
      {
          "label": "GUIDE",
          "value": "177"
      },
      {
          "label": "CM CAPITAL MARKETS CCTVM LTDA",
          "value": "180"
      },
      {
          "label": "SOCRED SA - SCMEPP",
          "value": "183"
      },
      {
          "label": "BCO ITAÚ BBA S.A.",
          "value": "184"
      },
      {
          "label": "ATIVA S.A. INVESTIMENTOS CCTVM",
          "value": "188"
      },
      {
          "label": "HS FINANCEIRA",
          "value": "189"
      },
      {
          "label": "SERVICOOP",
          "value": "190"
      },
      {
          "label": "NOVA FUTURA CTVM LTDA.",
          "value": "191"
      },
      {
          "label": "UNIDA DTVM LTDA",
          "value": "194"
      },
      {
          "label": "VALOR SCD S.A.",
          "value": "195"
      },
      {
          "label": "FAIR CC S.A.",
          "value": "196"
      },
      {
          "label": "STONE IP S.A.",
          "value": "197"
      },
      {
          "label": "BANCO BTG PACTUAL S.A.",
          "value": "208"
      },
      {
          "label": "BANCO ORIGINAL",
          "value": "212"
      },
      {
          "label": "BCO ARBI S.A.",
          "value": "213"
      },
      {
          "label": "BANCO JOHN DEERE S.A.",
          "value": "217"
      },
      {
          "label": "BCO BS2 S.A.",
          "value": "218"
      },
      {
          "label": "BCO CRÉDIT AGRICOLE BR S.A.",
          "value": "222"
      },
      {
          "label": "BCO FIBRA S.A.",
          "value": "224"
      },
      {
          "label": "BANCO CIFRA",
          "value": "233"
      },
      {
          "label": "BCO BRADESCO S.A.",
          "value": "237"
      },
      {
          "label": "BCO CLASSICO S.A.",
          "value": "241"
      },
      {
          "label": "BANCO MASTER",
          "value": "243"
      },
      {
          "label": "BCO ABC BRASIL S.A.",
          "value": "246"
      },
      {
          "label": "BANCO INVESTCRED UNIBANCO S.A.",
          "value": "249"
      },
      {
          "label": "BANCO BMG CONSIGNADO S.A.",
          "value": "250"
      },
      {
          "label": "BEXS CC S.A.",
          "value": "253"
      },
      {
          "label": "PARANA BCO S.A.",
          "value": "254"
      },
      {
          "label": "MONEYCORP BCO DE CÂMBIO S.A.",
          "value": "259"
      },
      {
          "label": "NU PAGAMENTOS - IP",
          "value": "260"
      },
      {
          "label": "BCO FATOR S.A.",
          "value": "265"
      },
      {
          "label": "BCO CEDULA S.A.",
          "value": "266"
      },
      {
          "label": "BARI CIA HIPOTECÁRIA",
          "value": "268"
      },
      {
          "label": "BCO HSBC S.A.",
          "value": "269"
      },
      {
          "label": "SAGITUR CC - EM LIQUIDAÇÃO EXTRAJUDICIAL",
          "value": "270"
      },
      {
          "label": "IB CCTVM S.A.",
          "value": "271"
      },
      {
          "label": "AGK CC S.A.",
          "value": "272"
      },
      {
          "label": "CCR DE SÃO MIGUEL DO OESTE",
          "value": "273"
      },
      {
          "label": "BMP SCMEPP LTDA",
          "value": "274"
      },
      {
          "label": "BCO SENFF S.A.",
          "value": "276"
      },
      {
          "label": "GENIAL INVESTIMENTOS CVM S.A.",
          "value": "278"
      },
      {
          "label": "COOP DE PRIMAVERA DO LESTE",
          "value": "279"
      },
      {
          "label": "WILL FINANCEIRA S.A.CFI",
          "value": "280"
      },
      {
          "label": "CCR COOPAVEL",
          "value": "281"
      },
      {
          "label": "RB INVESTIMENTOS DTVM LTDA.",
          "value": "283"
      },
      {
          "label": "FRENTE CC S.A.",
          "value": "285"
      },
      {
          "label": "UNIPRIME OURO - COOP DE OURO",
          "value": "286"
      },
      {
          "label": "CAROL DTVM LTDA.",
          "value": "288"
      },
      {
          "label": "EFX CC LTDA.",
          "value": "289"
      },
      {
          "label": "PAGSEGURO INTERNET IP S.A.",
          "value": "290"
      },
      {
          "label": "GALAPAGOS DTVM S.A.",
          "value": "292"
      },
      {
          "label": "LASTRO RDV DTVM LTDA",
          "value": "293"
      },
      {
          "label": "OZ CORRETORA DE CÂMBIO S.A.",
          "value": "296"
      },
      {
          "label": "VIPS CC LTDA.",
          "value": "298"
      },
      {
          "label": "BCO AFINZ S.A. - BM",
          "value": "299"
      },
      {
          "label": "BCO LA NACION ARGENTINA",
          "value": "300"
      },
      {
          "label": "DOCK IP S.A.",
          "value": "301"
      },
      {
          "label": "FOURTRADE COR. DE CAMBIO LTDA",
          "value": "305"
      },
      {
          "label": "PORTOPAR DTVM LTDA",
          "value": "306"
      },
      {
          "label": "TERRA INVESTIMENTOS DTVM",
          "value": "307"
      },
      {
          "label": "CAMBIONET CC LTDA",
          "value": "309"
      },
      {
          "label": "VORTX DTVM LTDA.",
          "value": "310"
      },
      {
          "label": "DOURADA CORRETORA",
          "value": "311"
      },
      {
          "label": "HSCM SCMEPP LTDA.",
          "value": "312"
      },
      {
          "label": "AMAZÔNIA CC LTDA.",
          "value": "313"
      },
      {
          "label": "PI DTVM S.A.",
          "value": "315"
      },
      {
          "label": "BCO BMG S.A.",
          "value": "318"
      },
      {
          "label": "OM DTVM LTDA",
          "value": "319"
      },
      {
          "label": "BCO CCB BRASIL S.A.",
          "value": "320"
      },
      {
          "label": "CREFAZ SCMEPP SA",
          "value": "321"
      },
      {
          "label": "CCR DE ABELARDO LUZ",
          "value": "322"
      },
      {
          "label": "MERCADO PAGO IP LTDA.",
          "value": "323"
      },
      {
          "label": "CARTOS SCD S.A.",
          "value": "324"
      },
      {
          "label": "ÓRAMA DTVM S.A.",
          "value": "325"
      },
      {
          "label": "PARATI - CFI S.A.",
          "value": "326"
      },
      {
          "label": "CECM FABRIC CALÇADOS SAPIRANGA",
          "value": "328"
      },
      {
          "label": "QI SCD S.A.",
          "value": "329"
      },
      {
          "label": "BANCO BARI S.A.",
          "value": "330"
      },
      {
          "label": "OSLO CAPITAL DTVM SA",
          "value": "331"
      },
      {
          "label": "ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO",
          "value": "332"
      },
      {
          "label": "BANCO BESA S.A.",
          "value": "334"
      },
      {
          "label": "BANCO DIGIO",
          "value": "335"
      },
      {
          "label": "BCO C6 S.A.",
          "value": "336"
      },
      {
          "label": "SUPERDIGITAL I.P. S.A.",
          "value": "340"
      },
      {
          "label": "ITAÚ UNIBANCO S.A.",
          "value": "341"
      },
      {
          "label": "CREDITAS SCD",
          "value": "342"
      },
      {
          "label": "FFA SCMEPP LTDA.",
          "value": "343"
      },
      {
          "label": "BCO XP S.A.",
          "value": "348"
      },
      {
          "label": "AL5 S.A. CFI",
          "value": "349"
      },
      {
          "label": "COOP DE AGRICULTORES E AEROPORTUÁRIOS DO BRASIL",
          "value": "350"
      },
      {
          "label": "TORO CTVM S.A.",
          "value": "352"
      },
      {
          "label": "NECTON INVESTIMENTOS S.A CVM",
          "value": "354"
      },
      {
          "label": "ÓTIMO SCD S.A.",
          "value": "355"
      },
      {
          "label": "MIDWAY S.A. - SCFI",
          "value": "358"
      },
      {
          "label": "ZEMA CFI S/A",
          "value": "359"
      },
      {
          "label": "TRINUS CAPITAL DTVM",
          "value": "360"
      },
      {
          "label": "CIELO IP S.A.",
          "value": "362"
      },
      {
          "label": "SINGULARE CTVM S.A.",
          "value": "363"
      },
      {
          "label": "EFÍ S.A. - IP",
          "value": "364"
      },
      {
          "label": "SIMPAUL",
          "value": "365"
      },
      {
          "label": "BCO SOCIETE GENERALE BRASIL",
          "value": "366"
      },
      {
          "label": "VITREO DTVM S.A.",
          "value": "367"
      },
      {
          "label": "BCO CSF S.A.",
          "value": "368"
      },
      {
          "label": "BCO MIZUHO S.A.",
          "value": "370"
      },
      {
          "label": "WARREN CVMC LTDA",
          "value": "371"
      },
      {
          "label": "UP.P SEP S.A.",
          "value": "373"
      },
      {
          "label": "REALIZE CFI S.A.",
          "value": "374"
      },
      {
          "label": "BCO J.P. MORGAN S.A.",
          "value": "376"
      },
      {
          "label": "BMS SCD S.A.",
          "value": "377"
      },
      {
          "label": "BCO BRASILEIRO DE CRÉDITO S.A.",
          "value": "378"
      },
      {
          "label": "CECM COOPERFORTE",
          "value": "379"
      },
      {
          "label": "PICPAY",
          "value": "380"
      },
      {
          "label": "BCO MERCEDES-BENZ S.A.",
          "value": "381"
      },
      {
          "label": "FIDUCIA SCMEPP LTDA",
          "value": "382"
      },
      {
          "label": "EBANX IP LTDA.",
          "value": "383"
      },
      {
          "label": "GLOBAL SCM LTDA",
          "value": "384"
      },
      {
          "label": "CECM DOS TRAB.PORT. DA G.VITOR",
          "value": "385"
      },
      {
          "label": "NU FINANCEIRA S.A. CFI",
          "value": "386"
      },
      {
          "label": "BCO TOYOTA DO BRASIL S.A.",
          "value": "387"
      },
      {
          "label": "BCO MERCANTIL DO BRASIL S.A.",
          "value": "389"
      },
      {
          "label": "BCO GM S.A.",
          "value": "390"
      },
      {
          "label": "CCR DE IBIAM",
          "value": "391"
      },
      {
          "label": "BCO VOLKSWAGEN S.A",
          "value": "393"
      },
      {
          "label": "BCO BRADESCO FINANC. S.A.",
          "value": "394"
      },
      {
          "label": "F D GOLD DTVM LTDA",
          "value": "395"
      },
      {
          "label": "HUB IP S.A.",
          "value": "396"
      },
      {
          "label": "LISTO SCD S.A.",
          "value": "397"
      },
      {
          "label": "IDEAL CTVM S.A.",
          "value": "398"
      },
      {
          "label": "KIRTON BANK",
          "value": "399"
      },
      {
          "label": "COOP CREDITAG",
          "value": "400"
      },
      {
          "label": "IUGU IP S.A.",
          "value": "401"
      },
      {
          "label": "COBUCCIO S.A. SCFI",
          "value": "402"
      },
      {
          "label": "CORA SCFI",
          "value": "403"
      },
      {
          "label": "SUMUP SCD S.A.",
          "value": "404"
      },
      {
          "label": "ACCREDITO SCD S.A.",
          "value": "406"
      },
      {
          "label": "SEFER INVESTIMENTOS DTVM LTDA",
          "value": "407"
      },
      {
          "label": "BONUSPAGO SCD S.A.",
          "value": "408"
      },
      {
          "label": "PLANNER SOCIEDADE DE CRÉDITO DIRETO",
          "value": "410"
      },
      {
          "label": "VIA CERTA FINANCIADORA S.A. - CFI",
          "value": "411"
      },
      {
          "label": "SOCIAL BANK S/A",
          "value": "412"
      },
      {
          "label": "BCO BV S.A.",
          "value": "413"
      },
      {
          "label": "LEND SCD S.A.",
          "value": "414"
      },
      {
          "label": "LAMARA SCD S.A.",
          "value": "416"
      },
      {
          "label": "ZIPDIN SCD S.A.",
          "value": "418"
      },
      {
          "label": "NUMBRS SCD S.A.",
          "value": "419"
      },
      {
          "label": "CC LAR CREDI",
          "value": "421"
      },
      {
          "label": "BCO SAFRA S.A.",
          "value": "422"
      },
      {
          "label": "COLUNA S.A. DTVM",
          "value": "423"
      },
      {
          "label": "SOCINAL S.A. CFI",
          "value": "425"
      },
      {
          "label": "NEON FINANCEIRA - CFI S.A.",
          "value": "426"
      },
      {
          "label": "CRED-UFES",
          "value": "427"
      },
      {
          "label": "CREDSYSTEM SCD S.A.",
          "value": "428"
      },
      {
          "label": "CREDIARE CFI S.A.",
          "value": "429"
      },
      {
          "label": "CCR SEARA",
          "value": "430"
      },
      {
          "label": "BR-CAPITAL DTVM S.A.",
          "value": "433"
      },
      {
          "label": "DELCRED SCD S.A.",
          "value": "435"
      },
      {
          "label": "TRUSTEE DTVM LTDA.",
          "value": "438"
      },
      {
          "label": "ID CTVM",
          "value": "439"
      },
      {
          "label": "CREDIBRF COOP",
          "value": "440"
      },
      {
          "label": "MAGNETIS - DTVM",
          "value": "442"
      },
      {
          "label": "CREDIHOME SCD",
          "value": "443"
      },
      {
          "label": "TRINUS SCD S.A.",
          "value": "444"
      },
      {
          "label": "PLANTAE CFI",
          "value": "445"
      },
      {
          "label": "MIRAE ASSET (BRASIL) CCTVM LTDA.",
          "value": "447"
      },
      {
          "label": "HEMERA DTVM LTDA.",
          "value": "448"
      },
      {
          "label": "DM",
          "value": "449"
      },
      {
          "label": "FITBANK IP",
          "value": "450"
      },
      {
          "label": "J17 - SCD S/A",
          "value": "451"
      },
      {
          "label": "CREDIFIT SCD S.A.",
          "value": "452"
      },
      {
          "label": "MÉRITO DTVM LTDA.",
          "value": "454"
      },
      {
          "label": "FÊNIX DTVM LTDA.",
          "value": "455"
      },
      {
          "label": "BCO MUFG BRASIL S.A.",
          "value": "456"
      },
      {
          "label": "UY3 SCD S/A",
          "value": "457"
      },
      {
          "label": "HEDGE INVESTMENTS DTVM LTDA.",
          "value": "458"
      },
      {
          "label": "CCM SERV. PÚBLICOS SP",
          "value": "459"
      },
      {
          "label": "UNAVANTI SCD S/A",
          "value": "460"
      },
      {
          "label": "ASAAS IP S.A.",
          "value": "461"
      },
      {
          "label": "STARK SCD S.A.",
          "value": "462"
      },
      {
          "label": "AZUMI DTVM",
          "value": "463"
      },
      {
          "label": "BCO SUMITOMO MITSUI BRASIL S.A.",
          "value": "464"
      },
      {
          "label": "CAPITAL CONSIG SCD S.A.",
          "value": "465"
      },
      {
          "label": "MASTER S/A CCTVM",
          "value": "467"
      },
      {
          "label": "PORTOSEG S.A. CFI",
          "value": "468"
      },
      {
          "label": "PICPAY INVEST",
          "value": "469"
      },
      {
          "label": "CDC SCD S.A.",
          "value": "470"
      },
      {
          "label": "CECM SERV PUBL PINHÃO",
          "value": "471"
      },
      {
          "label": "BCO CAIXA GERAL BRASIL S.A.",
          "value": "473"
      },
      {
          "label": "BCO YAMAHA MOTOR S.A.",
          "value": "475"
      },
      {
          "label": "CITIBANK N.A.",
          "value": "477"
      },
      {
          "label": "GAZINCRED S.A. SCFI",
          "value": "478"
      },
      {
          "label": "BCO ITAUBANK S.A.",
          "value": "479"
      },
      {
          "label": "SUPERLÓGICA SCD S.A.",
          "value": "481"
      },
      {
          "label": "SBCASH SCD",
          "value": "482"
      },
      {
          "label": "MAF DTVM SA",
          "value": "484"
      },
      {
          "label": "DEUTSCHE BANK S.A.BCO ALEMAO",
          "value": "487"
      },
      {
          "label": "JPMORGAN CHASE BANK",
          "value": "488"
      },
      {
          "label": "ING BANK N.V.",
          "value": "492"
      },
      {
          "label": "BCO LA PROVINCIA B AIRES BCE",
          "value": "495"
      },
      {
          "label": "BCO CREDIT SUISSE S.A.",
          "value": "505"
      },
      {
          "label": "RJI",
          "value": "506"
      },
      {
          "label": "SCFI EFÍ S.A.",
          "value": "507"
      },
      {
          "label": "AVENUE SECURITIES DTVM LTDA.",
          "value": "508"
      },
      {
          "label": "CELCOIN IP S.A.",
          "value": "509"
      },
      {
          "label": "FFCRED SCD S.A.",
          "value": "510"
      },
      {
          "label": "MAGNUM SCD",
          "value": "511"
      },
      {
          "label": "FINVEST DTVM",
          "value": "512"
      },
      {
          "label": "ATF SCD S.A.",
          "value": "513"
      },
      {
          "label": "EXIM CC LTDA.",
          "value": "514"
      },
      {
          "label": "QISTA S.A. CFI",
          "value": "516"
      },
      {
          "label": "MERCADO CRÉDITO SCFI S.A.",
          "value": "518"
      },
      {
          "label": "LIONS TRUST DTVM",
          "value": "519"
      },
      {
          "label": "SOMAPAY SCD S.A.",
          "value": "520"
      },
      {
          "label": "PEAK SEP S.A.",
          "value": "521"
      },
      {
          "label": "RED SCD S.A.",
          "value": "522"
      },
      {
          "label": "HR DIGITAL SCD",
          "value": "523"
      },
      {
          "label": "WNT CAPITAL DTVM",
          "value": "524"
      },
      {
          "label": "INTERCAM CC LTDA",
          "value": "525"
      },
      {
          "label": "MONETARIE SCD",
          "value": "526"
      },
      {
          "label": "ATICCA SCD S.A.",
          "value": "527"
      },
      {
          "label": "REAG DTVM S.A.",
          "value": "528"
      },
      {
          "label": "PINBANK IP",
          "value": "529"
      },
      {
          "label": "SER FINANCE SCD S.A.",
          "value": "530"
      },
      {
          "label": "BMP SCD S.A.",
          "value": "531"
      },
      {
          "label": "EAGLE SCD S.A.",
          "value": "532"
      },
      {
          "label": "SRM BANK",
          "value": "533"
      },
      {
          "label": "EWALLY IP S.A.",
          "value": "534"
      },
      {
          "label": "OPEA SCD",
          "value": "535"
      },
      {
          "label": "NEON PAGAMENTOS S.A. IP",
          "value": "536"
      },
      {
          "label": "MICROCASH SCMEPP LTDA.",
          "value": "537"
      },
      {
          "label": "SUDACRED SCD S.A.",
          "value": "538"
      },
      {
          "label": "SANTINVEST S.A. - CFI",
          "value": "539"
      },
      {
          "label": "HBI SCD",
          "value": "540"
      },
      {
          "label": "FDO GARANTIDOR CRÉDITOS",
          "value": "541"
      },
      {
          "label": "CLOUDWALK IP LTDA",
          "value": "542"
      },
      {
          "label": "COOPCRECE",
          "value": "543"
      },
      {
          "label": "MULTICRED SCD S.A.",
          "value": "544"
      },
      {
          "label": "SENSO CCVM S.A.",
          "value": "545"
      },
      {
          "label": "U4C INSTITUIÇÃO DE PAGAMENTO S.A.",
          "value": "546"
      },
      {
          "label": "BNK DIGITAL SCD S.A.",
          "value": "547"
      },
      {
          "label": "RPW S.A. SCFI",
          "value": "548"
      },
      {
          "label": "INTRA DTVM",
          "value": "549"
      },
      {
          "label": "BEETELLER",
          "value": "550"
      },
      {
          "label": "VERT DTVM LTDA.",
          "value": "551"
      },
      {
          "label": "UZZIPAY IP S.A.",
          "value": "552"
      },
      {
          "label": "PERCAPITAL SCD S.A.",
          "value": "553"
      },
      {
          "label": "STONEX BANCO DE CÂMBIO S.A.",
          "value": "554"
      },
      {
          "label": "PAN CFI",
          "value": "555"
      },
      {
          "label": "PROSEFTUR",
          "value": "556"
      },
      {
          "label": "PAGPRIME IP",
          "value": "557"
      },
      {
          "label": "QI DTVM LTDA.",
          "value": "558"
      },
      {
          "label": "KANASTRA SCD",
          "value": "559"
      },
      {
          "label": "MAG IP LTDA.",
          "value": "560"
      },
      {
          "label": "PAY4FUN IP S.A.",
          "value": "561"
      },
      {
          "label": "AZIMUT BRASIL DTVM LTDA",
          "value": "562"
      },
      {
          "label": "PROTEGE PAY CASH IP S.A.",
          "value": "563"
      },
      {
          "label": "ÁGORA CTVM S.A.",
          "value": "565"
      },
      {
          "label": "FLAGSHIP IP LTDA",
          "value": "566"
      },
      {
          "label": "MERCANTIL FINANCEIRA",
          "value": "567"
      },
      {
          "label": "BRCONDOS SCD S.A.",
          "value": "568"
      },
      {
          "label": "CONTA PRONTA IP",
          "value": "569"
      },
      {
          "label": "ALL IN CRED SCD S.A.",
          "value": "572"
      },
      {
          "label": "DGBK CREDIT S.A. - SOCIEDADE DE CRÉDITO DIRETO.",
          "value": "575"
      },
      {
          "label": "MERCADO BITCOIN IP LTDA",
          "value": "576"
      },
      {
          "label": "AF DESENVOLVE SP S.A.",
          "value": "577"
      },
      {
          "label": "SICRES",
          "value": "578"
      },
      {
          "label": "QUADRA SCD",
          "value": "579"
      },
      {
          "label": "CCCPOUPINV SUL E SUDESTE - CENTRAL SUL/SUDESTE",
          "value": "580"
      },
      {
          "label": "CENTRAL NORDESTE",
          "value": "581"
      },
      {
          "label": "CCC POUP INV DE MS GO DF E TO",
          "value": "582"
      },
      {
          "label": "CCC POUP INV DO CENTRO NORTE DO BRASIL",
          "value": "583"
      },
      {
          "label": "CCC POUP E INV DOS ESTADOS DO PR SP E RJ",
          "value": "584"
      },
      {
          "label": "SETHI SCD SA",
          "value": "585"
      },
      {
          "label": "Z1 IP LTDA.",
          "value": "586"
      },
      {
          "label": "FIDD DTVM LTDA.",
          "value": "587"
      },
      {
          "label": "PROVER PROMOCAO DE VENDAS IP LTDA.",
          "value": "588"
      },
      {
          "label": "G5 SCD SA",
          "value": "589"
      },
      {
          "label": "REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS IP S.A.",
          "value": "590"
      },
      {
          "label": "BANVOX DTVM",
          "value": "591"
      },
      {
          "label": "MAPS IP LTDA.",
          "value": "592"
      },
      {
          "label": "TRANSFEERA IP S.A.",
          "value": "593"
      },
      {
          "label": "EMBRACRED S/A SCD",
          "value": "594"
      },
      {
          "label": "ZOOP MEIOS DE PAGAMENTO",
          "value": "595"
      },
      {
          "label": "ISSUER IP LTDA.",
          "value": "597"
      },
      {
          "label": "AGORACRED S/A SCFI",
          "value": "599"
      },
      {
          "label": "BCO LUSO BRASILEIRO S.A.",
          "value": "600"
      },
      {
          "label": "BCO INDUSTRIAL DO BRASIL S.A.",
          "value": "604"
      },
      {
          "label": "BCO VR S.A.",
          "value": "610"
      },
      {
          "label": "BCO PAULISTA S.A.",
          "value": "611"
      },
      {
          "label": "BCO GUANABARA S.A.",
          "value": "612"
      },
      {
          "label": "OMNI BANCO S.A.",
          "value": "613"
      },
      {
          "label": "NITRO SCD S.A.",
          "value": "614"
      },
      {
          "label": "TRIO IP LTDA.",
          "value": "619"
      },
      {
          "label": "REVOLUT SCD S.A.",
          "value": "620"
      },
      {
          "label": "BANCO PAN",
          "value": "623"
      },
      {
          "label": "BCO C6 CONSIG",
          "value": "626"
      },
      {
          "label": "BCO LETSBANK S.A.",
          "value": "630"
      },
      {
          "label": "BCO RENDIMENTO S.A.",
          "value": "633"
      },
      {
          "label": "BCO TRIANGULO S.A.",
          "value": "634"
      },
      {
          "label": "BCO SOFISA S.A.",
          "value": "637"
      },
      {
          "label": "BCO PINE S.A.",
          "value": "643"
      },
      {
          "label": "321 SCD S.A.",
          "value": "644"
      },
      {
          "label": "PAGARE IP S.A.",
          "value": "651"
      },
      {
          "label": "ITAÚ UNIBANCO HOLDING S.A.",
          "value": "652"
      },
      {
          "label": "BANCO VOITER",
          "value": "653"
      },
      {
          "label": "BCO DIGIMAIS S.A.",
          "value": "654"
      },
      {
          "label": "BCO VOTORANTIM S.A.",
          "value": "655"
      },
      {
          "label": "BCO DAYCOVAL S.A",
          "value": "707"
      },
      {
          "label": "OURIBANK S.A.",
          "value": "712"
      },
      {
          "label": "BANCO MASTER MÚLTIPLO",
          "value": "719"
      },
      {
          "label": "BCO RNX S.A.",
          "value": "720"
      },
      {
          "label": "BCO CETELEM S.A.",
          "value": "739"
      },
      {
          "label": "BCO RIBEIRAO PRETO S.A.",
          "value": "741"
      },
      {
          "label": "BANCO SEMEAR",
          "value": "743"
      },
      {
          "label": "BCO CITIBANK S.A.",
          "value": "745"
      },
      {
          "label": "BCO MODAL S.A.",
          "value": "746"
      },
      {
          "label": "BCO RABOBANK INTL BRASIL S.A.",
          "value": "747"
      },
      {
          "label": "BCO COOPERATIVO SICREDI S.A.",
          "value": "748"
      },
      {
          "label": "SCOTIABANK BRASIL",
          "value": "751"
      },
      {
          "label": "BCO BNP PARIBAS BRASIL S A",
          "value": "752"
      },
      {
          "label": "NOVO BCO CONTINENTAL S.A. - BM",
          "value": "753"
      },
      {
          "label": "BANCO SISTEMA",
          "value": "754"
      },
      {
          "label": "BOFA MERRILL LYNCH BM S.A.",
          "value": "755"
      },
      {
          "label": "BANCO SICOOB S.A.",
          "value": "756"
      },
      {
          "label": "BCO KEB HANA DO BRASIL S.A.",
          "value": "757"
      }
    ]
  }

}
