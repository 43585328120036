import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Cliente } from '@shared/models/cliente.interface'
import { DocumentoService } from '@shared/services/documento.service'
import { concatMap, forkJoin, from, of, switchMap, tap } from 'rxjs'

@Component({
  selector: 'form-documentacao-fornecedor',
  templateUrl: './form-documentacao-fornecedor.component.html',
  styleUrls: ['./form-documentacao-fornecedor.component.scss'],
})
export class FormDocumentacaoFornecedorComponent
  implements OnInit, AfterViewInit {
  documentoDialog = false
  tipoDocumento: any
  headerTitle: string
  documentoObrigatorioCampo = null
  documentosFormGroup: FormGroup
  modelosDocumentos: any[]
  documentosExistentes: any[]

  @Input('documentosObrigatoriosDoCliente')
  documentosObrigatoriosDoCliente: any[]
  @Input('fornecedorId') fornecedorId: string
  @Input('clienteId') clienteId: string

  @Output() documentosEnviadosComSucesso = new EventEmitter<boolean>()
  @Output() formReady = new EventEmitter<FormGroup>()
  @Output() messageERROR = new EventEmitter<string>()

  constructor(
    private _formBuilder: FormBuilder,
    private _documentosServices: DocumentoService
  ) { }

  ngOnInit(): void {
    this.documentosFormGroup = this._formBuilder.group({})
    this.documentosObrigatoriosDoCliente.forEach((documento) => {
      this.documentosFormGroup.addControl(
        documento.nomeCampoObrigatorio,
        this._formBuilder.control(null, Validators.required)
      )
    })
    
  }
  ngAfterViewInit() {
    this.formReady.emit(this.documentosFormGroup)
    if(this.fornecedorId && this.clienteId){
      this._documentosServices
      .listarDocumentosDoFornecedorProspect(this.fornecedorId, { limit: 1000 })
      .subscribe(
        (documentos) => {
          this.documentosExistentes = documentos.docs

          // Primeiro, fazemos o patchValue para todos os documentos existentes
          this.documentosExistentes.forEach((documentoExistente) => {
            const documentoObrigatorio =
              this.documentosObrigatoriosDoCliente.find(
                (tipo) => tipo.id === documentoExistente.tipo.id
              )
            if (documentoObrigatorio) {
              this.documentosFormGroup.controls[
                documentoObrigatorio.nomeCampoObrigatorio
              ].patchValue(documentoExistente.id)
            }
          })

          // Em seguida, criamos as requisições automáticas
          for (let documentoExistente of this.documentosExistentes) {
            const documentoObrigatorio =
              this.documentosObrigatoriosDoCliente.find(
                (tipo) => tipo.id === documentoExistente.tipo.id
              )
            if (documentoObrigatorio) {
              this._documentosServices
                .criarRequisicaoAutomaticaProspect(
                  (documentoExistente.tipo.id ||
                  documentoExistente.tipo._id ||
                  documentoExistente.tipo),
                  this.fornecedorId,
                  this.clienteId,
                  (documentoExistente.id || documentoExistente._id)
                )
                .subscribe(
                  (res) => {
                    
                  },
                  (error) => {
                    console.error(error)
                  }
                )
            }
          }

        },
        (error) => {
          console.error(error)
        }
      )
    }
  }
  createDocument(habilitado, nome, tipo, campo) {
    this.messageERROR.emit(null)
    try {
      this.documentoDialog = false
      setTimeout(() => { }, 1000)
      if (habilitado) {
        this.headerTitle = `Enviar Documento - ${nome}`
        this.tipoDocumento = tipo
        this.documentoObrigatorioCampo = campo
        this.documentoDialog = true
        this.modelosDocumentos = this.documentosObrigatoriosDoCliente
          .map((e) => e.modelosDocumentos)
          .flat()
          .filter((e) => e.documentoTipo.id == this.tipoDocumento)
      }
    } catch (error) {
      this.messageERROR.emit(error)
    }
  }
  createdAndEditedDocumento(documento) {
    
    this.documentosFormGroup.controls[
      this.documentoObrigatorioCampo
    ].patchValue(documento.id)
    if (this.documentosFormGroup.valid) {
      this.documentosEnviadosComSucesso.emit(true)
    }
    this.documentoDialog = false
  }
  documentosSalvos() {
    if (this.documentosFormGroup.valid) {
      this.documentosEnviadosComSucesso.emit(true)
    }
  }
  resetDialog() {
    this.documentoDialog = false
  }
}
