import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import Utils from '@shared/helpers/utils';
import { Cliente } from '@shared/models/cliente.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { ConviteService } from '@shared/services/convite.service';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { GeolocationIpService } from '@shared/services/geolocation-ip.service';
import { mustMatch } from '@shared/validators/mutMatch';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'form-criar-vendedor-eh-vincular-no-fornecedor',
  templateUrl: './form-criar-vendedor-eh-vincular-no-fornecedor.component.html',
  styleUrls: ['./form-criar-vendedor-eh-vincular-no-fornecedor.component.scss']
})
export class FormCriarVendedorEhVincularNoFornecedorComponent implements OnInit,AfterViewInit {
  cadastroContaVendedorForm: any;
  loading: boolean = false;

  @Input('cnpj') cnpj: string;
  @Input('email') email: string;

  @Output('vendedorCriado') emitter = new EventEmitter<Usuario>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() messageERROR = new EventEmitter<string>();
  
  geolocationaIp: any;


  constructor(
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _geolocationIpService: GeolocationIpService,
    private _conviteService: ConviteService,
  ) { }

  ngAfterViewInit() {
    this.formReady.emit(this.cadastroContaVendedorForm);
  }

  ngOnInit(): void {
    this.cadastroContaVendedorForm = this._formBuilder.group({
      name: new FormControl(null, Validators.required),
      telefone: new FormControl(null, Validators.required),
      c_password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      passwordConfirm: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    }, {
      validator: mustMatch('c_password', 'passwordConfirm')
    });
    
  }
  criarUsuarioEhVincularCNPJ(): void {
    this.messageERROR.emit(null);
    if (this.cadastroContaVendedorForm.invalid) {
      const errorMessages = Utils.getErrorMessages(this.cadastroContaVendedorForm);
      if (errorMessages) {
        console.error(errorMessages);
        this.messageERROR.emit(errorMessages);
        return;
      }
      return;
    }
    this.cadastroContaVendedorForm.disable();
    this._geolocationIpService.getGeolocationData(window.location.href).subscribe((res: any) => {
        this.geolocationaIp = res;
    });
    
    let vendedor = {
      name: this.cadastroContaVendedorForm.controls.name.value,
      email: this.email,
      telefone: this.cadastroContaVendedorForm.controls.telefone.value,
      cnpj: Utils.manterSomenteNumeros(this.cnpj),
      password: this.cadastroContaVendedorForm.controls.c_password.value,
      origemCadastro: {
        url: window.location.href,
        device: navigator.userAgent,
        browser: Utils.getBrowserName(),
        geolocation: this.geolocationaIp,
      },
    };

    
    this._conviteService.cadastrarProspectVendedorVinculaFornecedorProspect(vendedor).subscribe({
      next: (res: any) => {
        this.cadastroContaVendedorForm.enable();
        this.emitter.emit(res as Usuario);
      },
      error: (err) => {
        this.cadastroContaVendedorForm.enable();
        
        this.messageERROR.emit(`Houve um erro,${err.error.message || err.error.error || err.error}`);
        this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: err.error.message });
      }
    })
  }
}
