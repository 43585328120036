<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="left">
      <h1>{{ 'Criar Documento' }}</h1>
    </div>
    <div class="right">
      <!-- Action buttons -->
    </div>
  </div>
  <div class="max-w">
    <form [formGroup]="form" autocomplete="disabled" class="flex flex-col mt-2 px-8 pt-2 rounded overflow-hidden">
      <div class="flex flex-col gap-4">
        <div class="my-1" *ngIf="acimaModeloDocumentoMensagemAlert">
          <ng-template *ngTemplateOutlet="acimaModeloDocumentoMensagemAlert"></ng-template>
        </div>
        <div class="flex gap-4"> 
          <div class="treo-mat-dense w-full md:w-1/2" *ngIf="form && formControl.tipo.value == null">
            <span class="mat-label">Tipo de Documento</span>
            <p-dropdown [disabled]="idTipoDeDocumentoPrefixado != null" [options]="documentoTipos" formControlName="tipo"
              optionLabel="nome" optionValue="id" [filter]="true" filterBy="nome" [virtualScroll]="true"
              [virtualScrollItemSize]="60" appendTo="body" placeholder="Selecione um tipo de documento"></p-dropdown>
            <small class="invalid-feedback"
              *ngIf="form && formControl.tipo.touched && formControl.tipo.hasError('required')">O campo é obrigatório.</small>
          </div>
          <div class="treo-mat-dense w-full md:w-1/2" *ngIf="form && formControl.fornecedor.value == null" >
            <span class="mat-label">Minha Empresa</span>
            <p-dropdown [disabled]="idFornecedorPrefixado != null" [options]="user.fornecedores"
              formControlName="fornecedor" optionLabel="nome" optionValue="id" [filter]="true" filterBy="nome"
              [virtualScroll]="true" [virtualScrollItemSize]="60" appendTo="body"
              placeholder="Selecione uma de suas empresas"></p-dropdown>
            <small class="invalid-feedback"
              *ngIf="form && formControl.fornecedor.touched && formControl.fornecedor.hasError('required')">O campo é obrigatório.</small>
          </div>
        </div>

        <div class="flex gap-4"> 
          <mat-form-field class="w-full md:w-1/2"> 
            <mat-label>Nome do Documento</mat-label> 
              <input matInput formControlName="nome"> <mat-error
              *ngIf="form && formControl.nome.hasError('required')">O campo é obrigatório.</mat-error> 
          </mat-form-field>
          <mat-form-field class="w-full md:w-1/2">
            <mat-label>Data de Emissāo do Documento</mat-label> <input matInput formControlName="dataEmissao" type="date"> <mat-error
              *ngIf="form && formControl.dataEmissao.hasError('required')">O campo é obrigatório.</mat-error>
          </mat-form-field> 
          <mat-form-field class="w-full md:w-1/2" *ngIf="formControl.numero"> 
            <mat-label>Número de Identificaçāo</mat-label> 
            <input  matInput formControlName="numero"> 
            <mat-error *ngIf="form && formControl.numero.hasError('required')">O campo é obrigatório.</mat-error> 
          </mat-form-field> 
          <mat-form-field class="w-full md:w-1/2" *ngIf="formControl.dataValidade"> 
            <mat-label>Data de Validade do Documento</mat-label> 
            <input matInput formControlName="dataValidade" type="date"> 
            <mat-error *ngIf="form && formControl.dataValidade.hasError('required')">O campo é obrigatório.</mat-error>
          </mat-form-field> </div>
        <!-- <div
          class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <mat-form-field *ngIf="formControl.inscricaoMunicipal">
            <mat-label>Inscricao Municipal</mat-label>
            <input matInput formControlName="inscricaoMunicipal">
            <mat-error *ngIf="form && formControl.inscricaoMunicipal.hasError('required')">O campo é
              obrigatório.</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="formControl.codigoDeVerificacao">
            <mat-label>Código de Verificaçāo</mat-label>
            <input matInput formControlName="codigoDeVerificacao">
            <mat-error *ngIf="form && formControl.codigoDeVerificacao.hasError('required')">O campo é
              obrigatório.</mat-error>
          </mat-form-field>
        </div> -->

        <div class="flex flex-col gap-0">
          <mat-form-field appearance="fill" class="treo-mat-dense flex-auto">
            <mat-label>Arquivo</mat-label>

            <!-- Verifica se o arquivo existe -->
            <ng-container *ngIf="documento && documento.file; else fileInput">
              <input type="text" matInput formControlName="file" [disabled]="true">
              <button mat-icon-button (click)="viewFile()">
                <mat-icon>open_in_new</mat-icon>
              </button>
              <button mat-icon-button (click)="removeFile()">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>


            <!-- Exibe o input para escolher o arquivo -->
            <ng-template #fileInput>
              <ngx-mat-file-input #file placeholder="Envie o arquivo" [accept]="'.doc, .docx, .xls, .xlsm, .pdf, .png, .jpg, .jpeg'"
                formControlName="file" (change)="handleFileChange($event)"></ngx-mat-file-input>
              <button mat-icon-button matSuffix *ngIf="file.value" (click)="file.clear()">
                <mat-icon>clear</mat-icon>
              </button>
              <mat-icon matSuffix>folder</mat-icon>
            </ng-template>
            <mat-error *ngIf="form && form.get('file').hasError('required')">O arquivo é obrigatório.</mat-error>
          </mat-form-field>
          <small id="file-help" class="block">Formatos aceitos: .doc, .docx, .xls, .xlsm, .pdf, .png, .jpg, .jpeg</small>
        </div>
        <div class="my-1" *ngIf="abaixoModeloDocumentoMensagemAlert">
          <ng-template *ngTemplateOutlet="abaixoModeloDocumentoMensagemAlert"></ng-template>
        </div>
        <mat-form-field class="treo-mat-dense flex-auto">
          <mat-label>Observacao do documento</mat-label>
          <textarea matInput formControlName="observacao"></textarea>
          <mat-error>O campo é obrigatório.</mat-error>
        </mat-form-field>
      </div>
      <div
        class="flex items-center justify-end border-t mt-5 px-1 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <treo-message [dismissed]="!message.show" [appearance]="message.appearance" [showIcon]="message.showIcon"
          [type]="message.type">
          {{message.content}}
        </treo-message>
        <button pButton pRipple label="Cancelar" *ngIf="inRoute" icon="pi pi-times" class="p-button-text"
          (click)="cancelar()"></button>
        <button mat-raised-button type="submit" (click)="salvar()" [class.spinner]="loading" [disabled]="loading"
          color="primary" class="px-6 ml-3">
          Salvar
        </button>
      </div>
    </form>
  </div>