<div *ngFor="let modelo of modelosDocumentos">
  <treo-message *ngIf="show(modelo)" class="w-full mb-1 mt-0" type="warning" [appearance]="'outline'" [showIcon]="true">
    <span treoMessageTitle>{{ modelo.nome }} - {{ modelo.documentoTipo.nome }}</span>
    <div class="flex items-center justify-between" (click)="selectModeloExibirModeloDocumentoAnotherPage(modelo)">
      <span class="flex-1" treoMessageContent>
        {{ modelo.mensagemTexto || mensagemTextoPadrao }}
      </span>
      <div class="flex self-align-start">
        <button mat-icon-button class="mx-2" (click)="selectModeloExibirModeloDocumentoAnotherPage(modelo)">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
    </div>
  </treo-message>
</div>