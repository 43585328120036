// import { AuthMockApi } from 'app/data/mock/auth';
import { CalendarMockApi } from "app/dataAPI/mock/apps/calendar";
import { ContactsMockApi } from "app/dataAPI/mock/apps/contacts";
import { ECommerceInventoryMockApi } from "app/dataAPI/mock/apps/ecommerce/inventory";
import { MailboxMockApi } from "app/dataAPI/mock/apps/mailbox";
import { TasksMockApi } from "app/dataAPI/mock/apps/tasks";
import { MessagesMockApi } from "app/dataAPI/mock/common/messages";
import { NotificationsMockApi } from "app/dataAPI/mock/common/notifications";
import { SearchMockApi } from "app/dataAPI/mock/common/search";
import { ShortcutsMockApi } from "app/dataAPI/mock/common/shortcuts";
import { UserMockApi } from "app/dataAPI/mock/common/user";
import { NavigationMockApi } from "app/dataAPI/mock/navigation/index";
import { HelpCenterMockApi } from "app/dataAPI/mock/pages/help-center";

export const mockDataServices = [
  CalendarMockApi,
  ContactsMockApi,
  ECommerceInventoryMockApi,
  HelpCenterMockApi,
  MailboxMockApi,
  MessagesMockApi,
  NavigationMockApi,
  NotificationsMockApi,
  SearchMockApi,
  ShortcutsMockApi,
  TasksMockApi,
  UserMockApi,
];
