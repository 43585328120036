<div class="max-w">
  <h6>Gerenciar Documentos</h6>
  <div class="card">
    <p-table #dt [value]="documentos" (onLazyLoad)="load($event)" dataKey="id" [lazy]="true" [loading]="loading"
      [(rows)]="limit" [totalRecords]="totalRecords" [paginator]="true" 
      [globalFilterFields]="['nome','numero','fornecedor.nome','status']" stateStorage="session" stateKey="gerenciar-documentos-do-fornecedor"
      [tableStyle]="{'min-width': '75rem'}" [rowsPerPageOptions]="[10,25,50,100]" [(selection)]="selectedDocumentos"
      [rowHover]="true" dataKey="id" currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}"
      [showCurrentPageReport]="true">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-space-between" style="justify-content: space-between!important;">
          <div class="flex justify-content-space-between">
            <button pButton pRipple label="Adicionar" icon="pi pi-plus" class="mr-2 p-button-raised"
              (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="mr-2 p-button-raised p-button-danger"
              (click)="deleteSelected()" [disabled]="!selectedDocumentos || !selectedDocumentos.length"></button>
            <button pButton pRipple label="Limpar" class="p-button-outlined" icon="pi pi-filter-slash"
              (click)="clear(dt,'backoffice-listar-documentos')"></button>
          </div>
          <div class="flex justify-content-space-between">
            <span class="p-input-icon-left mr-2 line-height-normal">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Buscar..." />
            </span>
            <p-button class="p-button-raised" icon="pi pi-refresh" (click)="dt.reset();"></p-button>
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="nome" style="min-width:15rem">Nome <p-sortIcon field="nome"></p-sortIcon>
          </th>
          <th pSortableColumn="numero">Número <p-sortIcon field="numero"></p-sortIcon>
          </th>
          <!-- <th>Arquivo</th> -->
          <th pSortableColumn="tipo" style="min-width:10rem">Tipo <p-sortIcon field="tipo"></p-sortIcon>
          </th>
          <!-- <th pSortableColumn="fornecedor">Fornecedor <p-sortIcon field="fornecedor"></p-sortIcon></th> -->
          <th pSortableColumn="enviadoPor" style="min-width:10rem">Enviado Por <p-sortIcon field="enviadoPor">
            </p-sortIcon>
          </th>
          <th pSortableColumn="createdAt" style="min-width:10rem">Criado em<p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th style="min-width:8rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-documento>
        <tr>
          <td>
            <p-tableCheckbox [value]="documento"></p-tableCheckbox>
          </td>
          <td>{{documento.nome}}</td>
          <td>{{documento.numero }}</td>
          <td>{{documento.tipo.nome || "-"}}</td>
          <td>{{ documento?.enviadoPor?.email || "-"}}</td>
          <td>{{ documento.createdAt | date: "dd/MM/yyyy" : 'UTC' }}</td>
          <td>
            <button pButton pRipple icon="pi pi-eye" class="p-button-raised p-button-rounded p-button-secondary mr-2"
              (click)="show(documento)"></button>
            <button pButton pRipple icon="pi pi-pencil" class="p-button-raised p-button-rounded p-button-primary mr-2"
              (click)="edit(documento)"></button>
            <button pButton pRipple icon="pi pi-trash" class="p-button-raised p-button-rounded p-button-danger"
              (click)="delete(documento)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="7">
            Nenhum documento encontrado
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                No total, existem {{documentos ? documentos.length : 0 }} documentos.
            </div>
        </ng-template> -->
    </p-table>
  </div>
</div>


<app-criar-documento-modal  
  [openDialog]="documentoDialog" 
  [fornecedor]="fornecedor" 
  [fornecedorId]="fornecedor.id" 
  [documento]="documento"
  (closeDialog)="resetDialog($event)" 
  (newDocumentoEvent)="createdAndEditedDocumento($event)">
</app-criar-documento-modal>

<app-editar-documento-modal 
  *ngIf="editDocumentoDialog" 
  [openDialog]="editDocumentoDialog" 
  [fornecedor]="fornecedor"
  [documento]="documento" 
  (closeDialog)="resetDialog($event)" 
  (editDocumentoEvent)="createdAndEditedDocumento($event)"
  [somenteVisualizar]="somenteVisualizar">
</app-editar-documento-modal>

<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>