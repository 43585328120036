import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

if ( environment.production )
{
    enableProdMode();
    Sentry.init({
      dsn: environment.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ["localhost", environment.apiEndpoint],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
      ],
      environment: (environment.production == true ? "production" : "development"),
      // Performance Monitoring
      tracesSampleRate: 0.4, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

  
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
