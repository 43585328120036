import { FormGroup, ValidatorFn } from "@angular/forms";

export function mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (!control || !matchingControl) {
      // Retornar nulo se os controles não estiverem definidos para evitar falhas
      return null;
    }

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // Retornar se já houver um erro diferente de "mustMatch"
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
      control.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
      control.setErrors(null);
    }

    return null; // Retornar null para indicar validação bem-sucedida
  };
}
