import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocationIpService {
  constructor(private http: HttpClient) { }

  getGeolocationData(urlLocal) {
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Referer', urlLocal);
    return this.http.get<any>('https://geolocation-db.com/json', { headers });
  }
}
