import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { environment } from '@environments';
import ModeloDocumento from '@shared/models/modeloDocumento.interface';

@Component({
  selector: 'modelo-documento-mensagem-alert',
  templateUrl: './modelo-documento-alert.component.html',
  styleUrls: ['./modelo-documento-alert.component.scss']
})
export class ModeloDocumentoAlertComponent implements OnInit {
  @Input('modelosDocumentos') modelosDocumentos: ModeloDocumento[] = null;
  @Input('mensagemPosicao') mensagemPosicao: string;
  mensagemTextoPadrao = "O documento solicitado não se aplica a sua empresa? Clique no botão do card e siga as instruções de preenchimento do documento para aprovação do comprador."

  modeloDocumentoSelecionado: EventEmitter<ModeloDocumento> = new EventEmitter<ModeloDocumento>();

  constructor() { }

  ngOnInit(): void {
  }

  selectModeloExibirModeloDocumentoAnotherPage(modeloDocumento: ModeloDocumento){
    this.modeloDocumentoSelecionado.emit(modeloDocumento);
    let url = `${environment.routes.vendedor.modeloDocumento.exibir}/${modeloDocumento.id}`;
    window.open(url, '_blank');
  }
  show(modelo){
    return (modelo.mensagemPosicao == this.mensagemPosicao);
  }
}
