import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Documento } from '@shared/models/documento.interface';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { FileSizePipe } from '@shared/pipes/fileSize.pipe';
import { DocumentoService } from '@shared/services/documento.service';
import { DocumentoTiposService } from '@shared/services/documentoTipos.service';
import { FileService } from '@shared/services/file.service';
import { UserService } from '@shared/services/user.service';
import { format, parseISO } from 'date-fns';
import { FileInputComponent } from 'ngx-material-file-input';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-exibir-documento',
  templateUrl: './exibir-documento.component.html',
  styleUrls: ['./exibir-documento.component.scss']
})
export class ExibirDocumentoComponent implements OnInit {
  form: FormGroup;
  documento: Documento = null;
  @ViewChild("file") file: FileInputComponent;
  urlFile: SafeResourceUrl;
  mimeType: string;
  pdfCarregado = false;
  documentoTipos: any[] = [];
  fileName: string;
  private destroyed$ = new Subject();
  user: Usuario;
  selectedFile;
  uploadedFiles = [];
  loading: boolean = false;
  inRoute: boolean = false;
  role: string;
  message: TreoMessage  = {show: false};

  constructor(
    private _formBuilder: FormBuilder,
    private _documentoService: DocumentoService,
    private _documentoTipoService: DocumentoTiposService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _fileSizePipe: FileSizePipe,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _config: DynamicDialogConfig,
    private _fileService: FileService,
    ) {
        this.role = this._activatedRoute.snapshot.data.role;
        if(this._config.data && (this._config.data.create == true || this._config.data.documento)) {
          this.documento = this._config.data.documento;
        }else{
          this.inRoute = true; // senao tem dado do dialoag é rota
          this.documento = this._activatedRoute.snapshot.data.documento;
          this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
          this.user = data;
        })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.unsubscribe();
  }
  ngOnInit(): void {

    // this.loadDocumentoTipos()
    this.buidForm()
    if (this.documento && this.documento.file && this.documento.file.id) {
      this.mimeType = this.documento.file.mimetype;
      this.urlFile = this.getUrlFile(this.documento.file.id)
    }
  }
  getUrlFile(url) {
    return this._fileService.urlViewFile(url);
  }
  onShow(){
    this.buidForm();
  }
  buidForm(){
    
    const idTipo = this.documento.tipo?._id || this.documento.tipo?.id;
    this.documentoTipos = [{id: idTipo, nome: this.documento.tipo.nome}]

    this.form = this._formBuilder.group({
      numero: [{ value: this.documento?.numero, disabled: true }, Validators.required],
      dataEmissao: [this.documento && this.documento.dataEmissao ? format(parseISO(this.documento?.dataEmissao.toString().split("T")[0]), 'yyyy-MM-dd') : null , Validators.required],
      dataValidade: [this.documento && this.documento.dataValidade ? format(parseISO(this.documento?.dataValidade.toString().split("T")[0]), 'yyyy-MM-dd') : null, Validators.required],
      nome: [{ value: this.documento?.nome, disabled: true }, Validators.required], 
      tipo: [{ value: idTipo, disabled: true }, Validators.required],
      atualizadoPor: [{ value: (this.user?.id || this.user?._id), disabled: true }, Validators.required],
      observacao: [{ value: this.documento?.observacao, disabled: true }],
      // codigoDeVerificacao: [{ value: this.documento?.codigoDeVerificacao, disabled: true }],
      // inscricaoMunicipal: [{ value: this.documento?.inscricaoMunicipal, disabled: true }],
    });

  }
  viewFile() {
    const url = this._fileService.urlViewFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  upload() {
   return ;
  }

  loadDocumentoTipos() {
    try {
      this._documentoTipoService.list().pipe(takeUntil(this.destroyed$)).subscribe((data) => {
        this.documentoTipos = data.docs;
      })
    } catch (error) {
      this.message.type = 'error';
      this.message.content = `Houve um erro: ${error?.message}`;
      this.message.show = true;
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true})
    }
  }
  removeFile(file: string) {
    return ;
  }
  getSizeFormated(size: number) {
    return this._fileSizePipe.transform(size)
  }
  handleFileChange(event) {
    
  }
  erroImage(event){
    console.error(event);
    this.documento = null;
  }
  getFileName(file){
    if(file && file.mimetype && file.mimetype != ""){
      return `${file.filename}.${file.mimetype.split("/")[1]}`
    }else{
      return `${file.id}`
    }
  }
}
