import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '@shared/services/user.service';

import { environment } from 'environments/environment';

import { AuthService } from '@shared/services/auth.service';
import { Usuario } from '@shared/models/usuario.interface';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
  @Input()
  showAvatar: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _user: Usuario;
  userData: Usuario;
  private destroyed$ = new Subject();
  refreshing = true;
  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Router} _router
   * @param {UserService} _userService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
  ) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.showAvatar = true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  @Input()
  set user(value: Usuario) {

    // Save the user
    this._user = value;

    // Store the user in the service
    // this._userService.user = value;
  }

  get user(): Usuario {
    return this._user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.userData = data;
    })
    this._userService.loadUser().subscribe(() => this.refreshing = false);


  }

  /**
   * On destroy
   */
  // ngOnDestroy(): void
  // {
  //     // Unsubscribe from all subscriptions
  //     this._unsubscribeAll.next(null);
  //     this._unsubscribeAll.complete();
  // }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  // updateUserStatus(status): void
  // {
  //     // Update the user data
  //     this.user.status = status;

  //     // Update the user on the server
  //     this._userService.update(this.user);
  // }

  /**
   * Sign out
   */
  signOut() {
    return this._router.navigate([environment.routes.auth.logout]);
  }
  /**
   * redirect to profile
   */
  redirectToProfile(): void {
    this._router.navigate([this.userData.roleSelected, 'minhaconta']);
  }
  /**
   * save information on backend
   */
  submit(): void {

  }
  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
