import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectorRef, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Utils from '@shared/helpers/utils';
import { Cliente } from '@shared/models/cliente.interface';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { DocumentoService } from '@shared/services/documento.service';
import { FileService } from '@shared/services/file.service';
import { RequisicaoDocumentoService } from '@shared/services/requisicaoDocumento.service';
import { UserService } from '@shared/services/user.service';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RequisicaoDocumento } from '../../../shared/models/requisicaoDocumento';

@Injectable()
export class StepperIntl extends MatStepperIntl {
  // the default optional label text, if unspecified is "Optional"
  override optionalLabel = " ";
}

@Component({
  selector: 'exibir-aprovar-requisicao',
  templateUrl: './exibir-aprovar-requisicao.component.html',
  styleUrls: ['./exibir-aprovar-requisicao.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
    NgxExtendedPdfViewerService,
  ],
})

export class ExibirAprovarRequisicaoComponent implements OnInit, AfterViewInit {
  // @Input('requisicaoDocumento') requisicaoDocumento: RequisicaoDocumento;
  requisicaoDocumento: RequisicaoDocumento;
  urlFile: SafeResourceUrl;
  mimeType: string = null;
  pdfCarregado = false;
  @ViewChild('stepper') stepper: MatStepper;
  showWidgets = false;
  statuses = Utils.getStatusToRequisicaoDocumento();
  status: string;
  role: string;
  inRoute: boolean = false;
  hasImageError: boolean = false;
  message: TreoMessage = {
    show: false,
    appearance: 'outline',
    showIcon: true,
  };
  mapaDeAcoes;
  contexto;
  hasAprovacao: Boolean = false;
  formAprovacao: FormGroup;
  usuario: Usuario;
  options = [
    { label: 'Requisiçāo Aprovada', value: 'aprovada' }, 
    { label: 'Requisiçāo Reprovada', value: 'reprovada' }, 
    { label: 'Solicitar Alteraçāo e Novo Envio', value: 'pendente-de-retorno' }
  ];
  justificativaAprovacaoCompradorText = null;
  aprovarRequisicaoRequestRef: any;
  cliente: Cliente;
  
  constructor(
    private _matStepperIntl: MatStepperIntl,
    private _activatedRoute: ActivatedRoute,
    private _config: DynamicDialogConfig,
    private _requisicaoDocumentosService: RequisicaoDocumentoService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _formBuilder: FormBuilder,
    private _dynamicDialogRef: DynamicDialogRef,
    private _userService: UserService,
    private _fileService: FileService,
    private _sanitizer: DomSanitizer,
    private _cdr: ChangeDetectorRef,
    ) {
    // via modal
    if (this._config.data) {
      if (this._config.data.requisicaoDocumento) this.requisicaoDocumento = this._config.data.requisicaoDocumento;
      if (this._config.data.contexto) this.contexto = this._config.data.contexto;
      if (this._config.data.mapaDeAcoes) this.mapaDeAcoes = this._config.data.mapaDeAcoes;
      if (this._config.data.hasAprovacao) this.hasAprovacao = this._config.data.hasAprovacao;
    }
    this._userService.getUserData().subscribe((user:Usuario)=>{
      this.usuario = user;
      this.cliente = this.usuario.clienteComprador as Cliente;
  })      
    // via rota
    if (this._activatedRoute.snapshot.data.requisicaoDocumento) {
      this.inRoute = true;
      this.requisicaoDocumento = this._activatedRoute.snapshot.data.requisicaoDocumento;
    }
    if (this._activatedRoute.snapshot.data.role) this.role = this._activatedRoute.snapshot.data.role;

    this.status = this.statuses.find((e) => e.value == this.requisicaoDocumento.status).label;
    
    if (this.requisicaoDocumento.documento && this.requisicaoDocumento.documento.file && this.requisicaoDocumento.documento.file.id) {
      this.mimeType = this.requisicaoDocumento.documento.file.mimetype;
      this.urlFile = this.getUrlFile(this.requisicaoDocumento.documento.file.id)
    }
    
    if (this.requisicaoDocumento && !this.requisicaoDocumento.respostaFornecedor && !this.requisicaoDocumento.documento) {
      this.updateOptionalLabel(`Aguardando Resposta do Fornecedor`)
    } else {
      this.updateOptionalLabel(' ')
    }
    this._dynamicDialogRef.onDestroy.subscribe(()=>{
      if(this.aprovarRequisicaoRequestRef) this.aprovarRequisicaoRequestRef.close();
    })
  }

  ngOnInit(): void {
    this.hasImageError = false;
    this.formAprovacao = this._formBuilder.group({
      id: [(this.requisicaoDocumento.id || this.requisicaoDocumento._id),Validators.required],
      status: [(this.requisicaoDocumento?.status || this.options[0].value),Validators.required],
      justificativaAprovacaoComprador: [(this.requisicaoDocumento?.justificativaAprovacaoComprador || ''),Validators.minLength(10)],
      atualizadaPor: [(this.usuario?.id || this.usuario?._id),Validators.required],
    });
  }
  onStatusChange(event: any) {
    const status = event.value;
    const justificativaFormControl = this.formAprovacao.get('justificativaAprovacaoComprador');
  
    if (status !== 'aprovada') {
      justificativaFormControl.setValidators([Validators.required]);
    } else {
      justificativaFormControl.setValidators([]);
      justificativaFormControl.reset();
    }
    
    justificativaFormControl.updateValueAndValidity();
  }
  ngAfterViewInit() {
    this.stepper.steps.toArray().reverse().forEach(step => {
      step.select()
    });
  }
  getUrlFile(url) {
    return this._sanitizer.bypassSecurityTrustUrl(this._fileService.urlViewFile(url));
  }
  onPdfLoad(event) {
    this.pdfCarregado = true;
  }

  updateOptionalLabel(optionalLabelText) {
    this._matStepperIntl.optionalLabel = optionalLabelText;
    // Required for the optional label text to be updated
    // Notifies the MatStepperIntl service that a change has been made
    this._matStepperIntl.changes.next();
  }
  getLabelDoStatus() {
    return this.statuses.find((e) => e.value == this.requisicaoDocumento.status).label
  }
  ocorreuErro(): boolean { 
    return this.requisicaoDocumento.documento ? false : true; 
  }
  handleImageError() {
    // Display an error message or perform any other error handling logic
    this.hasImageError = true;
    console.error('Error loading image');
  }
  aprovarRequisicaoRequest(event?: Event) {
    try {
 
      // if (this.hasAprovacao && this.mapaDeAcoes && this.contexto && this.mapaDeAcoes.includes(this.contexto)) {
      //   this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` , sticky: true});
      //   return;
      // }
      this.formAprovacao.markAllAsTouched();
      if (!this.formAprovacao.valid) {
        return;
      }
      this.formAprovacao.disable()
      const body = this.formAprovacao.getRawValue();
      if(body.atualizadaPor == null) body.atualizadaPor = this.usuario.id;
      this.aprovarRequisicaoRequestRef = this._confirmationService.confirm({
        target: event.target,
        acceptLabel: "Sim",
        rejectLabel: "Nāo",
        message: "Tem certeza de que deseja prosseguir?",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: 'none',
        accept: () => {
          this.aprovarRequisicaoRequestRef.close();
          return this._requisicaoDocumentosService.patchAprovarRequisicao(body).subscribe(
            (res: any) => {
              this.formAprovacao.enable()
              this._messageService.add({ severity: "success", summary: "Ótimo", detail: "Requisição de Documento Aprovada"});
              setTimeout(() => {}, 3000);
              this._dynamicDialogRef.close();
              
            },
            (error: any) => {
              this.formAprovacao.enable()
              console.error(error);
              this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
            }
          );
        },
        reject: () => {
          this.formAprovacao.enable()
          this.aprovarRequisicaoRequestRef.close();
        }
      })
    } catch (error) {
      this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}`, sticky: true });
    }
  }
  // reprovarRequisicaoRequest(requisicao: RequisicaoDocumento) {
  //   try {
  //     if (!this.mapaDeAcoes.includes(this.contexto)) {
  //       this._messageService.add({ severity: "warn", summary: "Atençāo", detail: `Você nāo pode executar essa açāo!` });
  //       return;
  //     }
  //     this._confirmationService.confirm({
  //       message: "Você realmente deseja reprovar a requisiçāo documento " + requisicao.tipo.nome + "?",
  //       header: "Confirmar",
  //       icon: "pi pi-exclamation-triangle",
  //       accept: () => {
  //         this._requisicaoDocumentosService.patchReprovarRequisicao(requisicao.id).subscribe(
  //           (res: any) => {
  //             this._messageService.add({ severity: "success", summary: "Ótimo", detail: "Requisicao de documento reprovada!" });
  //           },
  //           (error: any) => {
  //             console.error(error);
  //             this._messageService.add({ severity: "error", summary: "Houve um erro!", detail: error.error.message });
  //           }
  //         );
  //       }
  //     });
  //   } catch (error) {
  //     this._messageService.add({ severity: "error", summary: "Houve um erro", detail: `${error?.message}` });
  //   }
  // }

  approvalValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const status = control.get('status');
    const justificativaAprovacaoComprador = control.get('justificativaAprovacaoComprador');

    return status && status.value !== 'aprovar' && !justificativaAprovacaoComprador?.value ? { 'justificativaRequired': true } : null;
  };

  getStatusPossiveisdeAprovacao(){
    switch (this.requisicaoDocumento.status) {
      case 'pendente-de-aprovacao':
        return 'Requisiçāo aguardando aprovaçāo';
      case 'pendente-de-retorno':
        return 'Aprovaçāo aguardando retorno';
      case 'pendente-de-envio':
        return 'Aprovaçāo aguardando envios';
      case 'aprovada':
        return 'Requisiçāo aprovada';
      case 'recusada':
        return 'Requisiçāo recusada pelo fornecedor';
      case 'concluida':
        return 'Requisiçāo concluída';
      case 'expirada': 
        return 'Requisiçāo expirada';
      case 'reprovada':
        return 'Requisiçāo reprovada pelo cliente';
      default:
        return this.requisicaoDocumento.status;
    }
  }
  precisaDeAprovacao(){
    // return (this.hasAprovacao || this.requisicaoDocumento.status == 'aprovada'  || this.requisicaoDocumento.status == 'pendente-de-aprovacao'  || this.requisicaoDocumento.status == 'pendente-de-retorno' || this.requisicaoDocumento.status == 'reprovada' ) && (this.contexto == 'comprador' || this.contexto == 'backoffice')
    const a = (this.requisicaoDocumento.status == 'pendente-de-aprovacao'  || this.requisicaoDocumento.status == 'pendente-de-retorno' || this.requisicaoDocumento.status == 'reprovada' ) && (this.contexto == 'comprador' || this.contexto == 'backoffice')
    
    return a;
  }
  viewFile() {
    const url = this._fileService.urlViewFile(this.requisicaoDocumento.documento?.file?.id || this.requisicaoDocumento.documento?.file?._id);
    window.open(url, '_blank');
    this._cdr.detectChanges();
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.requisicaoDocumento.documento?.file?.id || this.requisicaoDocumento.documento?.file?._id);
    window.open(url, '_blank');
    this._cdr.detectChanges();
  }
  getRespondidoPor(requisicaoDocumento: RequisicaoDocumento): string {
    if (requisicaoDocumento.respondidaPor) {
      return `${requisicaoDocumento.respondidaPor.name} - ${requisicaoDocumento.respondidaPor.email}`;
    }
    return 'Automático';
  }
  getAprovadaPor(requisicaoDocumento: RequisicaoDocumento): string {
    if (requisicaoDocumento.compradorAprovador) {
      return `${requisicaoDocumento.compradorAprovador.name} - ${requisicaoDocumento.compradorAprovador.email}`;
    }
    return 'Automático';
  }
  erroImage($event){
    this._messageService.add({
      key: "app",
      severity: "warn",
      summary: "Atenção",
      detail: "Erro ao carregar a imagem",
      life: 15000,
    })
    console.error($event);
  }
}
