import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments';
import { RequisicaoDocumentoService } from '@shared/services/requisicaoDocumento.service';
import { EMPTY, Observable, catchError } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoDocumentoResolver implements Resolve<boolean> {
  constructor(private _requisicaoDocumentoService: RequisicaoDocumentoService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.params.id;

    if (id === null || id === undefined) {
      Swal.fire({
        icon: "error",
        title: "Houve um erro",
        text: `é necessário informar um id!`
      });
      return EMPTY;
    }

    return this._requisicaoDocumentoService.getDocumentById(id).pipe(
      catchError((err) => {
        Swal.fire({
          icon: "error",
          title: "Houve um erro ao resolver dados da Requisiçāo de Documento",
          text: `${err}`
        });
        return EMPTY;
      })
    );
  }
}