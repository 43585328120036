import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDateFormat'
})
export class CustomDateFormatPipe implements PipeTransform {
  transform(date: string): string {
    const currentDate = new Date();
    const messageDate = new Date(date);

    // Converta as datas para valores numéricos em milissegundos
    const currentDateMillis = currentDate.getTime();
    const messageDateMillis = messageDate.getTime();

    // Verifique se a data é do mesmo dia
    if (currentDate.toDateString() === messageDate.toDateString()) {
      // Exibir o horário sem segundos
      return messageDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
    } else {
      // Verificar se passou mais de um ano
      const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
      if (currentDateMillis - messageDateMillis >= oneYearInMillis) {
        // Exibir o ano
        return messageDate.getFullYear().toString();
      } else {
        // Exibir o dia e mês
        return messageDate.toLocaleDateString();
      }
    }
  }
}
