<div>
  <!-- <mat-form-field appearance="fill"  style="z-index: 999; width: 10rem!important;">
    <mat-label>Perfil</mat-label>
    <mat-select [(ngModel)]="moduleActive" disableRipple (ngModelChange)="selectThisRole($event)">
      <mat-option *ngFor="let role of user.role" [value]="role">{{ role | uppercase }}</mat-option>
    </mat-select>
  </mat-form-field> -->
  <span class="p-float-label">
    <p-dropdown inputId="dropdown" [options]="user.role" [(ngModel)]="moduleActive" (onChange)="selectThisRole($event)" appendTo="body" 
    class="mt-1" >
      <ng-template pTemplate="selectedItem">
        <div class="perfil-item perfil-item-value flex flex-col gap-0">
          <span class="">{{ moduleActive | capitalizeFirstLetter }}</span>
          <small class="" *ngIf="moduleActive == 'comprador'">
            {{ user.clienteComprador.desc_emp | words: 1 | lowercase }}
          </small>
        </div>
    </ng-template>
    <ng-template let-perfil pTemplate="item">
        <div class="">
          {{ perfil | capitalizeFirstLetter }}
        </div>
    </ng-template>
    </p-dropdown>
    <label for="dropdown">Perfil</label>
</span>
</div>
