/* tslint:disable:max-line-length */
import { environment } from "@environments";
import { TreoNavigationItem } from "@treo/components/navigation";

export const defaultNavigation: TreoNavigationItem[] = [
  /** inicio das rotas do vendedor */
  {
    id: "dashboard",
    title: "Dashboard",
    subtitle: "",
    type: "basic",
    icon: "heroicons_outline:home",
    link: environment.routes.vendedor.dashboard,
    metadata: {},
    permitted: 'vendedor',
  },
  {
    id: "cotacoes",
    title: "Minhas Cotaçōes",
    // subtitle: "Visualize suas cotações",
    type: "basic",
    icon: "heroicons_outline:document-text",
    link: environment.routes.vendedor.cotacoes.listar,
    metadata: {},
    permitted: 'vendedor',
  },
  {
    id: "cotacoes",
    title: "Minhas Empresas",
    // subtitle: "Visualize suas cotações",
    type: "basic",
    icon: "heroicons_outline:office-building",
    link: environment.routes.vendedor.fornecedores.listar,
    metadata: {},
    permitted: 'vendedor',
  },
  // {
  //   id: "minhaconta",
  //   title: "Minha Conta",
  //   // subtitle: "Visualize suas informações",
  //   type: "basic",
  //   icon: "dripicons:user",
  //   link: environment.routes.vendedor.conta,
  //   metadata: {},
  //   permitted: 'vendedor',
  // },
  {
    id: 'documentos-vendedor',
    title: 'Documentos',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:document',
    link: environment.routes.vendedor.documentos.listar,
    metadata: {},
    permitted: 'vendedor',
  },
  {
    id: "help-center",
    title: "Central de Ajuda",
    // subtitle: "Precisa de ajuda?",
    type: "basic",
    icon: "dripicons:help",
    link: environment.routes.vendedor.helpcenter.home,
    metadata: {},
    permitted: 'vendedor',
  },
  /** fim das rotas do vendedor */
  /** inicio das rotas do backoffice */
  {
    id: 'dashboard-backoffice',
    title: 'Dashboard',
    subtitle: '',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: environment.routes.backoffice.dashboard,
    metadata: {},
    permitted: 'backoffice',
  },
  {
    id: 'clientes-backoffice',
    title: 'Clientes',
    // subtitle: 'Clientes Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:office-building',
    link: environment.routes.backoffice.clientes.listar,
    metadata: {},
    permitted: 'backoffice',
  },
  {
    id: 'usuarios-backoffice',
    title: 'Usuários',
    // subtitle: 'Usuários Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: environment.routes.backoffice.usuarios.listar,
    metadata: {},
    permitted: 'backoffice',
  },
  {
    id: 'fornecedores-backoffice',
    title: 'Fornecedores',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: environment.routes.backoffice.fornecedores.listar,
    metadata: {},
    permitted: 'backoffice',
  },
  {
    id: 'documentos-backoffice',
    title: 'Documentos',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:document',
    link: environment.routes.backoffice.documentos.listar,
    metadata: {},
    permitted: 'backoffice',
  },
  {
    id: 'localizar-fornecedores-backoffice',
    title: 'Localizar Fornecedores',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:search-circle',
    link: environment.routes.backoffice.localizar_fornecedores.listar,
    metadata: {},
    permitted: 'backoffice',
  },
  // {
  //     id      : 'minhaconta-backoffice',
  //     title   : 'Minha Conta',
  //     subtitle: 'Visualize suas informações',
  //     type    : 'basic',
  //     icon    : 'dripicons:user',
  //     link : '/backoffice/minhaconta',
  //     metadata: {},
  //     permitted: 'backoffice',
  // }
  /** fim das rotas do backoffice */
  /** inicio das rotas do comprador */
  {
    id: 'dashboard-comprador',
    title: 'Dashboard',
    subtitle: '',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: environment.routes.comprador.dashboard.listar,
    metadata: {},
    permitted: 'comprador',
  },

  // {
  //   id: 'cotacao-comprador',
  //   title: 'Cotações',
  //   // subtitle: 'Usuários Cadastrados',
  //   type: 'basic',
  //   icon: 'heroicons_outline:document-text',
  //   link: environment.routes.comprador.cotacao.listar,
  //   metadata: {},
  //   permitted: 'comprador',
  // },

  {
    id: 'aprovacao-comprador',
    title: 'Aprovações',
    // subtitle: 'Aprovações Cadastrados',
    type: 'basic',
    icon: 'mat_outline:fact_check',
    link: environment.routes.comprador.aprovacao.home,
    metadata: {},
    permitted: 'comprador',
    clientePermitted:  Array.isArray(environment.permissaoBetaAprovacao) ? environment.permissaoBetaAprovacao : environment.permissaoBetaAprovacao ? [environment.permissaoBetaAprovacao]: [],
    badge: {
      title     : 'Beta',
      style     : 'rounded',
      background: '#89bb30',
      color     : '#fff'
    }
  },
  {
    id: 'fornecedores-comprador',
    title: 'Fornecedores',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:office-building',
    link: environment.routes.comprador.fornecedores.listar,
    metadata: {},
    permitted: 'comprador',
  },
  {
    id: 'vendedores-comprador',
    title: 'Vendedores',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: environment.routes.comprador.vendedores.listar,
    metadata: {},
    permitted: 'comprador',
  },
  {
    id: 'documentos-comprador',
    title: 'Documentos',
    // subtitle: 'Fornecedores Cadastrados',
    type: 'basic',
    icon: 'heroicons_outline:document',
    link: environment.routes.comprador.documentos.listar,
    metadata: {},
    permitted: 'comprador',
  },
  {
    id: "help-center",
    title: "Central de Ajuda",
    // subtitle: "Precisa de ajuda?",
    type: "basic",
    icon: "dripicons:help",
    link: "/comprador/help-center",
    metadata: {},
    permitted: 'comprador',
  },
  /** fim das rotas do backoffice */

];

export const horizontalNavigation: TreoNavigationItem[] = [
  ...defaultNavigation,
];
