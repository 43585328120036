<div>
  <p-dropdown [options]="bancos" [(ngModel)]="bancoValue" [filter]="true" 
  (onChange)="onChange($event)" appendTo="body" filterBy="label,value" optionValue="value" 
  optionLabel="label" [showClear]="true" placeholder="Selecione um Banco">
  <ng-template pTemplate="selectedItem">
    <div class="selected-item">
        <div>{{ bancoSelecionado.value }} - {{ bancoSelecionado.label }}</div>
    </div>
  </ng-template>
  <ng-template let-banco pTemplate="item">
    <div class="banco-item">
        <div>{{ banco.value }} - {{ banco.label }}</div>
    </div>
</ng-template>
</p-dropdown>
</div>