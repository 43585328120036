import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usuario } from '@shared/models/usuario.interface';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, throwError, defer } from 'rxjs';
import { catchError, shareReplay, switchMap, tap, map  } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly SERVER_URL = environment.apiEndpoint;
  private readonly CACHE_DURATION = 60000; // 1 minuto em milissegundos

  private _userData$ = new BehaviorSubject<Usuario | null>(null);
  private _refreshToken$ = new BehaviorSubject<number>(0);

  constructor(
    private _httpClient: HttpClient,
  ) {}

  fetchUserData(): Observable<Usuario> {
    return this._httpClient.get<Usuario>(`${this.SERVER_URL}usuario/profile`).pipe(
      map((res: any) => {
        return res.data;
      }),
      tap(user => this._userData$.next(user)),
      catchError(error => {
        console.error('Erro ao atualizar dados do usuário:', error);
        sessionStorage.clear();
        location.reload();
        return throwError(() => error);
      })
    );
  }

  loadUser(): Observable<Usuario | null> {
    if(!this._userData$.value){
      this.fetchUserData().subscribe();
    }
    return this._userData$.asObservable();
  }

  getUserData(): Observable<Usuario | null> {
    return this._userData$.asObservable();
  }

  setUser(user: Usuario): void {
    this._userData$.next(user);
  }
  update(id: string, user: Usuario): Observable<Usuario> {
    return this._httpClient.put<Usuario>(`${this.SERVER_URL}usuario/${id}`, user).pipe(
      tap((updatedUser: Usuario) => {
        this._userData$.next(updatedUser);
      })
    );
  }
  create(key: string, user: Usuario): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}usuario/create/${key}`, user);
  }

  createVendedor(key: string, user: Usuario): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}usuario/create/vendedor`, user);
  }

  createUserPeloBackoffice(user: any): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}usuario/create/backoffice`, user);
  }

  verificarCNPJ(cnpj: string): Observable<any> {
    return this._httpClient.get(`${this.SERVER_URL}usuario/verificar/${cnpj}`);
  }

  getByEmail(email: string): Observable<any> {
    return this._httpClient.get(`${this.SERVER_URL}usuario/email/${email}`);
  }
}