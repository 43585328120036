import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments';


@Injectable({
  providedIn: 'root'
})
export class RequisicaoDocumentoService {

  constructor(private httpClient: HttpClient) { }

  // Cria um novo documento de requisição
  createDocument(requisicao: any): Observable<any> {
    const url = `${environment.apiEndpoint}requisicao/documento`;
    return this.httpClient.post<any>(url, requisicao);
  }

  // Obtém a lista de documentos de requisição
  getRequisicaoDocumentoAllPaginated(event): Observable<any> {
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}requisicao/documento/list`;

    return this.httpClient.get<any>(url, options);
  }
 

  // Obtém a lista de documentos de requisição
  getDocumentListFromFornecedor(fornecedor,event): Observable<any> {
    let options;
    if(event){
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}requisicao/documento/fornecedor/${fornecedor}/list`;
    return this.httpClient.get<any>(url,options);
  }
  // Obtém a lista de documentos de requisição
  getDocumentListFromVendedor(fornecedor, event): Observable<any> {
    let options;
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}requisicao/documento/vendedor/${fornecedor}`;
    return this.httpClient.get<any>(url, options);
  }

  getOnlyBySomeIDs(ids: any[]): Observable<any> {
    const url = `${environment.apiEndpoint}requisicao/documento/ids`;
    const params = new HttpParams().set('ids', ids.join(','));
    return this.httpClient.get<any>(url, { params });
  }

  // Obtém a lista de documentos de requisição
  getDocumentListFromFornecedorDeUmCliente(fornecedor, cliente, event): Observable<any> {
    let options;
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}requisicao/documento/${cliente}/fornecedor/${fornecedor}/list`;
    return this.httpClient.get<any>(url, options);
  }

  // Obtém a lista de documentos de requisição
  getRequisicoesFromClienteList(cliente, event): Observable<any> {
    let options;
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    const url = `${environment.apiEndpoint}requisicao/documento/${cliente}/list`;
    return this.httpClient.get<any>(url, options);
  }

  // Obtém um documento de requisição por ID
  getDocumentById(id: string): Observable<any> {
    const url = `${environment.apiEndpoint}requisicao/documento/${id}`;
    return this.httpClient.get<any>(url);
  }


  // Atualiza um documento de requisição por ID1
  updateDocument(id: string, data: any): Observable<any> {
    const url = `${environment.apiEndpoint}requisicao/documento/${id}`;
    return this.httpClient.patch<any>(url, data);
  }

  // Atualiza um documento de requisição por ID1
  responderRequisicaoDocumento(body: any): Observable<any> {
    const url = `${environment.apiEndpoint}requisicao/documento/responder`;
    return this.httpClient.patch<any>(url, body);
  }

  // Exclui um documento de requisição por ID
  deleteDocument(id: string): Observable<any> {
    const url = `${environment.apiEndpoint}requisicao/documento/${id}`;
    return this.httpClient.delete<any>(url);
  }
  patchAprovarRequisicao(body: any){
    const url = `${environment.apiEndpoint}requisicao/documento/aprovacao`;
    return this.httpClient.patch<any>(url,body)
  }
}
