import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { daysInWeek, differenceInDays } from "date-fns";
import { Table } from "primeng/table";

export default class Utils {
  static somenteNumeros(cnpj: string) {
    throw new Error('Method not implemented.');
  }
  static isNotUndefined(object: any) { return (object != undefined ? true : false); }
  static isNotNull(object: any) { return (object != null ? true : false); }
  static isUndefined(object: any) { return (object == undefined ? true : false); }
  static isNull(object: any) { return (object == null ? true : false); }
  static clearTableFilters(table: Table, storageKey: string) {
    table.clear();
    localStorage.removeItem(storageKey);
    sessionStorage.removeItem(storageKey);
  }
  static manterSomenteNumeros(str: string): string {
    if (str !== undefined) {
      return str.replace(/\D/g, '');
    }
    return '';
  }
  static generateLabelValueStatus(status: string): { label: string; value: string, badge: string } {
    switch (status) {
      case 'pendente-de-retorno':
        return { label: 'Pendente de Retorno', value: 'pendente-de-retorno',badge: "renewal" };
      case 'pendente-de-envio':
        return { label: 'Pendente de Envio', value: 'pendente-de-envio',badge: "negotiation" };
      case 'pendente-de-aprovacao':
        return { label: 'Pendente de Aprovação', value: 'pendente-de-aprovacao', badge: "pendente-de-aprovacao" };
      case 'expirada':
        return { label: 'Expirada', value: 'expired', badge: "unqualified" };
      case 'aprovada':
        return { label: 'Aprovada', value: 'aprovada', badge: "qualified" };
      case 'reprovada':
        return { label: 'Reprovada', value: 'reprovada', badge: "unqualified" };
      case 'recusada':
        return { label: 'Recusada', value: 'recusada', badge: "unqualified" };
      default:
        return { label: status, value: status , badge: "qualified"  }; 
    }
  }
  static getStatusToRequisicaoDocumento(){
    return  [
      "pendente-de-retorno",
      "pendente-de-envio",
      "pendente-de-aprovacao",
      "expirada",
      "aprovada",
      "recusada",
      "reprovada"].map((e) => Utils.generateLabelValueStatus(e) )
  }

  static getContextoDaRota(router: Router): string | null {
    const params = router.url;
    return params.split("/")[1];
  }
  
  static contarDiasDeHojeAte(data: string) {
    const dataFutura = new Date(data);
    const hoje = new Date();
    
    if (dataFutura <= hoje) {
      throw new Error('A data deve ser no futuro.');
    }
    
    const diffEmDias = differenceInDays(dataFutura, hoje);
  
    return diffEmDias;
  }
  static getRangeDisplayText = (page: number, pageSize: number, length: number) => {
    const initialText = ``;  // customize this line
    if (length == 0 || pageSize == 0) {
      return `${initialText} 0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;
    return `${initialText} ${startIndex + 1} até ${endIndex} de ${length}`; // customize this line
  };
  static getBrowserName(): string {
    const userAgent = navigator.userAgent;
    let browserName = "";
    if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
    } else if (userAgent.indexOf("Opera") > -1) {
      browserName = "Opera";
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Edge";
    } else if (userAgent.indexOf("IE") > -1) {
      browserName = "Internet Explorer";
    }
    return browserName;
  }
  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((arrayControl) => {
          if (arrayControl instanceof FormGroup) {
            this.validateAllFormFields(arrayControl);
          }
        });
      }
    });
  }
  static getErrorMessages(formGroup: FormGroup): string {
  const errorMap = {
    required: 'é obrigatório',
    email: 'não é um e-mail válido',
    minlength: 'é muito curto',
    maxlength: 'é muito longo',
    pattern: 'não corresponde ao padrão esperado',
    min: 'é menor que o valor mínimo permitido',
    max: 'é maior que o valor máximo permitido',
    nullValidator: 'é inválido',
    compose: 'é inválido',
    composeAsync: 'é inválido',
    group: 'é inválido',
    array: 'é inválido',
    checkboxRequiredValidator: 'é obrigatório',
    number: 'não é um número válido',
    date: 'não é uma data válida',
    json: 'não é um JSON válido',
    url: 'não é uma URL válida',
    time: 'não é uma hora válida',
    select: 'é obrigatório',
    noWhitespace: 'não pode conter espaços em branco',
    range: 'não está no intervalo permitido',
    digits: 'não é um número válido',
    numberRange: 'não está no intervalo permitido',
    round: 'não é um número arredondado',
    equal: 'não é igual ao valor esperado',
    equalTo: 'não é igual ao valor esperado',
    notEqualTo: 'é igual ao valor não permitido',
    includes: 'não inclui o valor esperado',
    password: 'não é uma senha válida',
    custom: 'é inválido',
    base64: 'não é um valor base64 válido',
    phone: 'não é um número de telefone válido',
    uuid: 'não é um UUID válido',
    rangeLength: 'não está no comprimento de intervalo permitido',
    equalPasswords: 'as senhas não são iguais',
    property: 'é inválido',
    latitude: 'não é uma latitude válida',
    longitude: 'não é uma longitude válida',
    // Adicione mais mapeamentos de erro aqui
  };

  const errorMessages = Object.keys(formGroup.controls)
    .map(controlName => {
      const control = formGroup.get(controlName);
      if (control.errors) {
        return Object.keys(control.errors)
          .map(error => `${controlName}: ${errorMap[error] || error}`)
          .join(', ');
      }
      return null;
    })
    .filter(errorMessage => errorMessage !== null)
    .join(', ');

  return errorMessages;
  }
  static convertProperties(obj: any, conversionMap: { [key: string]: string }): any {
    if (Array.isArray(obj)) {
      return obj.map(item => this.convertProperties(item, conversionMap));
    } else if (obj && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = conversionMap[key] || key;
        acc[newKey] = this.convertProperties(obj[key], conversionMap);
        return acc;
      }, {} as any);
    }
    return obj;
  };
  static convertIds(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(item => Utils.convertIds(item)); // Usar Utils.convertIds para manter o contexto correto
    } else if (obj !== null && typeof obj === 'object') {
      const newObj: any = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === '_id') {
            newObj['id'] = Utils.convertIds(obj[key]);
          } else {
            newObj[key] = Utils.convertIds(obj[key]);
          }
        }
      }
      return newObj;
    } else {
      return obj;
    }
  }
}
