import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackofficeDashboardService } from '@shared/services/backoffice-dashboard.service';
import { ApexOptions } from 'ng-apexcharts';
import { Subject, takeUntil } from 'rxjs';

@Component({
  templateUrl: './backoffice-dashboard.component.html',
  styleUrls: ['./backoffice-dashboard.component.scss']
})
export class BackofficeDashboardComponent implements OnInit, OnDestroy {
  data: any;
  ageOptions: ApexOptions;
  averagePurchaseValueOptions: ApexOptions;
  browsersOptions: ApexOptions;
  channelsOptions: ApexOptions;
  devicesOptions: ApexOptions;
  genderOptions: ApexOptions;
  languageOptions: ApexOptions;
  newVsReturningOptions: ApexOptions;
  purchasesOptions: ApexOptions;
  refundsOptions: ApexOptions;
  totalVisitsOptions: ApexOptions;
  uniqueVisitorsOptions: ApexOptions;
  uniquePurchasesOptions: ApexOptions;
  atualizando: boolean = false;
  pattern;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AnalyticsService} _analyticsService
   * @param {Router} _router
   */
  constructor(
    private _analyticsService: BackofficeDashboardService,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.pattern = {
      0: {
          pattern: new RegExp('/\B(?=(\d{3})+(?!\d))/g'),
      },
  };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.atualizarDados();
  }
  atualizarDados() {
    this.atualizando = true;
    this._analyticsService.getData()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {

        // Store the data
        this.data = data;

        // Prepare the chart data
        this._prepareChartData();
        setTimeout(() => {
          // Após a conclusão da atualização
          this.atualizando = false;
        }, 1000); // Tempo simulado de atualização em milissegundos
      });

    // Attach SVG fill fixer to all ApexCharts
    window['Apex'] = {
      chart: {
        events: {
          mounted: (chart: any, options?: any) => {
            this._fixSvgFill(chart.el);
          },
          updated: (chart: any, options?: any) => {
            this._fixSvgFill(chart.el);
          }
        }
      }
    };
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll?.unsubscribe();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Fix the SVG fill references. This fix must be applied to all ApexCharts
   * charts in order to fix 'black color on gradient fills on certain browsers'
   * issue caused by the '<base>' tag.
   *
   * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
   *
   * @param element
   * @private
   */
  private _fixSvgFill(element: Element): void {
    // Current URL
    const currentURL = this._router.url;

    // 1. Find all elements with 'fill' attribute within the element
    // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
    // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
    Array.from(element.querySelectorAll('*[fill]'))
      .filter((el) => el.getAttribute('fill').indexOf('url(') !== -1)
      .forEach((el) => {
        const attrVal = el.getAttribute('fill');
        el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
      });
  }

  /**
   * Prepare the chart data from the data
   *
   * @private
   */
  private _prepareChartData(): void {
    //  // Purchases
    //   this.purchasesOptions = {
    //       chart  : {
    //           animations: {
    //               enabled: false
    //           },
    //           fontFamily: 'inherit',
    //           foreColor : 'inherit',
    //           height    : '100%',
    //           type      : 'line',
    //           sparkline : {
    //               enabled: true
    //           }
    //       },
    //       colors : ['#4FD1C5'],
    //       series : [
    //           {
    //               name: 'Purchases',
    //               data: this.data.purchases.data
    //           }
    //       ],
    //       stroke : {
    //           curve: 'smooth'
    //       },
    //       tooltip: {
    //           theme: 'dark'
    //       },
    //       xaxis  : {
    //           type      : 'category',
    //           categories: this.data.purchases.labels
    //       },
    //       yaxis  : {
    //           labels: {
    //               formatter: (val) => {
    //                   return val.toString();
    //               }
    //           }
    //       }
    //   };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
