<form [formGroup]="validacaoCNPJFormGroup" class="mt-1" >
  <mat-form-field>
    <mat-label>CNPJ do Fornecedor</mat-label>
    <input id="cnpj" matInput mask="000.000.000-00||00.000.000/0000-00" [formControlName]="'cnpj'" />
    <mat-error *ngIf="validacaoCNPJFormGroup.get('cnpj').hasError('invalidCnpj')">O CNPJ é inválido
    </mat-error>
    <mat-error *ngIf="validacaoCNPJFormGroup.get('cnpj').hasError('invalidSituacao')">O CNPJ possui restrições
    </mat-error>
    <mat-error *ngIf="validacaoCNPJFormGroup.get('cnpj').hasError('required')">O CNPJ é obrigatório
    </mat-error>
  </mat-form-field>
  <button class="submit-button treo-mat-button-large" mat-flat-button [color]="'primary'" 
    [disabled]="validacaoCNPJFormGroup.disabled" (click)="buscarEhSalvarCNPJ()">
    <span *ngIf="!validacaoCNPJFormGroup.disabled"> Verificar CNPJ e Prosseguir </span>
    <mat-spinner *ngIf="validacaoCNPJFormGroup.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-spinner>
  </button>
</form>