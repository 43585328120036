import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { TreoNavigationModule } from "@treo/components/navigation";
import { NotificationsModule } from "app/layout/common/notifications/notifications.module";
import { SearchModule } from "app/layout/common/search/search.module";
import { ShortcutsModule } from "app/layout/common/shortcuts/shortcuts.module";
import { UserModule } from "app/layout/common/user/user.module";
import { RoleSelectorModule } from "app/core/componentes/role-selector/role-selector.module";
import { SharedModule } from "primeng/api";
import { MessagesModule } from "primeng/messages";
import { ModernLayoutComponent } from "./modern.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { CoreModule } from "app/core/core.module";

@NgModule({
  declarations: [
    ModernLayoutComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    TreoNavigationModule,
    MessagesModule,
    NotificationsModule,
    SearchModule,
    ShortcutsModule,
    UserModule,
    SharedModule,
    RoleSelectorModule,
  ],
  exports: [
    ModernLayoutComponent
  ]
})
export class ModernLayoutModule {
}
