export const UTILS_DASHBOARD = {
    toSet(list, keyFn){ 
        return list.reduce((resultSet, item) =>
        resultSet.add(typeof keyFn === 'string' ? item[keyFn] : keyFn(item)),new Set); 
    },
    toSetSize(list, keyFn){ 
        return list.reduce((resultSet, item) =>
        resultSet.add(typeof keyFn === 'string' ? item[keyFn] : keyFn(item)),new Set).size; 
    },
    filter(list,KeyFn,equals){
        return list.filter((item) => item[KeyFn] == equals);
    },
    getFirstName(list: string[]){
        return list.map((item)=> item.split(" ",1)[0]);
    }
}