<div class="card">
  <treo-message class="mb-2" [dismissed]="!message.show" [appearance]="message.appearance" [showIcon]="message.showIcon"
          [type]="message.type">
          {{message.content}}
  </treo-message>
  <p-table #dtRequisicoes [value]="requisicoes" (onLazyLoad)="loadRequisicoesDocumentos($event)"
    [loading]="loadingRequisicoes" class="mb-5" [(rows)]="limitRequisicoes" [lazy]="true"
    [totalRecords]="totalRecordsRequisicoes" [paginator]="true" [scrollable]="true" scrollHeight="400px"  
    [globalFilterFields]="['motivo','tipo.nome','status','solicitadoPor','fornecedorRequerido.nome','fornecedorRequerido.cnpj']"
    [tableStyle]="{'min-width': '75rem'}" [rowsPerPageOptions]="[5,10,25,50,100]" stateStorage="session" stateKey="listar-requisicoes-documentos"
    [rowHover]="true" currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" [showCurrentPageReport]="true">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-space-between" style="justify-content: space-between!important;">
        <div class="flex justify-content-space-between">
          <button  *ngIf="mapaDeAcoes.criar.includes(contexto)" pButton pRipple label="Requisitar" icon="pi pi-plus" class="mr-2 p-button-raised"
            (click)="requisitarNovo()"  ></button>
          <button pButton pRipple label="Limpar" class="p-button-outlined" icon="pi pi-filter-slash"
            (click)="dtRequisicoes.reset()"></button>
        </div>
        <div class="flex justify-content-space-between">
          <!-- <span class="p-input-icon-left mr-2 line-height-normal">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtRequisicoes.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..." />
          </span> -->
          <p-button class="p-button-raised" icon="pi pi-refresh" (click)="loadRequisicoesDocumentos()"></p-button>
        </div>
      </div>

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <!-- <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th> -->
        <!-- <th pSortableColumn="status" style="min-width:5rem">Status <p-sortIcon field="status"></p-sortIcon>
          </th> -->
        <th pSortableColumn="status" style="min-width: 14rem;">
          <div class="flex justify-content-center align-items-center">
            Status
            <p-sortIcon field="status"></p-sortIcon>
            <p-columnFilter field="status" matchMode="equals" display="menu">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any"
                  [showClear]="true">
                  <ng-template let-option pTemplate="selectedItem">
                    <span [class]="'badge status-' + option.value">{{option.label}}</span>
                  </ng-template>
                  <ng-template let-option pTemplate="item">
                    <span [class]="'badge status-' + option.value">{{option.label}}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="dataInicio" style="min-width:11rem">Data Inicial
          <p-sortIcon field="dataInicio"></p-sortIcon>
          <p-columnFilter class="header-center" type="date" field="dataInicio" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="dataFinal" style="min-width:11rem">Prazo Final
          <p-sortIcon field="dataFinal"></p-sortIcon>
          <p-columnFilter class="header-center" type="date" field="dataInicio" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="tipo.nome" style="min-width:10rem" >Tipo
          <p-sortIcon field="tipo.nome"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="tipo.nome" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="motivo" style="min-width:10rem" >Motivo
          <p-sortIcon field="motivo"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="motivo" display="menu"></p-columnFilter>
        </th>
        <!-- <th pSortableColumn="cliente.desc_emp" style="min-width:10rem">Cliente solicitador <p-sortIcon field="cliente.desc_emp">
            </p-sortIcon>
          </th> -->
        <th pSortableColumn="cliente.desc_emp" style="min-width:10rem" *ngIf="contexto == 'vendedor'">Cliente
          <p-sortIcon field="cliente.desc_emp"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="cliente.desc_emp"
            display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="fornecedorRequerido.nome" style="min-width:14rem"> {{  contexto == 'comprador' ? 'Fornecedor' : 'Empresa' }}
          <p-sortIcon field="fornecedorRequerido.nome"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="fornecedorRequerido.nome"
          display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="fornecedorRequerido.cnpj" style="min-width:14rem"> {{  contexto == 'comprador' ? 'Fornecedor CNPJ' : 'Empresa CNPJ' }}
          <p-sortIcon field="fornecedorRequerido.cnpj"></p-sortIcon>
          <p-columnFilter class="header-center" type="text" field="fornecedorRequerido.rawCNPJ"
          display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="updatedAt" style="min-width:10rem">Atualizaçāo
            <p-sortIcon field="updatedAt"></p-sortIcon>
          </th>
        <th pFrozenColumn></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-requisicao>
      <tr>
        <!-- <td>
            <p-tableCheckbox [value]="selectedrequisicaosRequisicoes"></p-tableCheckbox>
          </td> -->
        <td> <span [class]="'badge status-' + requisicao.status ">{{ requisicao.status?.replaceAll("-"," ") }}</span></td>
        <td>{{ requisicao.dataInicio | date: "dd/MM/yyyy":'UTC' }}</td>
        <td>{{ (requisicao.dataFinal == null ? '-' : requisicao.dataFinal | date: "dd/MM/yyyy":'UTC') }}</td>
        <td><span [pTooltip]="requisicao.tipo?.nome" [appendTo]="'body'" >{{ requisicao.tipo?.nome }}</span></td>
        <td><span [pTooltip]="requisicao.motivo" [appendTo]="'body'" >{{ requisicao.motivo | truncate: 30 }}</span></td>
        <td *ngIf="contexto == 'vendedor'" >{{ requisicao.cliente?.desc_emp }}</td>
        <td ><span [pTooltip]="requisicao.fornecedorRequerido?.nome" [appendTo]="'body'" >{{ requisicao.fornecedorRequerido?.nome }}</span></td>
        <td ><span [pTooltip]="requisicao.fornecedorRequerido?.cnpj" [appendTo]="'body'" >{{ requisicao.fornecedorRequerido?.cnpj }}</span></td>
        <td>{{ requisicao.updatedAt | date: "dd/MM/yyyy":'UTC' }}</td>
        <td alignFrozen="right" pFrozenColumn class="flex items-center space-x-2">
          <button pButton pRipple icon="pi pi-eye" class="p-button-raised p-button-rounded p-button-secondary"
            *ngIf="(requisicao.status != 'pendente-de-envio') && mapaDeAcoes.visualizar.includes(contexto)" (click)="exibirRequisicao(requisicao)"></button>
          <button *ngIf="(requisicao.status == 'pendente-de-envio' || requisicao.status == 'pendente-de-retorno') && mapaDeAcoes.responder.includes(contexto)" pButton pRipple icon="pi pi-send" class="p-button-raised p-button-rounded p-button-warning"
            (click)="responderRequisicao(requisicao)"></button>
          <button pButton pRipple icon="pi pi-check" class="p-buttaon-raised p-button-rounded p-button-warning"
            *ngIf="requisicao.status == 'pendente-de-aprovacao' && mapaDeAcoes.aprovar.includes(contexto)" (click)="exibirEhAprovarEhReprovar(requisicao)"></button>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-raised p-button-rounded p-button-primary"
            *ngIf="(requisicao.status == 'pendente-de-envio' || requisicao.status == 'expirada') && mapaDeAcoes.editar.includes(contexto)" (click)="editarRequisicao(requisicao)"></button>
          <button pButton pRipple icon="pi pi-trash" class="p-button-raised p-button-rounded p-button-danger"
            *ngIf="requisicao.status == 'pendente-de-envio' && mapaDeAcoes.excluir.includes(contexto) || contexto == 'backoffice'" (click)="excluirRequisicao(requisicao)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="9">
          Nenhuma Requisiçāo de Documentos encontrada.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<!-- <p-toast></p-toast> -->
<p-confirmDialog key="listar-requisicao" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>