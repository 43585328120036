<form [formGroup]="categoriasFormGroup" class="mt-1" >
  <div class="treo-mat-dense w-full grid grid-cols-1 gap-2">
    <div class="flex flex-col w-full">
      <span class="mat-label font-semibold">Categorias de Produtos Selecionadas*</span>
      <p-multiSelect [options]="categoriaFornecedor" [emptyMessage]="'Nenhum resultado encontrado.'"
        [emptyFilterMessage]="'Nenhum resultado encontrado com esse filtro.'" [showToggleAll]="false"
        appendTo="body" formControlName="categoriasFornecedor" defaultLabel="Selecione suas categorias"
        optionLabel="descricao" optionValue="id" display="chip" required></p-multiSelect>
      <!-- <p-autoComplete appendTo="body" formControlName="categoriasFornecedor" [suggestions]="suggestions" (completeMethod)="filterCategories($event)"  multiple="true" field="descricao" showEmptyMessage="true" emptyMessage="Categoria nāo encontrada" delay="500" minLength="4" ></p-autoComplete> -->
      <small class="invalid-feedback"
        *ngIf="categoriasFormGroup && (categoriasFormGroup.controls.categoriasFornecedor.touched || categoriasFormGroup.controls.categoriasFornecedor.dirty) && categoriasFormGroup.controls.categoriasFornecedor.hasError('required')">As
        Categorias de Produtos sāo obrigatórias, selecione ao menos uma.</small>
    </div>
  </div>
  <div>
    <button class="submit-button treo-mat-button-large" mat-flat-button [color]="'primary'"
      [disabled]="categoriasFormGroup.disabled" (click)="salvarCategoriasDoFornecedor()">
      <span *ngIf="!categoriasFormGroup.disabled"> Salvar e Prosseguir </span>
      <mat-spinner *ngIf="categoriasFormGroup.disabled" [diameter]="24"
        [mode]="'indeterminate'"></mat-spinner>
    </button>
  </div>
</form>