export interface AnexoUAU {
    lista_arquivos?: ArquivoDoAnexoUAU[];
    empresa?: number;
    clienteId?: string;  
    numeroCotacao?: number;
    codigoFornecedor?: number;
}

export interface ArquivoDoAnexoUAU {
  ConteudoArquivo?: string;
  NomeArquivo?: string;
  CaminhoArquivo?: string;
  Extension?: string;
  Tamanho?: number;
  Identificador: string;
  Origem?: number;
  CaminhoPadrao?: string;
  filename?: string;
  dataEnvio?: Date;
}