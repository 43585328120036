<!-- Navigation -->
<treo-vertical-navigation class="bg-cool-gray-900 theme-dark"
                         *ngIf="isScreenSmall && data"
                         [appearance]="'classic'"
                         [mode]="'over'"
                         [name]="'mainNavigation'"
                         [navigation]="getnavigationFor(data.navigation.default,moduleActive)"
                         [opened]="false">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <a routerLink="/">
                <img src="assets/images/logo/logo-cotar-on-dark.svg">
            </a>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Logo -->
        <div class="logo"
             *ngIf="!isScreenSmall">
             <a routerLink="/">
                <img class="logo-text"
                src="assets/images/logo/logo-cotar-horizontal-large-2.svg">
                <!-- <img class="logo-text"
                 src="assets/images/logo/logo-text.svg"> -->
                <!-- <img class="logo-text-on-dark"
                    src="assets/images/logo/logo-text-on-dark.svg"> -->
                <!-- <img class="logo-base"
                    src="assets/images/logo/logo.svg"> -->
            </a>
        </div>

        <!-- Horizontal navigation -->
        <treo-horizontal-navigation *ngIf="!isScreenSmall && data"
                                   [name]="'mainNavigation'"
                                   [navigation]="getnavigationFor(data.navigation.horizontal,moduleActive)"
                                   ></treo-horizontal-navigation>

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                *ngIf="isScreenSmall && data"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <!-- Search -->
        <!-- <search [appearance]="'bar'"></search> -->

        <!-- Shortcuts -->
        <!-- <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->

        <!-- Messages -->
        <!-- <messages [messages]="data.messages"></messages> -->

        <!-- Notifications -->
        <!-- <notifications [notifications]="data.notifications"></notifications> -->

        <!-- auth-role-selector -->
        <auth-role-selector 
        *ngIf="data && data.user && data.user.role && data.user.role.length > 1"
        [user]="data?.user" [moduleActive]="moduleActive"></auth-role-selector>

        <!-- User -->
        <user *ngIf="data" [user]="data?.user"></user>

    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer" style="max-height: 40px!important;">
        Sistema Cotar &copy; {{currentYear}}
    </div>

</div>
