<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="">
      <h1 *ngIf="requisicaoDocumento && requisicaoo.id; then editTemplate; else newTemplate"></h1>
    
      <ng-template #editTemplate>
        Editar Requisição de Documento
      </ng-template>
    
      <ng-template #newTemplate>
        Requisitar Novo Documento 
      </ng-template>
    </div>
    <div class="right">
      <!-- Action buttons -->
    </div>
  </div>
  <div class="max-w">
    <form [formGroup]="formData"  autocomplete="disabled"
      class="flex flex-col p-8 pb-0 bg-card shadow rounded overflow-hidden">
      <div class="flex flex-col gap-4">
        <!-- Fornecedor -->
        <div class="treo-mat-dense flex flex-col gap-2">
          <label for="fornecedorRequerido">Fornecedor </label>
          <p-multiSelect [options]="fornecedores" formControlName="fornecedorRequerido" optionLabel="nome"
            optionValue="id" [filter]="true" filterBy="nome" [showClear]="true" display="chip" [virtualScroll]="true"
            [virtualScrollItemSize]="60" [selectedItemsLabel]="'{0} fornecedores selecionados'"
            placeholder="Selecione um fornecedor" [disabled]="requisicaoDocumento && requisicaoDocumento.id"
            [style]="formData.get('fornecedorRequerido').errors ? 'ng-invalid ng-dirty' : '' ">
          </p-multiSelect>

          <small id="fornecedorRequerido-help" class="p-error block"
            *ngIf="formData.get('fornecedorRequerido').errors?.required && formData.get('fornecedorRequerido').touched">Campo
            Obrigatório.</small>
        </div>

        <!-- Tipo -->
        <div class="treo-mat-dense flex flex-col gap-2">
          <label for="tipo">Tipo</label>
          <p-dropdown [options]="documentoTipos" formControlName="tipo" optionLabel="nome" optionValue="id"  appendTo="body"
            [filter]="true" filterBy="nome" [showClear]="true" [virtualScroll]="true" [virtualScrollItemSize]="60"
            placeholder="Selecione um tipo" invalidInputClass></p-dropdown>
          <small id="tipo-help" class="p-error block"
            *ngIf="formData.get('tipo').errors?.required && formData.get('tipo').touched">Campo obrigatório.</small>
        </div>
        <!-- Data de Início -->
        <div class="treo-mat-dense flex flex-col gap-2">

          <label for="dataInicio">Data de Início</label>
          <input id="dataInicio" type="date" style="width: fit-content;" pInputText formControlName="dataInicio" [ngClass]="[ formData.get('dataInicio').invalid && formData.get('dataInicio').dirty ? 'ng-invalid ng-dirty' : '' ]">
          <small id="dataInicio-help" class="block" *ngIf="apresentarMensagemDataInicio()">O fornecedor ficará bloqueado para preencher novas cotações a partir da data <strong>{{ formData.controls.dataInicio.value | date: "dd/MM/yyyy" }}</strong> até o envio e a aprovação da requisição de documento.</small>
          <small id="dataInicio-help" class="p-error block"
            *ngIf="formData.get('dataInicio').errors?.required && formData.get('dataInicio').touched">Campo
            Obrigatório.</small>
        </div>  

        <!-- Prazo Final -->
        <div class="treo-mat-dense flex flex-col gap-2">

          <label for="dataFinal">Prazo Final (opcional)</label>
          <input id="dataFinal" type="date" style="width: fit-content;" pInputText formControlName="dataFinal" placeholder="Prazo Final" [ngClass]="[formData.get('dataFinal').invalid && formData.get('dataFinal').dirty ? 'ng-invalid ng-dirty' : '' ]">
          <small id="dataInicio-help" class="block" *ngIf="apresentarMensagemDataFinal()">O fornecedor ficará liberado para preencher novas cotações até o prazo final. Após <strong>{{ formData.controls.dataFinal.value | date: "dd/MM/yyyy" }}</strong> a requisição expira e o fornecedor ficará bloqueado.</small>
          <small id="dataFinal-help1" class="p-error block"
            *ngIf="formData.get('dataInicio').hasError('dataInicioMenorQueDataFinal') && formData.get('dataFinal').touched">A Prazo Final deve ser maior que a data de início.</small>
          <small id="dataFinal-help2" class="p-error block"
            *ngIf="formData.get('dataFinal').errors?.required && formData.get('dataFinal').touched">Campo obrigatório.</small>
        </div>

        <!-- Motivo -->
        <div class="treo-mat-dense flex flex-col gap-2">
          <label for="motivo">Motivo</label>
          <input id="motivo" type="text" pInputText formControlName="motivo">
          <small id="motivo-help" class="p-error block"
            *ngIf="formData.get('motivo').errors?.required && formData.get('motivo').touched">Campo obrigatório.</small>
        </div>

        <div class="treo-mat-dense flex flex-col gap-2">
          <label for="observacao">Observaçāo</label>
          <input id="observacao" type="text" pInputText formControlName="observacao" [ngClass]="[ formData.get('observacao').invalid && formData.get('observacao').dirty ? 'ng-invalid ng-dirty' : '']">
        </div>

      </div>
      <div class="flex items-center justify-end border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <treo-message [dismissed]="!message.show" [appearance]="message.appearance" [showIcon]="message.showIcon"
          [type]="message.type">
          {{message.content}}
        </treo-message>
        <button pButton pRipple label="Cancelar" *ngIf="inRoute" icon="pi pi-times" class="p-button-text"
          (click)="cancelar()"></button>
        <button mat-raised-button (click)="saveData()" [class.spinner]="loading" [disabled]="loading" color="primary"
          class="px-6 ml-3">
          Salvar
        </button>
      </div>
    </form>
  </div>