import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Fornecedor from '@shared/models/fornecedor.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { UserService } from '@shared/services/user.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'autocomplete-vincular-vendedor-fornecedor',
  templateUrl: './autocomplete-vincular-vendedor-fornecedor.component.html',
  styleUrls: ['./autocomplete-vincular-vendedor-fornecedor.component.scss']
})
export class AutocompleteVincularVendedorFornecedorComponent {
  @Input() fornecedor: Fornecedor;
  
  filteredVendedores: Usuario[] = null;
  loadingVinculo: boolean;
  vendedorSelected: Usuario = null;

  @Output() vinculado = new EventEmitter<{}>();

  constructor(
    private _fornecedorService: FornecedorService,
    private _userService: UserService,
    private _messageService: MessageService,
  ) { }


  filterVendedor(event) {
    try {
      this._userService.getByEmail((event.query || this.vendedorSelected)).subscribe(
        (response: any) => {
          
          this.filteredVendedores = response.data;
        }, (error: any) => {
          this.filteredVendedores = null;
          console.error(error);
          this._messageService.add({ key: 'app', severity: 'error', summary: 'Houve um erro', detail: `${error?.message}` });
        })
    } catch (error) {
      this._messageService.add({ key: 'app', severity: 'error', summary: 'Houve um erro', detail: `${error?.message}` })
    }
  }
  vendedorValido() {
    if (this.vendedorSelected && this.vendedorSelected.hasOwnProperty('id')) {
      return true;
    } else {
      return false;
    }
  }
  vincular() {
    try {
      if (this.vendedorValido()) {
        this.loadingVinculo = true;
        this._fornecedorService.vincularVendedorFornecedor(this.vendedorSelected.id, this.fornecedor.rawCNPJ).subscribe({
          next: (response: any) => {
            this.vinculado.emit({});
            this.vendedorSelected = null;
            this._messageService.add({ key: 'app', severity: 'success', summary: 'Ótimo', detail: `Vendedor vinculado com sucesso` })
            this.loadingVinculo = false;
          }, error: (error: any) => {
            this._messageService.add({ key: 'app', severity: 'error', summary: 'Houve um erro', detail: `${error?.message}` })
            this.loadingVinculo = false;
          }
        })
      } else {
        this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atençāo', detail: "Selecione um vendedor válido." })
      }
    } catch (error) {
      this._messageService.add({ key: 'app', severity: 'error', summary: 'Houve um erro', detail: `${error?.message}` })
    }
  }
}
