import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Utils from 'app/shared/helpers/utils';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from 'app/shared/models/fornecedor.interface';
import { APIresponsePaginate } from 'app/shared/models/paginateResponse.interface';
import { DocumentoService } from '@shared/services/documento.service';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { FileService } from '@shared/services/file.service';

@Component({
  selector: 'app-gerenciar-documento-fornecedor',
  templateUrl: './gerenciar-documento-fornecedor.component.html',
  styleUrls: ['./gerenciar-documento-fornecedor.component.scss'],
})
export class GerenciarDocumentoFornecedorComponent implements OnInit, OnDestroy {
  @Input('fornecedor') fornecedor: Fornecedor;
  documentoDialog: boolean = false;
  editDocumentoDialog: boolean = false;
  documentos: Documento[];
  documento: Documento;
  selectedDocumentos: Documento[];
  loading: boolean = false;
  private destroyed$ = new Subject();
  totalRecords: number = 0;
  offset: number = 0;
  limit: number = 10;
  totalPages: number;
  page: number = 1;
  pagingCounter: number = 1;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
  somenteVisualizar: boolean = false;
  @ViewChild('dt') dt: Table;

  constructor(
    private _documentoService: DocumentoService,
    private _confirmationService: ConfirmationService,
    private _sanitizer: DomSanitizer,
    private _messageService: MessageService,
    private _fileService: FileService,
  ) { }
  ngOnDestroy(): void {
    this._confirmationService.close();
  }

  ngOnInit() {
  }
  load(event?: LazyLoadEvent) {
    try {
      this.loading = true;
      this._documentoService.listarDocumentosDoFornecedor(this.fornecedor.id, event)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: any) => {
          this.documentos = res.docs;
          this.totalRecords = res.totalDocs;
          this.limit = res.limit;
          this.loading = false
        }, (error) => {
          this.loading = false;
          console.error(error)
          this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message, sticky: true });
        });
    } catch (error) {
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true })
    }
  }
  showImage(image) {
    return this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + image)
  }
  viewFile(documento) {
    const url = this._fileService.urlViewFile(documento.id);
    window.open(url, '_blank');
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.documento.file.id || this.documento.file._id);
    window.open(url, '_blank');
  }
  openNew() {
    
    this.documento = {};
    this.documentoDialog = true;
  }
  deleteSelected() {
    const confirmRef = this._confirmationService.confirm({
      message: 'Você realmente deseja deletar todos os documentos selecionados?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.documentos = this.documentos.filter(val => !this.selectedDocumentos.includes(val));
        this.selectedDocumentos = null;
        this._messageService.add({ severity: 'success', summary: 'Successful', detail: 'documentos deletados' });
      },
      reject: ()=>{
        confirmRef.close()
      }
    });
  }

  edit(documento: Documento) {
    
    this.somenteVisualizar = false;
    this.documento = documento;
    this.editDocumentoDialog = true;
  }
  show(documento: Documento) {
    
    this.somenteVisualizar = true;
    this.documento = documento;
    this.editDocumentoDialog = true;
  }

  delete(documento: Documento) {
    try {

      const confirmRef = this._confirmationService.confirm({
        message: 'Você realmente deseja deletar o documento ' + documento.nome + '?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this._documentoService.deleteOne(documento.id).subscribe(
            (res: any) => {
              
              this.documentos = this.documentos.filter(val => val.id !== documento.id);
              this.documento = {};
              this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: 'documento deletado' });
            },
            (error: any) => {
              console.error(error)
              this._messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: error.error.message, sticky: true});
            }
          )
        },
        reject: ()=>{
          confirmRef.close()
        }
      });
    } catch (error) {
      this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error?.message}`, sticky: true })
    }
  }
  resetDialog(event) {
    this.editDocumentoDialog = event;
    this.documentoDialog = event;
  }


  createdAndEditedDocumento(documento) {
    this.documentoDialog = false;
    this.editDocumentoDialog = false;
    this.documento = null;
    // Procura o índice do documento existente com base no 'id'
    const index = this.documentos.findIndex((item) => item.id === documento.id);

    if (index !== -1) {
      // Substitui o documento existente pelo novo documento usando 'splice'
      this.documentos.splice(index, 1, documento);
    } else {
      // Se o documento não foi encontrado, simplesmente adiciona o novo documento à lista
      this.documentos.push(documento);
    }
  }


  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.documentos.length; i++) {
      if (this.documentos[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }
  clear(table: Table, storageKey: string) {
    Utils.clearTableFilters(table, storageKey)
  }
}
