import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CEPError, CEPErrorCode, NgxViacepService } from '@brunoc/ngx-viacep';
import Utils from '@shared/helpers/utils';
import Fornecedor, { Endereco } from '@shared/models/fornecedor.interface';
import { FornecedorService } from '@shared/services/fornecedor.service';
import { MessageService } from 'primeng/api';
import { EMPTY, catchError } from 'rxjs';

@Component({
  selector: 'form-criar-atualizar-endereco-fornecedor',
  templateUrl: './form-criar-atualizar-endereco-fornecedor.component.html',
  styleUrls: ['./form-criar-atualizar-endereco-fornecedor.component.scss']
})
export class FormCriarAtualizarEnderecoFornecedorComponent implements OnInit,AfterViewInit {
  enderecoFormGroup: FormGroup;
  loading: boolean = false;
  informarNovoEndereco: boolean = false;
  filteredCEP: any[];

  @Input('cnpj') cnpj: string;
  @Input('fornecedorId') fornecedorId: string;
  @Input('endereco') endereco?: Endereco;
  
  @Output('fornecedorAtualizado') emitter = new EventEmitter<Fornecedor>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() messageERROR = new EventEmitter<string>();

  constructor(
    private _formBuilder: FormBuilder,
    private _fornecedorService: FornecedorService,
    private _messageService: MessageService,
    private _viacep: NgxViacepService,
  ) { }


  ngAfterViewInit() {
    this.atualizaEndereco(this.endereco);
    this.formReady.emit(this.enderecoFormGroup);
  }

  ngOnInit(): void {
    this.atualizaEndereco(this.endereco);

    // this.enderecoFormGroup = this._formBuilder.group({
    //   cep: [null, Validators.required],
    //   logradouro: [null, Validators.required],
    //   numero: [null],
    //   complemento: [null],
    //   bairro: [null, Validators.required],
    //   municipio: [null, Validators.required],
    //   uf: [null, Validators.required],
    // });
    
  }
  atualizaEndereco(endereco: Endereco): void {
    
    this.enderecoFormGroup = this._formBuilder.group({
      cep: endereco?.cep,
      logradouro: endereco?.logradouro,
      numero: endereco?.numero,
      complemento: endereco?.complemento,
      bairro: endereco?.bairro,
      municipio: endereco?.cidade,
      uf: endereco?.uf
    });
  }
  salvarEndereco(): void {
    this.messageERROR.emit(null);
    this.enderecoFormGroup.markAllAsTouched();
    if (this.enderecoFormGroup.invalid) {
      const errorMessages = Utils.getErrorMessages(this.enderecoFormGroup);
      if (errorMessages) {
        console.error(errorMessages);
        this.messageERROR.emit(errorMessages);
        return;
      }
      return;
    }
    this.enderecoFormGroup.disable();
    const endereco = this.enderecoFormGroup.getRawValue()
    const fornecedor = {
      id: this.fornecedorId, 
      rawCNPJ: Utils.manterSomenteNumeros(this.cnpj),
      enderecos: [endereco]
    }
    this._fornecedorService.atualizarFornecedorProspect(fornecedor).subscribe({
      next: (res: any) => {
        this.enderecoFormGroup.enable();
        this.emitter.emit(res as Fornecedor);
      },
      error: (err) => {
        this.enderecoFormGroup.enable();
        console.error(err)
        this.messageERROR.emit(`Houve um erro,${err.error.message || err.error.error || err.error}`);
        // this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: err.error.message });
      }
    })
  }
  filterCEP($event) {
    
    this._viacep
    .buscarPorCep($event.query.replace(/[\s\W]/g, ''))
    .pipe(
      catchError((error: CEPError) => {
        // Ocorreu algum erro :/
        this.filteredCEP = [];
        
        switch (error.getCode()) {
          case CEPErrorCode.CEP_NAO_ENCONTRADO:
            this._messageService.add({ severity: 'warn', summary: 'Ops!', detail: 'Servidor de CEP fora do ar, você precisará informar o endereço manualmente.' });
            break;
          case CEPErrorCode.CEP_MUITO_CURTO:
            // this.enderecoFormGroup.controls.cep.setValue("")
            break;
          case CEPErrorCode.CEP_MUITO_LONGO:
            this.enderecoFormGroup.controls.cep.setValue(this.enderecoFormGroup.controls.cep.value.slice(0, 8))
            break;
          case CEPErrorCode.ERRO_SERVIDOR:  
            this._messageService.add({ severity: 'warn', summary: 'Ops!', detail: 'Servidor de CEP fora do ar, você precisará informar o endereço manualmente.' });
            
            break;
          case CEPErrorCode.UF_VAZIA:
            
            break;
          case CEPErrorCode.UF_NAO_EXISTE:
            
            break;
          // Quaisquer outros erros contidos em CEPErrorCode podem ser tratados assim
        }
        return EMPTY;
      })
    ).subscribe((res: any) => {
      
      const enderecos = Array.isArray(res) ? res : [res];
      if(enderecos.length == 1) {
        this.filteredCEP = [];
        this.selectedEndereco(enderecos[0])
      }else{
        this.filteredCEP = enderecos;
      }
    });
  }
  selectedEndereco(endereco){
    this.enderecoFormGroup.controls.complemento.setValue(endereco.complemento);
    this.enderecoFormGroup.controls.numero.setValue(endereco.numero);
    this.enderecoFormGroup.controls.logradouro.setValue(endereco.logradouro);
    this.enderecoFormGroup.controls.bairro.setValue(endereco.bairro,{ readonly: true });
    this.enderecoFormGroup.controls.municipio.setValue(endereco.localidade, { readonly: true });
    this.enderecoFormGroup.controls.uf.setValue(endereco.uf, { readonly: true });
  }
}
