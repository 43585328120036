import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@environments';
import { AuthService } from '@shared/services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._authService.signOut();
            this._router.navigate(['error/401']);
          } else if (error.status === 403) {
            this._router.navigateByUrl(environment.routes.errors.error403);
          }
        }

        return throwError(error);
      })
    );
  }
}
