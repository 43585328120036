import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'words'
})
export class WordsPipe implements PipeTransform {
  transform(value: string, numberOfWords: number): string {
    // Dividir o valor em palavras usando espaços, hífens e barras como delimitadores
    const words = value.split(/[\s\-\/]+/);

    // Verificar se o número de palavras solicitado é maior que o número real de palavras
    const numWordsToShow = Math.min(numberOfWords, words.length);

    // Juntar as palavras novamente usando espaço como separador
    return words.slice(0, numWordsToShow).join(' ');
  }
}