import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { cnpjValidator } from '@shared/validators/cnpjValidator';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-validar-cnpj',
  templateUrl: './validar-cnpj.component.html',
  styleUrls: ['./validar-cnpj.component.scss']
})
export class ValidarCnpjComponent  implements OnInit, OnDestroy {
  message: any;
  validarForn: FormGroup;
  
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AuthService} _authService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
  ) {
    // Set the defaults
    this.message = null;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.validarForn = this._formBuilder.group({
      cnpj: ['', [Validators.required,cnpjValidator()]],
    }
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign up
   */
  validarCNPJ(): void {
    // Do nothing if the form is invalid
    if (this.validarForn.invalid) {
      return;
    }

    // Disable the form
    this.validarForn.disable();

    // Hide the message
    this.message = null;

    // Do your action here...

    // Emulate server delay
    setTimeout(() => {

      // Re-enable the form
      this.validarForn.enable();

      // Reset the form
      this.validarForn.reset({});

      // Show the message
      this.message = {
        appearance: 'outline',
        content: 'Your account has been created and a confirmation mail has been sent to your email address.',
        shake: false,
        showIcon: false,
        type: 'success'
      };
    }, 1000);
  }
}
