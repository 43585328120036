import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusCotacao'
})
export class StatusCotacaoPipe implements PipeTransform {


  transform(value: string): string {
    // Supondo que o valor seja no formato "0 - Cotação Criada"
    const parts = value.split(' - ');
    return parts.length > 1 ? parts[1] : value;
  }
}
