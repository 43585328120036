import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarNumero'
})
export class FormatarNumeroPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value === null || value === undefined) {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
