<form [formGroup]="documentosFormGroup" class="mt-1" >
  <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mb-2" *ngFor="let documento of documentosObrigatoriosDoCliente">
    <button
      pButton
      pRipple
      [label]="documento.nome"
      [disabled]="documentosFormGroup.controls[documento.nomeCampoObrigatorio].value !== null"
      [icon]="'pi pi-' + (documentosFormGroup.controls[documento.nomeCampoObrigatorio].value == null ? 'plus' : 'check')"
      class="mr-2 p-button-raised"
      [ngClass]="{
        'p-button-success': documentosFormGroup.controls[documento.nomeCampoObrigatorio].value !== null,
        'p-button-danger': documentosFormGroup.controls[documento.nomeCampoObrigatorio].value === null
      }"
      (click)="createDocument(
        !documentosFormGroup.controls[documento.nomeCampoObrigatorio].value,
        documento.nome,
        documento.id,
        documento.nomeCampoObrigatorio
      )"
    ></button>
  </div>
  <div>
    <button
      class="submit-button treo-mat-button-large"
      mat-flat-button
      [color]="'primary'"
      [disabled]="!documentosFormGroup.valid"
      (click)="documentosSalvos()"
    >
      <span>Salvar</span>
    </button>
  </div>
</form>

<app-criar-documento-modal
  [openDialog]="documentoDialog"
  [fornecedorId]="fornecedorId"
  [tipoDocumento]="tipoDocumento"
  [headerTitle]="headerTitle"
  [documentoObrigatorio]="true"
  [documentoObrigatorioCampo]="documentoObrigatorioCampo"
  (closeDialog)="resetDialog()"  
  (onHide)="resetDialog()"
  (newDocumentoEvent)="createdAndEditedDocumento($event)"
  [modeloDocumento]="modelosDocumentos"
  [acimaModeloDocumentoMensagemAlert]="acimaModeloDocumentoMensagemAlert"
  [abaixoModeloDocumentoMensagemAlert]="abaixoModeloDocumentoMensagemAlert"
  [clienteId]="clienteId"
>
</app-criar-documento-modal>
<ng-template #acimaModeloDocumentoMensagemAlert>
  <modelo-documento-mensagem-alert [modelosDocumentos]="modelosDocumentos" [mensagemPosicao]="'acima'" ></modelo-documento-mensagem-alert>
</ng-template>
<ng-template #abaixoModeloDocumentoMensagemAlert>
    <modelo-documento-mensagem-alert [modelosDocumentos]="modelosDocumentos" [mensagemPosicao]="'abaixo'" ></modelo-documento-mensagem-alert>
</ng-template>