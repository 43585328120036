// replace-thousand-separator.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceThousandSeparator'
})
export class ReplaceThousandSeparatorPipe implements PipeTransform {
  transform(value: any, newSeparator: string = '.'): string {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, newSeparator);
    }
    return '';
  }
}
