import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Utils from '@shared/helpers/utils';
import { Cliente } from '@shared/models/cliente.interface';
import Fornecedor from '@shared/models/fornecedor.interface';

import { FornecedorService } from '@shared/services/fornecedor.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'form-consultar-eh-persistir-fornecedor-no-cliente',
  templateUrl: './form-consultar-eh-persistir-fornecedor-no-cliente.component.html',
  styleUrls: ['./form-consultar-eh-persistir-fornecedor-no-cliente.component.scss']
})
export class FormConsultarEhPersistirFornecedorNoClienteComponent implements OnInit,AfterViewInit {
  validacaoCNPJFormGroup: any;
  loading: boolean = false;

  @Input('cliente') cliente?: Cliente;
  @Output('fornecedorCriado') emitter = new EventEmitter<Fornecedor>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output('messageERROR') messageERROR = new EventEmitter<any>();


  constructor(
    private _formBuilder: FormBuilder,
    private _fornecedorService: FornecedorService,
    private _messageService: MessageService,
  ) { }

  ngAfterViewInit() {
    this.formReady.emit(this.validacaoCNPJFormGroup);
  }
  ngOnInit(): void {
    this.validacaoCNPJFormGroup = this._formBuilder.group({
      cnpj: [null,[Validators.required,Validators.minLength(11)]]
    });
  }
  
  buscarEhSalvarCNPJ(): void {
    this.messageERROR.emit(null);
    this.validacaoCNPJFormGroup.markAllAsTouched();
    if (this.validacaoCNPJFormGroup.invalid) {
      const errorMessages = Utils.getErrorMessages(this.validacaoCNPJFormGroup);
      if (errorMessages) {
        console.error(errorMessages);
        this.messageERROR.emit(errorMessages);
        return;
      }
      return;
    }
    this.validacaoCNPJFormGroup.disable();
    const cnpj = this.validacaoCNPJFormGroup.get('cnpj').value;
    this._fornecedorService.consultarEhPersistirCNPJProspect(cnpj, this.cliente?.key).subscribe({
      next: (res: any) => {
        
        this.validacaoCNPJFormGroup.enable();
        if (res.status == "ERROR") {
          this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: res.message });
        }
        switch (res.situacao) {
            case "ATIVA":
              // Se a situação for ATIVA, prossegue com o cadastro
              this.emitter.emit(res as Fornecedor);
              break;
            case "BAIXADA":
              this.validacaoCNPJFormGroup.controls.cnpj.setErrors({ 'invalidSituacao': true });
              this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: "Este cnpj está com situaçāo baixada, nāo é possível prosseguir com cadastro.", sticky: true });
              this.messageERROR.emit("Este cnpj está com situaçāo baixada, nāo é possível prosseguir com cadastro.");
              break;
            case "INAPTA":
              this.validacaoCNPJFormGroup.controls.cnpj.setErrors({ 'invalidSituacao': true });
              this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: "Este cnpj está inapto, nāo é possível prosseguir com cadastro.", sticky: true  });
              this.messageERROR.emit("Este cnpj está inapto, nāo é possível prosseguir com cadastro.");
            case "SUSPENSA":
              this.validacaoCNPJFormGroup.controls.cnpj.setErrors({ 'invalidSituacao': true });
              this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: "Este cnpj está suspenso, nāo é possível prosseguir com cadastro.", sticky: true  });
              this.messageERROR.emit("Este cnpj está suspenso, nāo é possível prosseguir com cadastro.");
              break;
            case "NULA":
              this.validacaoCNPJFormGroup.controls.cnpj.setErrors({ 'invalidSituacao': true });
              this.messageERROR.emit("Este cnpj é nulo, nāo é possível prosseguir com cadastro.");
              this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: "Este cnpj é nulo, nāo é possível prosseguir com cadastro." , sticky: true });
              break;
            case "EM LIQUIDAÇÃO JUDICIAL OU EXTRAJUDICIAL":
              this.validacaoCNPJFormGroup.controls.cnpj.setErrors({ 'invalidSituacao': true });
              this.messageERROR.emit("Este cnpj está em liquidação judicial ou extrajudicial, nāo é possível prosseguir com cadastro.");
              this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: "Este cnpj está em liquidação judicial ou extrajudicial, nāo é possível prosseguir com cadastro." , sticky: true });
              break;
            default:
              this.validacaoCNPJFormGroup.controls.cnpj.setErrors({ 'invalidSituacao': true });
              this.messageERROR.emit("Situação do cnpj desconhecida, verifique o seu status com a receita federal, nāo é possível prosseguir com cadastro.");
              this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atenção', detail: "Situação do cnpj desconhecida, verifique o seu status com a receita federal, nāo é possível prosseguir com cadastro." , sticky: true });
              break;
          }
        },
        error: (err: HttpErrorResponse) => {  
          this.loading = false;
          this.validacaoCNPJFormGroup.enable();
          console.error(err)
          if(err.status == 404){
            this.messageERROR.emit(`Atençāo ${err.error.message || err.error.error || err.error}`);
            this._messageService.add({ key: 'app', severity: 'warn', summary: 'Atençāo', detail: err.error.message , sticky: true });
          }else{
            this.messageERROR.emit(`Houve um erro ${err.error.message || err.error.error || err.error}`);
            this._messageService.add({ key: 'app', severity: 'error', summary: 'Houve um erro', detail: err.error.message , sticky: true });
          }
          
        }
      })
   
  }
}
