import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[permissaoAcesso]'
})
export class PermissaoAcessoDirective {
  private _permissoes: string[] = [];
  private _contexto: string = '';

  @Input() set permissaoAcesso(permissoes: string[]) {
    this._permissoes = permissoes;
    this.verificarPermissoes();
  }

  @Input() set contexto(contexto: string) {
    this._contexto = contexto;
    this.verificarPermissoes();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  private verificarPermissoes() {
    // Verifica se a variável de contexto está presente no array de permissões
    const hasPermission = this._permissoes.includes(this._contexto);

    // Limpa a view container
    this.viewContainer.clear();

    // Se a variável de contexto tem permissão, renderiza o conteúdo
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
