import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentoTiposService {

 
  readonly SERVER_URL = environment.apiEndpoint;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient){}

  list(event?:any): Observable<any>{
      let options;
      if(event){
        delete event.forceUpdate;
        event.filters = JSON.stringify(event.filters);
        options = { params: event };
      }
      return this._httpClient.get<any>(`${this.SERVER_URL}tipo/documento/paginated`,options);
  }
  getPaginatedFromCliente(clienteId: string,event?:any): Observable<any>{
      let options;
      if(event){
        delete event.forceUpdate;
        event.filters = JSON.stringify(event.filters);
        options = { params: event };
      }
      return this._httpClient.get<any>(`${this.SERVER_URL}tipo/documento/cliente/${clienteId}`,options);
  }
  getLisAllWithCliente(clienteId?: string): Observable<any>{
    let queryParams = {};
    if (clienteId) {
      queryParams = { clienteId };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}tipo/documento`, { params: queryParams });
  }
  getOne(id): Observable<any>{
    return this._httpClient.get<any>(`${this.SERVER_URL}tipo/documento/${id}`);
  }
  getSomenteAtivos(clienteId?: string): Observable<any>{
    let queryParams = {};
    if (clienteId) {
      queryParams = { clienteId };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}tipo/documento/ativos`, { params: queryParams });
  }
  getSomenteAtivosProspect(clienteId?: string): Observable<any>{
    let queryParams = {};
    if (clienteId) {
      queryParams = { clienteId };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}prospect/tipo/documento/ativos`, { params: queryParams });
  }

  createOne(tipo): Observable<any>{
    return this._httpClient.post<any>(`${this.SERVER_URL}tipo/documento`,tipo);
  }
  updateOne(tipo): Observable<any>{
    return this._httpClient.patch<any>(`${this.SERVER_URL}tipo/documento`,tipo);
  }
  deleteOne(id): Observable<any>{ 
    return this._httpClient.delete<any>(`${this.SERVER_URL}tipo/documento/${id}`);
  }
}
