import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModeloDocumentoService {
  readonly SERVER_URL = environment.apiEndpoint;
  constructor(private _httpClient: HttpClient) { }

  listarModeloDocumento(event?: any): Observable<any> {
    let options;
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }   
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento`, options);
  }
  listarTodosModeloDocumentoPaginated(event?: any): Observable<any> {
    let options;
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento/paginated`, options);
  }

  listarDocumentosDoCliente(clienteId: string, event?: any): Observable<any> {
    let options;
    if (event) {
      delete event.forceUpdate;
      event.filters = JSON.stringify(event.filters);
      options = { params: event };
    }
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento/cliente/${clienteId}`, options);
  }

  criarModeloDocumento(formData: FormData): Observable<any> {
    return this._httpClient.post<any>(`${this.SERVER_URL}modelo-documento`, formData);
  }

  atualizarModeloDocumento(body: any): Observable<any> {
    return this._httpClient.patch<any>(`${this.SERVER_URL}modelo-documento`, body);
  }

  deletarModeloDocumento(id: string): Observable<any> {
    return this._httpClient.delete<any>(`${this.SERVER_URL}modelo-documento/${id}`);
  }

  obterModeloDocumentoById(id: string): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento/${id}`);
  }

  getModeloDocumentoByDocumentoTipoAndCliente(documentoTipo: string, cliente: string): Observable<any> {
    const queryParams = new HttpParams()
      .set('documentoTipo', documentoTipo)
      .set('cliente', cliente);
  
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento/tipo/cliente`, { params: queryParams });
  }
  obterModeloDocumentoParaVisualizacao(id: string): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento/${id}/view`);
  }

  obterModeloDocumentoParaDownload(id: string): Observable<any> {
    return this._httpClient.get<any>(`${this.SERVER_URL}modelo-documento/${id}/download`);
  }
}