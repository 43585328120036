import { Injectable } from '@angular/core';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';

import { Observable, map, of, switchMap, tap } from 'rxjs';

@Injectable({

  providedIn: 'root'

})

export class AuthGuard implements CanActivate {
  private navigationAttempted = false;

  constructor(private _authService: AuthService, private router: Router) {}



  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean> {
    // Check the authentication status
    return this._authService.isLoggedIn()
      .pipe(
        switchMap((authenticated) => {

          if (!authenticated) {
            // Prevent the access
            return of(false);
          }

          // Allow the access
          return of(true);
        })
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {  
    return this._check().pipe(
      map((authenticated) => {
        if (authenticated) {
          return true;
        }
        const encodedRedirectUrl = encodeURIComponent(state.url);

        // Append the encoded redirectUrl as a query parameter
        return this.router.createUrlTree(['auth', 'login'], { queryParams: { redirectUrl: encodedRedirectUrl } });
      })
    );
  }

  // /**
  //  * Can activate child
  //  *
  //  * @param childRoute
  //  * @param state
  //  */
  // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return this._check();
  // }

  // /**
  //  * Can load
  //  *
  //  * @param route
  //  * @param segments
  //  */
  // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //   return this._check();
  // }
}