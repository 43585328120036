import { Directive, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[invalidInputClass]'
})
export class InvalidInputClassDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private ngControl: NgControl) { }

  ngAfterViewInit() {
    this.ngControl.statusChanges.subscribe(() => {
      const element = this.elementRef.nativeElement;
      const invalid = this.ngControl.invalid && this.ngControl.dirty;
      if (invalid) {
        this.renderer.addClass(element, 'ng-invalid');
        this.renderer.addClass(element, 'ng-dirty');
      } else {
        this.renderer.removeClass(element, 'ng-invalid');
        this.renderer.removeClass(element, 'ng-dirty');
      }
    });
  }

}
