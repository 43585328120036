import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShortcutsService } from '@shared/services/shortcuts.service';
import { Shortcut } from '@shared/types/shortcuts.types';


@Component({
  selector: 'shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'shortcuts'
})
export class ShortcutsComponent implements OnInit, OnDestroy {
  mode: 'view' | 'modify' | 'add' | 'edit';
  shortcutForm: FormGroup;

  // Private
  private _overlayRef: OverlayRef;
  private _shortcuts: Shortcut[];
  private _unsubscribeAll: Subject<any>;

  @ViewChild('shortcutsOrigin')
  private _shortcutsOrigin: MatButton;

  @ViewChild('shortcutsPanel')
  private _shortcutsPanel: TemplateRef<any>;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FormBuilder} _formBuilder
   * @param {ShortcutsService} _shortcutsService
   * @param {Overlay} _overlay
   * @param {ViewContainerRef} _viewContainerRef
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _shortcutsService: ShortcutsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.mode = 'view';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for shortcuts
   */
  @Input()
  set shortcuts(value: Shortcut[]) {
    // Store the value
    this._shortcutsService.store(value);
  }

  get shortcuts(): Shortcut[] {
    return this._shortcuts;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Initialize the form
    this.shortcutForm = this._formBuilder.group({
      id: [null],
      label: ['', Validators.required],
      description: [''],
      icon: ['', Validators.required],
      link: ['', Validators.required],
      useRouter: ['', Validators.required]
    });

    // Get the shortcuts
    this._shortcutsService.shortcuts$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((shortcuts: Shortcut[]) => {

        // Load the shortcuts
        this._shortcuts = shortcuts;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.unsubscribe();

    // Dispose the overlay if it's still on the DOM
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create route from given link
   */
  createRouteFromLink(link): string[] {
    // Split the link and add a leading slash
    const route = link.split('/');
    route.unshift('/');

    // Return the route
    return route;
  }

  /**
   * Open the shortcuts panel
   */
  openPanel(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._shortcutsOrigin._elementRef.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(16)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    // Create a portal from the template
    const templatePortal = new TemplatePortal(this._shortcutsPanel, this._viewContainerRef);

    // Attach the portal to the overlay
    this._overlayRef.attach(templatePortal);

    // Subscribe to the backdrop click
    this._overlayRef.backdropClick().subscribe(() => {

      // If overlay exists and attached...
      if (this._overlayRef && this._overlayRef.hasAttached()) {
        // Detach it
        this._overlayRef.detach();
      }

      // If template portal exists and attached...
      if (templatePortal && templatePortal.isAttached) {
        // Detach it
        templatePortal.detach();
      }

      // Make sure to start in 'view' mode
      this.mode = 'view';
    });
  }

  /**
   * Change the mode
   */
  changeMode(mode): void {
    // Change the mode
    this.mode = mode;
  }

  /**
   * Prepare for a new shortcut
   */
  newShortcut(): void {
    // Reset the form
    this.shortcutForm.reset();

    // Enter the add mode
    this.mode = 'add';
  }

  /**
   * Edit a shortcut
   */
  editShortcut(shortcut): void {
    // Reset the form with the shortcut
    this.shortcutForm.reset(shortcut);

    // Enter the edit mode
    this.mode = 'edit';
  }

  /**
   * Save shortcut
   */
  save(): void {
    // Get the data from the form
    const shortcut = this.shortcutForm.value;

    // If there is an id, update it...
    if (shortcut.id) {
      this._shortcutsService.update(shortcut.id, shortcut).subscribe();
    }
    // Otherwise, create a new shortcut...
    else {
      this._shortcutsService.create(shortcut).subscribe();
    }

    // Go back the modify mode
    this.mode = 'modify';
  }

  /**
   * Delete shortcut
   */
  delete(): void {
    // Get the data from the form
    const shortcut = this.shortcutForm.value;

    // Delete
    this._shortcutsService.delete(shortcut.id).subscribe();

    // Go back the modify mode
    this.mode = 'modify';
  }
}
