import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | string, decimalDigits: number = 2, thousandsSeparator: string = ','): string {
    if (value == null) {
      return '';
    }

    const numericValue: number = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    if (isNaN(numericValue)) {
      return '';
    }

    const integerValue = Math.floor(numericValue);
    const decimalPart = numericValue % 1;
    const decimalString = decimalPart.toFixed(decimalDigits).substring(decimalDigits); // Pegando os dígitos decimais

    const integerFormatted = integerValue.toLocaleString(); // Formatando a parte inteira

    return `${integerFormatted}${thousandsSeparator}${decimalString.padEnd(decimalDigits, '0')}`;
  }
}
