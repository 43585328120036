<p-dialog #dialog [(visible)]="openDialog" [maximizable]="true" [draggable]="false"  [closeOnEscape]="false" [closable]="false"
  [style]="{width: '80%'}" [header]="headerTitle" [modal]="true" styleClass="p-fluid" (onShow)="onShowDialog($event)">
  <ng-template pTemplate="content">
    <div class="max-w">
      <form [formGroup]="form" autocomplete="disabled" class="flex flex-col mt-2 px-8 pt-2 rounded overflow-hidden">
        <div class="flex flex-col gap-4">
          <div class="mb-1" *ngIf="acimaModeloDocumentoMensagemAlert">
            <ng-template *ngTemplateOutlet="acimaModeloDocumentoMensagemAlert"></ng-template>
          </div>  
          <div class="treo-mat-dense w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4"> 
            <mat-form-field class="w-full md:w-1/2"> 
              <mat-label>Nome do Documento</mat-label> 
                <input matInput formControlName="nome"> <mat-error
                *ngIf="form && formControl.nome.hasError('required')">O campo é obrigatório.</mat-error> 
            </mat-form-field>
            <mat-form-field class="w-full md:w-1/2">
              <mat-label>Data de Emissāo do Documento</mat-label> <input matInput formControlName="dataEmissao" type="date"> <mat-error
                *ngIf="form && formControl.dataEmissao.hasError('required')">O campo é obrigatório.</mat-error>
            </mat-form-field> 
            <mat-form-field class="w-full md:w-1/2" *ngIf="formControl.numero"> 
              <mat-label>Número de Identificaçāo</mat-label> 
              <input  matInput formControlName="numero"> 
              <mat-error *ngIf="form && formControl.numero.hasError('required')">O campo é obrigatório.</mat-error> 
            </mat-form-field> 
            <mat-form-field class="w-full md:w-1/2" *ngIf="formControl.dataValidade"> 
              <mat-label>Data de Validade do Documento</mat-label> 
              <input matInput formControlName="dataValidade" type="date"> 
              <mat-error *ngIf="form && formControl.dataValidade.hasError('required')">O campo é obrigatório.</mat-error>
            </mat-form-field> </div>  
          <div class="flex flex-col gap-0">
            <mat-form-field appearance="fill" class="treo-mat-dense flex-auto">
              <mat-label>Arquivo</mat-label>
  
              <!-- Verifica se o arquivo existe -->
              <ng-container *ngIf="documento && documento.file; else fileInput">
                <input type="text" matInput formControlName="file" [disabled]="true">
                <button mat-icon-button (click)="viewFile()">
                  <mat-icon>open_in_new</mat-icon>
                </button>
                <button mat-icon-button (click)="removeFile()">
                  <mat-icon>delete</mat-icon>
                </button>
              </ng-container>
  
  
              <!-- Exibe o input para escolher o arquivo -->
              <ng-template #fileInput>
                <ngx-mat-file-input #file placeholder="Envie o arquivo" [accept]="'.doc, .docx, .xls, .xlsm, .pdf, .png, .jpg, .jpeg'"
                  formControlName="file" (change)="handleFileChange($event)"></ngx-mat-file-input>
                <button mat-icon-button matSuffix *ngIf="file.value" (click)="file.clear()">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-icon matSuffix>folder</mat-icon>
              </ng-template>
              <mat-error *ngIf="form && form.get('file').hasError('required')">O arquivo é obrigatório.</mat-error>
            </mat-form-field>
            <small id="file-help" class="block">Formatos aceitos: .doc, .docx, .xls, .xlsm, .pdf, .png, .jpg, .jpeg</small>
          </div>
          <div class="mb-1" *ngIf="abaixoModeloDocumentoMensagemAlert" >
            <ng-template *ngTemplateOutlet="abaixoModeloDocumentoMensagemAlert"></ng-template>
          </div>
          <mat-form-field class="treo-mat-dense flex-auto">
            <mat-label>Observacao do documento</mat-label>
            <textarea matInput formControlName="observacao"></textarea>
            <mat-error>O campo é obrigatório.</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>

  </ng-template>
  
  <ng-template pTemplate="footer">    
    <div  class="flex items-center justify-end border-t ml-8 mt-5 pxl-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700"  >
      <treo-message [dismissed]="!message.show" [appearance]="'border'" [showIcon]="'true'"
        [type]="message.type">
        {{message.content}}
      </treo-message>
      <button mat-raised-button type="button" (click)="hideDialog()" color="secondary" class="px-6 ml-1">
        Cancelar
      </button>
      <button mat-raised-button type="submit" (click)="upload()" [class.spinner]="loading" [disabled]="loading" color="primary" class="px-6 ml-1">
        Salvar
      </button>
    </div>
  </ng-template>
</p-dialog>