import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'message-input-required',
  templateUrl: './message-input-required.component.html',
  styleUrls: ['./message-input-required.component.scss']
})
export class MessageInputRequiredComponent {
  @Input('formGroup') form: FormGroup;
  @Input('field')field: string;
  @Input() id: string;

  get showError(): boolean {
    if(!this.form || !this.form.get(this.field)){
      return false;
    }
    return this.form && this.form.get(this.field).touched && this.form.get(this.field).hasError('required');
  }
}