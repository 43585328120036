import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments';
import Utils from '@shared/helpers/utils';
import { Documento } from '@shared/models/documento.interface';
import Fornecedor from '@shared/models/fornecedor.interface';
import ModeloDocumento from '@shared/models/modeloDocumento.interface';
import { APIresponsePaginate } from '@shared/models/paginateResponse.interface';
import { RequisicaoDocumento } from '@shared/models/requisicaoDocumento';
import { TreoMessage } from '@shared/models/treoMessage.interface';
import { Usuario } from '@shared/models/usuario.interface';
import { DocumentoService } from '@shared/services/documento.service';
import { FileService } from '@shared/services/file.service';
import { ModeloDocumentoService } from '@shared/services/modelo-documento.service';
import { RequisicaoDocumentoService } from '@shared/services/requisicaoDocumento.service';
import { UserService } from '@shared/services/user.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-responder-requisicao',
  templateUrl: './responder-requisicao.component.html',
  styleUrls: ['./responder-requisicao.component.scss']
})
export class ResponderRequisicaoComponent implements OnInit,OnDestroy {
  usuario: Usuario;
  private destroyed$ = new Subject();
  loading = false;
  fornecedor: Fornecedor = null;
  documentosDisponiveis: Documento[] = [];
  statuses = Utils.getStatusToRequisicaoDocumento();
  status: string;
  role: string;
  inRoute: boolean = false;
  hasImageError: boolean = false;
  message: TreoMessage = {
    show: false,
    appearance: 'outline',
    showIcon: true,
  };
  mapaDeAcoes;
  contexto;
  hasAprovacao: Boolean = false;
  formResposta: FormGroup;
  formDocumento: FormGroup;
  requisicaoDocumento: RequisicaoDocumento;
  documentoCriado: Documento = null;
  @ViewChild('formElement', { static: true }) formElement: ElementRef<HTMLFormElement>;
  @ViewChild('stepper') stepper: MatStepper;
  options = [
    {
      label: "Sim",
      value: "recusada"
    },
    {
      label: "Nāo",
      value: "pendente-de-aprovacao"
    }
  ]
  modeloDocumento: ModeloDocumento;
  modelosDocumentos: ModeloDocumento[];
  show: any;
  mimeType: string;
  urlFile: string;
  id: string;
  adicionarNovoDocumento: boolean = false;

  constructor(
    private _userService: UserService,
    private _requisicaoDocumentoService: RequisicaoDocumentoService,
    private _formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _ref: DynamicDialogRef, 
    private _config: DynamicDialogConfig,
    private _cdRef: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _documentoService: DocumentoService,
    private _fileService: FileService,
    private _modeloDocumentoService: ModeloDocumentoService,
    private _router: Router,
  ) {
    this.requisicaoDocumento = this._config.data.requisicaoDocumento;
    this.fornecedor = this._config.data.fornecedor;
    this.status = this.statuses.find((e) => e.value == this.requisicaoDocumento.status).label;
    this._userService.getUserData().pipe(takeUntil(this.destroyed$)).subscribe((data: Usuario) => {
      this.usuario = data;
      this.reloadDocuments()
    })

    // via modal
    if (this._config.data) {
      if (this._config.data.requisicaoDocumento) this.requisicaoDocumento = this._config.data.requisicaoDocumento;
      if (this._config.data.contexto) this.contexto = this._config.data.contexto;
      if (this._config.data.mapaDeAcoes) this.mapaDeAcoes = this._config.data.mapaDeAcoes;
      if (this._config.data.usuario) this.usuario = this._config.data.usuario;
      
    }else{
      this.inRoute = true;
      this.requisicaoDocumento = this._activatedRoute.snapshot.data.requisicaoDocumento;

      // this._activatedRoute.queryParams.subscribe(params => {
      //   this.id = params.id;
      // });
    }

    this.status = this.statuses.find((e) => e.value == this.requisicaoDocumento.status).label;

  }

  ngOnInit(): void {
    console.log(this.requisicaoDocumento)
    
    this.carregarModelosDocumentos(this.requisicaoDocumento.tipo.id,this.requisicaoDocumento.cliente.id)
    this.buildForm()
  }
  ngOnDestroy(): void {
    this.destroyed$.unsubscribe()
  }
  buildForm(){
    this.formResposta = this._formBuilder.group({
      documento: [null,Validators.required],
      status: ["pendente-de-aprovacao",Validators.required],
      respostaFornecedor: [(this.requisicaoDocumento.respostaFornecedor || null),Validators.required],
      respondidaPor: [this.usuario.id,Validators.required],
    })
  }
  carregarModelosDocumentos(documentoTipo:string,cliente:string){
    this._modeloDocumentoService.getModeloDocumentoByDocumentoTipoAndCliente(documentoTipo,cliente).pipe(takeUntil(this.destroyed$)).subscribe((res)=>{
      
      this.modelosDocumentos = res;
    })
  }

  enviarRespostaDoFornecedor(){
    try {
      this.formResposta.markAllAsTouched();
      if (!this.formResposta.valid) {
        return;
      }
      const formValues = this.formResposta.getRawValue();
      this.loading = true;
      this.formResposta.disable();
      this._requisicaoDocumentoService.responderRequisicaoDocumento({id: this.requisicaoDocumento.id, ...formValues}).pipe(takeUntil(this.destroyed$)).subscribe(
        (response: any) => {
          this.requisicaoDocumento = response.data;
          this.formResposta.enable();        
          this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: `Requisiçāo de documento foi respondida com sucesso` })
          
          this.loading = false;
          this.formResposta.reset()
          this._ref.close(this.requisicaoDocumento);
        },
        (error: HttpErrorResponse) => {
          this.formResposta.enable();        
          this._messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `${error.error.message}` , sticky: true})
          this.loading = false;
        }
      );
    } catch (error) {
      this.formResposta.enable();        
      this.loading = false;
      this._messageService.add({ severity: 'error',life: 0,closable: true, summary: 'Houve um erro', detail: `${error?.message}` , sticky: true})
    }
  }
  hideDialog() {
    this._ref.close();
  }
  changeRecusarRequisicao(event){
    if(event.checked){
      this.formResposta.controls.status.patchValue("recusada");
    }else{
      this.formResposta.controls.status.patchValue("pendente-de-aprovacao");
    }
    this._cdRef.detectChanges();
  }
  reloadDocuments(showMessage = false){
    this._documentoService.listarDocumentosDoVendedor(this.usuario.id, {limit: 10000})
    .pipe(takeUntil(this.destroyed$))
    .subscribe((res: any) => {
      this.documentosDisponiveis = res.docs;
      this.documentosDisponiveis = this.documentosDisponiveis?.filter((doc) => {
        return doc.fornecedor.id == this.requisicaoDocumento.fornecedorRequerido.id
      });
      
      if(this.requisicaoDocumento && this.requisicaoDocumento.tipo.id) {
        this.documentosDisponiveis = this.documentosDisponiveis.filter((e)=> {
          return e.tipo.id == this.requisicaoDocumento.tipo.id;
        })
      }
      if(showMessage){
      this._messageService.add({ severity: 'success', summary: 'Ótimo', detail: 'Documentos carregados novamente!', life: 5000 });
      }
    }, (error) => {
      this.loading = false;
      console.error(error)
      this._messageService.add({ severity: 'error', summary: 'Houve um erro! ao carregar os documentos disponiveis', detail: error.error.message, sticky: true });
    });
  }
  addDocumentoToDisponiveis($event: Documento){
    
    this.formDocumento.disable()
    this.documentoCriado = $event;
    this.documentosDisponiveis = [$event,...this.documentosDisponiveis];
    this.stepper.selected.completed = true;
    this.formResposta.controls.documento.setValue(this.documentoCriado.id)
    this.stepper.next()
  }
  getFileName(file){
    if(file && file.mimetype && file.mimetype != ""){
      const a = `${file.filename}.${file.mimetype.split("/")[1]}`;
      return a;
    }else{
      const a = `${file.id}`;
      return a;
    }
  }
  openFile() {
    const url = this._fileService.urlViewFile(this.modeloDocumento.file.id || this.modeloDocumento.file._id);
    window.open(url, '_blank');
  }
  downloadFile() {
    const url = this._fileService.urlDownloadFile(this.modeloDocumento.file.id || this.modeloDocumento.file._id);
    window.open(url, '_blank');
  }
  showFile(){
    if(!this.show){
      this.mimeType = this.modeloDocumento.file.mimetype;
      this.urlFile = this._fileService.urlViewFile(this.modeloDocumento.file.id)
    }
    this.show = !this.show;
  }
  selectModeloExibirModeloDocumentoAnotherPage(modeloDocumento: ModeloDocumento){
    this.modeloDocumento = modeloDocumento;
    let url = environment.routes.vendedor.modeloDocumento.exibir + '/' + this.modeloDocumento.id;
    window.open(url, '_blank');
  }
  erroImage(event){
    
  }
  setAdicionarNovoDocumento(value){
    this.adicionarNovoDocumento = value;
  } 
}
