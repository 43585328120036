<div [ngClass]="inRoute ? 'content-layout fullwidth-standard-content-scroll' : ''">
  <!-- Header -->
  <div class="header" *ngIf="inRoute">
    <div class="left">
      <h1>Requisiçāo: {{ requisicaoDocumento.tipo.nome }} | Status: {{ getLabelDoStatus() }} </h1>
    </div>
    <div class="right">
      <!-- Action buttons -->
    </div>
  </div>
  <mat-stepper [linear]="(requisicaoDocumento.status == 'pendente-de-retorno') ? false : true" #stepper style="width: 100%;height: 100%;">
    <mat-step state="requisicao" label="Requisiçāo do Cliente" >
      <div class="flex flex-col mt-2 px-8 pt-2 rounded overflow-hidden show-info">
        <div class="flex flex-col gap-4">
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Status: </strong>
            <span [class]="'badge status-' + requisicaoDocumento.status " >{{ status || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Fornecedor: </strong>
            <span>{{ requisicaoDocumento.fornecedorRequerido.nome || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Cliente: </strong>
            <span>{{ requisicaoDocumento.cliente.desc_emp || "-"}}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="tipo">Tipo: </strong>
            <span>{{ requisicaoDocumento.tipo.nome || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="motivo">Motivo: </strong>
            <span>{{ requisicaoDocumento.motivo || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="dataInicio">Data de Início: </strong>
            <span>{{ requisicaoDocumento.dataInicio| date: "dd/MM/yyyy":'UTC' }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="dataFinal">Prazo Final: </strong>
            <span>{{ (requisicaoDocumento.dataFinal == null ? "-" : requisicaoDocumento.dataFinal | date:
              "dd/MM/yyyy":'UTC') }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="observacao">Observaçāo: </strong>
            <span>{{ requisicaoDocumento.observacao || "-" }}</span>
          </div>
          <div class="treo-mat-dense flex-auto">
            <strong for="observacao">Solicitada por: </strong>
            <span>{{ requisicaoDocumento?.solicitadoPor?.email || 'Automático' }}</span>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end border-t mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <button matStepperNext mat-raised-button color="primary" class="px-6 ml-3" >Próxima</button>
      </div>
    </mat-step>
    <mat-step state="documento" label="Enviar Documento" *ngIf="(documentosDisponiveis && documentosDisponiveis.length == 0) || requisicaoDocumento.status == 'pendente-de-retorno' || adicionarNovoDocumento"
      [stepControl]="formDocumento" [errorMessage]="'Necessário ter um documento do tipo '+requisicaoDocumento.tipo.nome" [hasError]="(requisicaoDocumento.status == 'pendente-de-retorno') ? false : true" >
        <criar-documento (form)="formDocumento = $event" (documento)="addDocumentoToDisponiveis($event)" [autoCloseOnFinish]="false" 
        [idTipoDeDocumentoPrefixado]="requisicaoDocumento.tipo.id" 
        [idFornecedorPrefixado]="requisicaoDocumento.fornecedorRequerido.id" 
        [idClientePrefixado]="requisicaoDocumento.cliente.id"  
        [modeloDocumento]="modeloDocumento"
        [acimaModeloDocumentoMensagemAlert]="acimaModeloDocumentoMensagemAlert"
        [abaixoModeloDocumentoMensagemAlert]="abaixoModeloDocumentoMensagemAlert"
      ></criar-documento>
        <ng-template #acimaModeloDocumentoMensagemAlert>
          <modelo-documento-mensagem-alert [modelosDocumentos]="modelosDocumentos" [mensagemPosicao]="'acima'" ></modelo-documento-mensagem-alert>
        </ng-template>
        <ng-template #abaixoModeloDocumentoMensagemAlert>
            <modelo-documento-mensagem-alert [modelosDocumentos]="modelosDocumentos" [mensagemPosicao]="'abaixo'" ></modelo-documento-mensagem-alert>
        </ng-template>
    </mat-step>
    <mat-step state="resposta" label="Resposta do Fornecedor" [stepControl]="formResposta" errorMessage="Necessário enviar a resposta da requisiçāo">
      <form [formGroup]="formResposta" autocomplete="disabled" class=" rounded overflow-hidden">
        <div class="grid grid-cols-1 my-2 py-2 gap-4">
  
          <div class="flex flex-col" *ngIf="formResposta.controls.status && formResposta.controls.status.value == 'pendente-de-aprovacao'">
            <div class="treo-mat-dense grid grid-cols-1" >
              <label>Selecione um documento cadastrado</label>
              <div class="p-inputgroup">
                  <p-dropdown
                  style="width: 100%!important;"
                  [options]="documentosDisponiveis"
                  formControlName="documento"
                  optionLabel="nome"
                  optionValue="id"
                  appendTo="body"
                  placeholder="Selecione um documento"
                  emptyMessage="Nāo foi encontrado um documento do mesmo tipo da requisiçāo" >
                <ng-template let-files pTemplate="selectedItem">
                  <div>Nome: {{ files.nome }} - Número: {{ files.numero }} - Data de Emissāo: {{ files.dataEmissao | date: "dd/MM/yyyy":'UTC' }} - Data de Validade: {{ files.dataValidade | date: "dd/MM/yyyy":'UTC' }}</div>
                </ng-template>
                <ng-template let-files pTemplate="item">
                  <div>Nome: {{ files.nome }} - Número: {{ files.numero }} - Data de Emissāo: {{ files.dataEmissao | date: "dd/MM/yyyy":'UTC' }} - Data de Validade: {{ files.dataValidade | date: "dd/MM/yyyy":'UTC' }}</div>
                </ng-template>
              </p-dropdown> 
              <button type="button" pTooltip="Recaregar lista de Documentos" class="ml-2" pButton pRipple icon="pi pi-refresh" styleClass="p-button-warn" (click)="reloadDocuments(true)"></button>      
              <button type="button" pTooltip="Enviar um Novo Documento" class="ml-2" pButton pRipple icon="pi pi-plus" styleClass="p-button-warn" (click)="setAdicionarNovoDocumento(true)"></button>      
            </div>
            <message-input-required [formGroup]="formResposta" field="documento" ></message-input-required>
            </div>
          </div>
          <div class="flex flex-col">
            <mat-form-field class="treo-mat-dense ">
              <mat-label>Resposta ao Solicitante</mat-label>
              <textarea matInput formControlName="respostaFornecedor"></textarea>
              <mat-error>O campo Resposta do Fornecedor é obrigatório.</mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div class="flex items-center justify-between border-t -mx-8 mt-5 px-8 py-5 light:bg-cool-gray-50 dark:bg-cool-gray-700">
        <button mat-raised-button matStepperPrevious color="info" class="px-6 ml-3 justify-start">Voltar</button>
        <button mat-raised-button (click)="enviarRespostaDoFornecedor()" class="px-6 ml-3 justify-end"
          [class.spinner]="loading" [disabled]="loading" color="primary"> Enviar </button>
      </div>
    </mat-step>
    <ng-template matStepperIcon="requisicao">
      <mat-icon>description</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="documento">
      <mat-icon>attach_file</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="resposta">
      <mat-icon>drafts</mat-icon>
    </ng-template>
  </mat-stepper>
</div>